import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { publicationofResearchPaperData } from './PublicationofResearchPaper.Data'
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { saveAs } from 'file-saver'
import { useLazyGetPublicationofResearchPaperByIdQuery } from '../../../../../app/services/CPD/CpdSubmissionApi';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { useAddEditPublicationOfResearchPaperMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';

const PublicationofResearchPaper = forwardRef((props, ref) => {
  const publicationofResearchPaperRef = useRef();
  const [publicationofResearchPaperForm, setpublicationofResearchPaper] = useState(publicationofResearchPaperData);
  const { success, error } = SwalAlert();

  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = publicationofResearchPaperRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));
  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [
    getPublicationofResearchPaperById, {
      isSuccess: getPublicationofResearchPaperByIdIsSuccess,
      isLoading: getPublicationofResearchPaperByIdIsLoading,
      data: getPublicationofResearchPaperByIdData,
    }
  ] = useLazyGetPublicationofResearchPaperByIdQuery();


  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isFetching: isLoadingForm,
      data: formResponse }
  ] = useAddEditPublicationOfResearchPaperMutation();

  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);

  useEffect(() => {
    if (getPublicationofResearchPaperByIdIsSuccess && !getPublicationofResearchPaperByIdIsLoading && getPublicationofResearchPaperByIdData) {
      console.log({ getPublicationofResearchPaperByIdData });
      let temp = { ...publicationofResearchPaperForm }
      let tempInitials = temp.initialState;
      tempInitials.titeofResearchPaper = getPublicationofResearchPaperByIdData.titleofResearchPaper;
      tempInitials.nameofDistinguishedProfessionalPublicationorResearchJournal = getPublicationofResearchPaperByIdData.nameofdistinguishedprofessional;
      tempInitials.dateofPublication = getPublicationofResearchPaperByIdData.dateofPublication;
      tempInitials.researchPaper = getPublicationofResearchPaperByIdData.researchPaper;
      tempInitials.CpdPoints = getPublicationofResearchPaperByIdData.cpdPoints;
      tempInitials.remarks = getPublicationofResearchPaperByIdData.remarks;
      tempInitials.processedBy = getPublicationofResearchPaperByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getPublicationofResearchPaperByIdData.cpdPoints;
      setpublicationofResearchPaper(temp);
    }
  }, [getPublicationofResearchPaperByIdIsSuccess, getPublicationofResearchPaperByIdIsLoading, getPublicationofResearchPaperByIdData]);

  useEffect(() => {
    getPublicationofResearchPaperById(cpdSubmissionData.cpdSubmissionId);
  }, []);


  const handleDownload = async (data, dataField) => {

    const request = {
      type: "ResearchPaper",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getPublicationofResearchPaperByIdData.researchPaper,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }
  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };

  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "researchPaper" && value) {
      handleOnChangeFileUpload(value)
    }
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "ResearchPaper");
    uploadPDF(formData);
  }

  const handleSaveButton = () => {
    const formData = publicationofResearchPaperRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "")
    }
    let request = {
      CPDPublicationOfResearchPaperId: getPublicationofResearchPaperByIdData.cpdPublicationOfResearchPaperId,
      GMAPMemberId: getPublicationofResearchPaperByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getPublicationofResearchPaperByIdData.cpdActivityTypeId,
      TitleOfResearchPaper: formData?.titeofResearchPaper || null,
      Nameofdistinguishedprofessional: formData?.nameofDistinguishedProfessionalPublicationorResearchJournal,
      DateOfPublication: formData?.dateofPublication || null,
      ResearchPaper: fileUploadData?.fileName ? fileUploadData?.fileName : getPublicationofResearchPaperByIdData.researchPaper,
      CPDSubmissionId: getPublicationofResearchPaperByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }
    handleSubmitForm(request)
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getPublicationofResearchPaperByIdData.cpdSubmissionId,
      gmapMemberId: getPublicationofResearchPaperByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };

  const handleApproveButton = () => {
    const formData = publicationofResearchPaperRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getPublicationofResearchPaperByIdData.cpdSubmissionId,
      gmapMemberId: getPublicationofResearchPaperByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };
  return (
    <React.Fragment>
      {getPublicationofResearchPaperByIdData ? <div className="speaking-teaching-engagement">
        {/* <div className="cpd-submission-description">
          <p>Research Paper has to be published in a distinguished professional publication or research journal.</p>
          <p>
            Research papers must relate to green building or sustainability of the built environment, and the date of publication must be within 1 year of renewal deadline. (e.g. For renewal of certification by 30 June 2022, the date of publication must not be earlier than 30 June 2021)
          </p>
          <p>Only 1 technical article or paper may be claimed per renewal cycle and 6 CPD points will be awarded.</p>
        </div> */}

        <div className="common-project-info-main card-section card-section-left">
          <div className="row gx-5">

            <FormCreator ref={publicationofResearchPaperRef} {...publicationofResearchPaperForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />

          </div>
        </div>
        <div className='row'>
          <div className='common-custom-pagination mt-5'>
            <div>
              <Buttons
                buttonText="Save"
                buttonTypeClassName="outline-btn"
                isDisable={props?.isPastCPD}
                onClick={handleSaveButton}
              />
            </div>
            <div className='d-flex column-gap-3'>
              <Buttons
                buttonText="Reject"
                buttonTypeClassName="red-btn"
                isDisable={props?.isPastCPD}
                onClick={handleRejectbutton}
              />
              <Buttons
                buttonText="Approve"
                buttonTypeClassName="success-btn"
                isDisable={props?.isPastCPD}
                onClick={handleApproveButton}
                isLoading={cpdSubmissionData?.approveIsLoading}
              />
            </div>
          </div>
        </div>
        <div className='center-button'>
          {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
              onClick={handleSubmit}
            />
          </div> */}
        </div>

      </div>
        : <DataLoader />}
    </React.Fragment>
  );
})

export default PublicationofResearchPaper;
