import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { AdditionalDescriptionFormData } from './AdditionalDescriptionForm.Data';
import CardSection from '../../../../../components/ui/card/CardSection';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';

const AdditionalDescription = forwardRef((props, ref) => {
    const [additionalDescriptionForms, setAdditionalDescriptionForms] = useState([AdditionalDescriptionFormData]);
    const additionalDescriptionFormRefs = useRef([]); // Array to hold refs for each FormCreator

    // Add a new FormCreator section
    const handleAddAdditionalDescriptionSection = () => {
        setAdditionalDescriptionForms(prevForms => [...prevForms, AdditionalDescriptionFormData]);
    };

    // Delete a specific FormCreator section
    const handleDeleteAdditionalDescriptionSection = (index) => {
        setAdditionalDescriptionForms(prevForms => prevForms.filter((_, i) => i !== index));
        additionalDescriptionFormRefs.current = additionalDescriptionFormRefs.current.filter((_, i) => i !== index); // Remove ref for deleted FormCreator
    };

    // Expose a method to get data from all FormCreators
    const handleSubmit = () => {
        const allFormData = additionalDescriptionFormRefs.current.map(additionalDescriptionFormref => additionalDescriptionFormref?.current?.getFormData());
        console.log('All Form Data:', allFormData);
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return additionalDescriptionFormRefs.current.map(additionalDescriptionFormref => additionalDescriptionFormref?.current?.getFormData());
        }
    }));

    return (
        <React.Fragment>
            <CardSection cardTitle="Additional Description">
                {additionalDescriptionForms.map((formData, index) => {
                    // Initialize the ref for each FormCreator if it doesn't already exist
                    if (!additionalDescriptionFormRefs.current[index]) {
                        additionalDescriptionFormRefs.current[index] = React.createRef();
                    }
                    const additionalDescriptionFormref = additionalDescriptionFormRefs.current[index];

                    return (
                        <div className='row' key={index}>
                            <FormCreator
                                ref={additionalDescriptionFormref}
                                {...formData}
                            />
                            <Buttons
                                buttonText="Delete"
                                buttonTypeClassName="danger-btn"
                                onClick={() =>handleDeleteAdditionalDescriptionSection(index)}
                            />
                        </div>
                    );
                })}
                <div className='row'>
                    <div className='col-lg-12'>
                        <Buttons
                            buttonText="Add Additional Description"
                            buttonTypeClassName="success-btn mb-3"
                            onClick={handleAddAdditionalDescriptionSection}
                        />
                        <Buttons
                            buttonText="Submit"
                            buttonTypeClassName="primary-btn mb-3"
                            onClick={handleSubmit} // Calls handleSubmit to print all form data
                        />
                    </div>
                </div>
            </CardSection>
        </React.Fragment>
    );
});

export default AdditionalDescription;
