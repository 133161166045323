import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TextBoxComponentData } from './TextBoxComponent.Data';
import FormCreator from '../../../../../../components/Forms/FormCreator';

// TextBoxComponent is a form component that uses FormCreator to render a form field
// This component allows parent components to retrieve its form data
const TextBoxComponent = forwardRef((props, ref) => {
    // Reference for FormCreator component to access its methods
    const textboxRef = useRef();

    // State to manage the form data for the TextBoxComponent
    const [textBoxForm, setTextBoxForm] = useState(TextBoxComponentData);

    // useImperativeHandle to expose `getFormData` method to parent components
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            // Retrieve form data from FormCreator if the reference exists
            if (ref?.current) {
                const textBoxFormData = textboxRef.current.getFormData();
                return textBoxFormData;
            }
            return null;
        }
    }));

    // Handle form field changes, specifically for the "required" validation setting
    const handleFormFieldChange = (fieldName, fieldValue) => {
        // If "required" field is changed, update validation of "textbox" field
        if (fieldName === "required") {
            const { formFields } = TextBoxComponentData;
            const textboxIndex = formFields.findIndex(field => field.id === "textbox");

            if (textboxIndex > -1) {
                const updatedFormData = {
                    ...TextBoxComponentData,
                    formFields: formFields.map((field, index) =>
                        index === textboxIndex
                            ? { ...field, validation: fieldValue ? [{ type: "require" }] : [] }
                            : field
                    )
                };
                // Update the form state with the new validation rule
                setTextBoxForm(updatedFormData);
            }
        }
    };

    // Render the TextBoxComponent UI
    return (
        <div className='container-fluid'>
            <div className='row'>
                {/* Render FormCreator component with the current textBoxForm data and handle form field changes */}
                <FormCreator ref={textboxRef} {...textBoxForm} onFormFieldValueChange={handleFormFieldChange} />
            </div>
        </div>
    );
});

export default TextBoxComponent;
