import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import gmapApplicationTaskListApi from "./services/GMAP/gmapApplicationTaskListApi";
import gmapPricingTableApi from "./services/GMAP/gmapPricingTableApi";
import commonApi from "./services/common/commonApi";
import gmapViewIndividualMemberListApi from "./services/GMAP/gmapViewIndividualMember";
import gmapMemberDirectoryListApi from "./services/GMAP/gmapMemberDirectoryApi";
import editApplicationTaskListApi from "./services/GMAP/editApplicationTaskListApi";
import careerHistoryApi from "./services/GMAP/careerHistoryApi";
import certificateCourseApi from "./services/GMAP/CertificateCourseApi";
import greenMarkProjectApi from "./services/GMAP/GreenMarkProjectApi";
import EmailTemplateManagementApi from "./services/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi";
import DropdownManagementApi from "./services/FieldManagement/DropdoenManagement/DropdownManagementApi";
import ApproveCPDSubmissionApi from "./services/CPD/ApproveCPDSubmissionApi";
import CpdSubmissionApi from "./services/CPD/CpdSubmissionApi";
import CPDApi from "./services/CPD/CPDApi";
import SaveCPDSubmissionApi from "./services/CPD/SaveCPDSubmissionApi";
import authReducer from './slice/authSlice';
import authApi from "./services/login/authApi";
import membershipFinancialService from "./services/GMAP/membershipFinancialService";
import DiscrertionaryRenewalApi from "./services/GMAP/DiscrertionaryRenewalApi";
import gmapApplicationApi from "./services/GMAP/gmaApplicationApi";
import XeroBatchExtractionApi from "./services/Financials/XeroBatchExtractionApi";
import adminDashBoardApi from "./services/DashBoard/adminDashBoardApi";
import eventRegistrationApprovalApi from "./services/EventManagement/EventRegistrationApproval/eventRegistrationApprovalApi";
import eventQuestionAnswerApi from "./services/EventManagement/EventQuestionAnswer/eventQuestionAnswerApi";
import eventFeedbackQuestionApi from "./services/EventManagement/EventFeedbackQuestion/eventFeedbackQuestionApi";
import ViewEditEventApi from "./services/EventManagement/ViewEditEvent/ViewEditEventApi";
import loginApi from "./services/GMAP/login";
import TransactionReportApi from "./services/Financials/TransactionReport";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [authApi.reducerPath]: authApi.reducer,
        [commonApi.reducerPath]: commonApi.reducer,
        [gmapApplicationTaskListApi.reducerPath]: gmapApplicationTaskListApi.reducer,
        [gmapPricingTableApi.reducerPath]: gmapPricingTableApi.reducer,
        [gmapViewIndividualMemberListApi.reducerPath]: gmapViewIndividualMemberListApi.reducer,
        [gmapMemberDirectoryListApi.reducerPath]: gmapMemberDirectoryListApi.reducer,
        [editApplicationTaskListApi.reducerPath]: editApplicationTaskListApi.reducer,
        [careerHistoryApi.reducerPath]: careerHistoryApi.reducer,
        [certificateCourseApi.reducerPath]: certificateCourseApi.reducer,
        [greenMarkProjectApi.reducerPath]: greenMarkProjectApi.reducer,
        [EmailTemplateManagementApi.reducerPath]: EmailTemplateManagementApi.reducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [DropdownManagementApi.reducerPath]: DropdownManagementApi.reducer,
        [ApproveCPDSubmissionApi.reducerPath]: ApproveCPDSubmissionApi.reducer,
        [CpdSubmissionApi.reducerPath]: CpdSubmissionApi.reducer,
        [CPDApi.reducerPath]: CPDApi.reducer,
        [SaveCPDSubmissionApi.reducerPath]: SaveCPDSubmissionApi.reducer,
        [membershipFinancialService.reducerPath]: membershipFinancialService.reducer,
        [DiscrertionaryRenewalApi.reducerPath]: DiscrertionaryRenewalApi.reducer,
        [gmapApplicationApi.reducerPath]: gmapApplicationApi.reducer,
        [XeroBatchExtractionApi.reducerPath]: XeroBatchExtractionApi.reducer,
        [adminDashBoardApi.reducerPath]: adminDashBoardApi.reducer,
        [eventRegistrationApprovalApi.reducerPath]: eventRegistrationApprovalApi.reducer,
        [eventQuestionAnswerApi.reducerPath]: eventQuestionAnswerApi.reducer,
        [eventFeedbackQuestionApi.reducerPath]: eventFeedbackQuestionApi.reducer,
        [ViewEditEventApi.reducerPath]: ViewEditEventApi.reducer,
        [TransactionReportApi.reducerPath]: TransactionReportApi.reducer,

    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        authApi.middleware,
        gmapApplicationTaskListApi.middleware,
        gmapPricingTableApi.middleware,
        commonApi.middleware,
        gmapViewIndividualMemberListApi.middleware,
        gmapMemberDirectoryListApi.middleware,
        editApplicationTaskListApi.middleware,
        careerHistoryApi.middleware,
        certificateCourseApi.middleware,
        greenMarkProjectApi.middleware,
        EmailTemplateManagementApi.middleware,
        loginApi.middleware,
        DropdownManagementApi.middleware,
        ApproveCPDSubmissionApi.middleware,
        CpdSubmissionApi.middleware,
        CPDApi.middleware,
        SaveCPDSubmissionApi.middleware,
        membershipFinancialService.middleware,
        DiscrertionaryRenewalApi.middleware,
        gmapApplicationApi.middleware,
        XeroBatchExtractionApi.middleware,
        adminDashBoardApi.middleware,
        eventRegistrationApprovalApi.middleware,
        eventQuestionAnswerApi.middleware,
        eventFeedbackQuestionApi.middleware,
        ViewEditEventApi.middleware,
        TransactionReportApi.middleware,
    )

})
setupListeners(store.dispatch)