import { useEffect, useRef, useState } from "react";
import { AccountListFormData, accountManagementConfig, viewListDataForm } from "./data/AccountManagement.data";
import FormCreator from "../../../components/Forms/FormCreator";
import Buttons from "../../../components/ui/button/Buttons";
import MolGrid from "../../../components/Grid/MolGrid";
import { useDeleteAdminDetailsByIdMutation, useGetAdminDetailListMutation, useLazyGetAdminDetailByIdQuery } from "../../../app/services/GMAP/gmaApplicationApi";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import SwalAlert from "../../../services/swalService/SwalService";
import { useParams } from "react-router-dom";
import EditViewAccount from "./EditViewAccount";
import { encryptAESID } from "../../../utils/Encryption";

const ViewAccountTaskList = () => {

    const molGridRef = useRef();
    const accountManagementFormDataRef = useRef();
    const [formData, setFormData] = useState(AccountListFormData);
    const [dataSource, setDataSource] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        SearchText: "",
        totalCount: 0,
    });
    const [isModelOpen, setIsModalOpen] = useState(false);
    const { success, error, confirm } = SwalAlert();

    const [selectedId, setSelectedId] = useState();

    const [getMemberList, { isSuccess, isLoading, data: memberListResponse }] = useGetAdminDetailListMutation();
    const [deleteDropdowndata, { isSuccess: isSuccessdeleteDropdowndata, data: deleteDropdowndataResponse }] = useDeleteAdminDetailsByIdMutation();

    useEffect(() => {
        getMemberList(paginationRequest);
    }, []);

    useEffect(() => {
        if (!isLoading && isSuccess && memberListResponse) {
            setPaginationRequest(prev => ({ ...prev, totalCount: memberListResponse.totalCount }));
            setDataSource(memberListResponse?.itemList);
        }
    }, [isLoading, isSuccess, memberListResponse]);

    useEffect(() => {
        if (isSuccessdeleteDropdowndata && deleteDropdowndataResponse) {
            if (deleteDropdowndataResponse.hasError === false) {
                success("Account Deleted Successfully");
                getMemberList(paginationRequest)
            }
            else {
                error(deleteDropdowndataResponse.errorMessage)
            }
        }
    }, [isSuccessdeleteDropdowndata, deleteDropdowndataResponse]);

    const handleSearch = () => {
        if (!accountManagementFormDataRef.current) {
            return;
        }
        const taskListFormData = accountManagementFormDataRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            SearchText: taskListFormData.name || "",
        };
        setPaginationRequest(updatedPaginationRequest);
        getMemberList(updatedPaginationRequest);
    };

    const handlePageChange = ({ pageNumber, pageSize }) => {
        const updatedPaginationRequest = { ...paginationRequest, pageNo: pageNumber, pageSize: pageSize };
        setPaginationRequest(updatedPaginationRequest);
        getMemberList(updatedPaginationRequest);
    };
    const handleEditClick = (data) => {
        setSelectedId(data.adminID)
        setIsModalOpen(!isModelOpen)
    };
    const openCloseModal = () => {
        setIsModalOpen(!isModelOpen);
        getMemberList(paginationRequest);
    };
    const handleDelete = (data) => {
        confirm('Delete', "Are you sure you want to delete", 'Yes', 'Cancel')
            .then(value => {
                if (value) {
                    const request = {
                        Id: data.adminID,
                        deletedBy: 115,
                        appId: 1
                    }
                    deleteDropdowndata(request)
                }
            })
    }

    const actionHandler = {
        EDIT: handleEditClick,
        DELETE: handleDelete,
    }
    return (
        <div className='container-fluid'>
            <div className='row mt-4'>
                <FormCreator ref={accountManagementFormDataRef} {...formData} />
                <div className='col-lg-1 d-flex justify-content-start'>
                    <Buttons
                        buttonText="Search"
                        buttonTypeClassName="success-btn ml-2 mt-4"
                        onClick={handleSearch}
                    />
                </div>

            </div>

            <div className='row mt-4'>
                <div className='col-lg-12'>
                    {isModelOpen && (
                        <CenterModel
                            modelTitle={'Edit view'}
                            show={"test"}
                            handleClose={openCloseModal}
                            onUpload={openCloseModal}
                        >
                            <EditViewAccount selectedId={encryptAESID(selectedId)} />
                        </CenterModel>

                    )}
                    <MolGrid
                        ref={molGridRef}
                        configuration={accountManagementConfig}
                        dataSource={dataSource}
                        allowPagination={true}
                        isLoading={isLoading}
                        pagination={paginationRequest}
                        onPageChange={handlePageChange}
                        onActionChange={actionHandler}
                    />

                </div>
            </div>
        </div>


    );
};

export default ViewAccountTaskList;
