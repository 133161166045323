import { FormFieldTypes } from "../../../../../../data/formFieldType";

export const FeedbackQuestionsFormData = {
    name: "FeedbackQuestionsFormData",
    initialState: {
        isRequiredAdditionalInformation: 'no',
    },
    formFields: [
        {
            id: "isRequiredAdditionalInformation",
            label: "",
            Field_Name: "Additional Information",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isRequiredAdditionalInformation",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "I do not require any additional information from the attendee.", value: "no", isDisable: false, optionClass: "", },
                    { label: "I would like to ask the attendee some additional questions before they complete their booking. Maximum 5 questions.", value: "yes", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}