import { useRef } from "react";
import { viewListDataForm } from "./data/AccountManagement.data";
import Buttons from "../../../components/ui/button/Buttons";
import FormCreator from "../../../components/Forms/FormCreator";
import { useAddEditAdminDetailsMutation, useLazyGetAdminDetailByIdQuery } from "../../../app/services/GMAP/gmaApplicationApi";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { decryptAESID } from "../../../utils/Encryption";
import SwalAlert from "../../../services/swalService/SwalService";
import { useState } from "react";

const EditViewAccount = (props) => {
    const viewListDataRef = useRef();
    const selectedId = props.selectedId ? decryptAESID(props.selectedId) : 0;
    const [viewList, setViewList] = useState(viewListDataForm)
    const { success, error } = SwalAlert();

    const [getAdminDetailById, { isSuccess: isSuccessAdminDetailsById, isFetching: isFetchingAdminDetailbyID, data: AdminDeatlisData }] = useLazyGetAdminDetailByIdQuery();
    const [addEditDetails, { isSuccess: isSuccesAddEditDetail, isFetching: isFetchingAddEditDetail, data: addEditAdminDetailsResponse }] = useAddEditAdminDetailsMutation();

    useEffect(() => {
        if (selectedId) {
            getAdminDetailById(selectedId);
        }
    }, []);

    useEffect(() => {
        if (!isFetchingAddEditDetail && isSuccesAddEditDetail && addEditAdminDetailsResponse) {
            if (addEditAdminDetailsResponse.hasError === false) {
                success("Account Details Update Successfully")
            }
            else {
                error(addEditAdminDetailsResponse.errorMessage)
            }
        }
    }, [isFetchingAddEditDetail, isSuccesAddEditDetail, addEditAdminDetailsResponse]);

    useEffect(() => {
        if (!isFetchingAdminDetailbyID && isSuccessAdminDetailsById && AdminDeatlisData) {
            let Data = { ...viewList }
            const { name, email } = AdminDeatlisData;
            Data.initialState = {
                name,
                email,
            };
            setViewList(Data);
        }
    }, [isFetchingAdminDetailbyID, isSuccessAdminDetailsById, AdminDeatlisData]);

    const handleaUpdate = () => {
        const formData = viewListDataRef.current.getFormData();
        const request = {
            AdminID: AdminDeatlisData?.adminID || 0,
            Name: formData.name,
            Email: formData.email,
            CreatedBy: 115,
            CreatedAppId: 1
        }

        addEditDetails(request)
    }

    return (<>
        <div className='row mt-4'>
            <FormCreator ref={viewListDataRef} {...viewList} />
            <div className='col-lg-1 d-flex justify-content-start'>
                <Buttons
                    buttonText="Save"
                    buttonTypeClassName="success-btn ml-2 mt-4"
                    onClick={handleaUpdate}
                />
            </div>

        </div>
    </>)
}

export default EditViewAccount;