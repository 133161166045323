import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { bcaGreenMarkProjectData } from "./BcaGreenMarkProject.Data";
import {
  useLazyGetPDFQuery,
  useUploadPDFMutation,
} from "../../../../../app/services/common/commonApi";
import { saveAs } from "file-saver";
import FormCreator from "../../../../../components/Forms/FormCreator";
import Buttons from "../../../../../components/ui/button/Buttons";
import { useLazyGetBcaGreenMarkProjectByIdQuery } from "../../../../../app/services/CPD/CpdSubmissionApi";
import { CurrentCPDCycleDataContext } from "../../CpdSubmissionForm";
import DataLoader from "../../../../../components/ui/dataLoader/DataLoader";
import SwalAlert from "../../../../../services/swalService/SwalService";
import { useAddEditBCAGreenMarkProjectMutation } from "../../../../../app/services/CPD/SaveCPDSubmissionApi";

const BcaGreenMarkProject = forwardRef((props, ref) => {
  const bcaGreenMarkProjectRef = useRef();
  const [bcaGreenMarkProjectForm, setbcaGreenMarkProjectForm] = useState(
    bcaGreenMarkProjectData
  );
  const { cpdSubmissionData, setCpdSubmissionData } = useContext(
    CurrentCPDCycleDataContext
  );
  const { success, error } = SwalAlert();
  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = bcaGreenMarkProjectRef.current.getFormData();
        return data;
      }
      return null;
    },
  }));

  const [
    getPDF,
    {
      isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData,
    },
  ] = useLazyGetPDFQuery();

  const [
    uploadPDF,
    { isSuccess: isSuccess, isLoading: isLoading, data: fileUploadData },
  ] = useUploadPDFMutation();

  const [
    getBcaGreenMarkProjectById,
    {
      isSuccess: getBcaGreenMarkProjectByIdIsSuccess,
      isLoading: getBcaGreenMarkProjectByIdIsLoading,
      data: getBcaGreenMarkProjectByIdData,
    },
  ] = useLazyGetBcaGreenMarkProjectByIdQuery();

  const [
    handleSubmitForm,
    { isSuccess: isSuccessForm, isLoading: isLoadingForm, data: formResponse },
  ] = useAddEditBCAGreenMarkProjectMutation();

  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);

  useEffect(() => {
    if (
      getBcaGreenMarkProjectByIdIsSuccess &&
      !getBcaGreenMarkProjectByIdIsLoading &&
      getBcaGreenMarkProjectByIdData
    ) {
      console.log({ getBcaGreenMarkProjectByIdData });
      let temp = { ...bcaGreenMarkProjectForm };
      let tempInitials = temp.initialState;
      tempInitials.projectName = getBcaGreenMarkProjectByIdData.projectName;
      tempInitials.greenMarkProjectNumber = getBcaGreenMarkProjectByIdData.greenMarkProjectNumber;
      tempInitials.dateofLetterofAward = getBcaGreenMarkProjectByIdData.dateofLetterofAward;
      tempInitials.awardRating = getBcaGreenMarkProjectByIdData.cpdAwardRatingId;
      tempInitials.gmapGreenMarkCategoryId = getBcaGreenMarkProjectByIdData.cpdGreenMarkCategoryId;
      tempInitials.developerBuildingOwner = getBcaGreenMarkProjectByIdData.developerOrbuildingOwner;
      tempInitials.describeYourRoleintheProject = getBcaGreenMarkProjectByIdData.describeRoleInProject;
      tempInitials.uploadEndorsementAttached = getBcaGreenMarkProjectByIdData.clientLetterOfEndorsement;
      tempInitials.uploadGreenMarkCategory = getBcaGreenMarkProjectByIdData.uploadGreenMarkCategory;
      tempInitials.CpdPoints = getBcaGreenMarkProjectByIdData.cpdPoints;
      tempInitials.remarks = getBcaGreenMarkProjectByIdData.remarks;
      tempInitials.processedBy = getBcaGreenMarkProjectByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getBcaGreenMarkProjectByIdData.cpdPoints;
      setbcaGreenMarkProjectForm(temp);
    }
  }, [
    getBcaGreenMarkProjectByIdIsSuccess,
    getBcaGreenMarkProjectByIdIsLoading,
    getBcaGreenMarkProjectByIdData,
  ]);

  useEffect(() => {
    getBcaGreenMarkProjectById(cpdSubmissionData.cpdSubmissionId);
  }, []);

  const handleDownload = async (data, dataField) => {
    let request = {
      type: "",
      fileName: "",
    };
    if (dataField === "uploadEndorsementAttached") {
      request.type = "LetterofEndorsement";
      request.fileName = fileUploadData?.fileName
        ? fileUploadData?.fileName
        : getBcaGreenMarkProjectByIdData.clientLetterOfEndorsement;
    } else if (dataField === "uploadGreenMarkCategory") {
      request.type = "GreenMarkCategoryFile";
      request.fileName = fileUploadData?.fileName
        ? fileUploadData?.fileName
        : getBcaGreenMarkProjectByIdData.uploadGreenMarkCategory;
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  };

  const handleFormFieldChange = (dataField, value) => {
    if (
      (dataField === "uploadEndorsementAttached" ||
        dataField === "uploadGreenMarkCategory") &&
      value
    ) {
      handleOnChangeFileUpload(dataField, value);
    }
  };

  const handleOnChangeFileUpload = (dataField, data) => {
    let pathName =
      dataField === "uploadEndorsementAttached"
        ? "LetterofEndorsement"
        : dataField === "uploadGreenMarkCategory" && "GreenMarkCategoryFile";
    let formData = new FormData();
    formData.append("file", data);
    formData.append("storagePath", pathName);
    uploadPDF(formData);
  };

  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };

  const handleSaveButton = () => {
    const formData = bcaGreenMarkProjectRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "");
    }
    let request = {
      CPDBcaGreenProjectInformationId: getBcaGreenMarkProjectByIdData.cpdBcaGreenProjectInformationId,
      GMAPMemberId: getBcaGreenMarkProjectByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getBcaGreenMarkProjectByIdData.cpdActivityTypeId,
      ProjectName: formData?.projectName || null,
      GreenMarkProjectNumber: formData?.greenMarkProjectNumber || null,
      DateofLetterofAward: formData?.dateofLetterofAward || null,
      CPDAwardRatingId: formData?.awardRating?.value ? formData?.awardRating?.value : formData?.awardRating,
      CPDGreenMarkCategoryId: formData?.gmapGreenMarkCategoryId?.value ? formData?.gmapGreenMarkCategoryId?.value : formData?.gmapGreenMarkCategoryId,
      DeveloperOrbuildingOwner: formData?.developerBuildingOwner || null,
      DescribeRoleInProject: formData?.describeYourRoleintheProject,
      ClientLetterOfEndorsement: fileUploadData?.fileName ? fileUploadData?.fileName : getBcaGreenMarkProjectByIdData.clientLetterOfEndorsement,
      UploadGreenMarkCategory: fileUploadData?.fileName ? fileUploadData?.fileName : getBcaGreenMarkProjectByIdData.uploadGreenMarkCategory,
      CPDSubmissionId: getBcaGreenMarkProjectByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    };
    handleSubmitForm(request);
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getBcaGreenMarkProjectByIdData.cpdSubmissionId,
      gmapMemberId: getBcaGreenMarkProjectByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    };
    if (props.formActionHandler) {
      props.formActionHandler["REJECT"](request);
    }
  };

  const handleApproveButton = () => {
    const formData = bcaGreenMarkProjectRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getBcaGreenMarkProjectByIdData.cpdSubmissionId,
      gmapMemberId: getBcaGreenMarkProjectByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    };
    if (props.formActionHandler) {
      props.formActionHandler["APPROVE"](request);
    }
  };
  return (
    <React.Fragment>
      {getBcaGreenMarkProjectByIdData ? (
        <div className="speaking-teaching-engagement">
          {/* <div className="cpd-submission-description">
          <p>
            Each Green Mark project may only be used once by each GMAP as a CPD activity.
            The award date of the green mark project stated on the Letter of Award (LOA) must be within 5 years of the renewal deadline.  </p>
          <p>(e.g. For renewal of certification due by 30 June 2022, the award date of the green mark project must not be earlier than 31 June 2017)</p>
          <p>
            Documentary proof required are i) Green Mark Project Letter of Award and ii) Project Involvement Verification Form OR Client's Letter of Endorsement
          </p>
          <div className='table-scroll mb-4'>
            The CPD points awarded depends on the type of project and rating achieved :
            <table className="border-table-simple mt-4">
              <thead>
                <tr>
                  <th className='whitespace-nowrap text-center' colspan="2">Green Mark Projects</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='whitespace-nowrap'>Green Mark Gold or above for Building Project</td>
                  <td className='whitespace-nowrap'>12</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap'>Green Mark Gold or above for Occupant-centric schemes</td>
                  <td className='whitespace-nowrap'>6</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            Click <a href="https://mcusercontent.com/cf5daef3e2509b2b8d51afefa/images/2e07af06-7cb8-49ed-9708-74d3b81303d4.png" target='none' >here</a> to view a sample of the Green Mark Project Letter of Award.
          </p>
          <p>
            Click <a href="https://mcusercontent.com/cf5daef3e2509b2b8d51afefa/files/78fbac98-e98b-4909-b33a-135faf9f2820/Project_involvement_verification_form.pdf" target='none' >here</a> to download the Project Involvement Verification Form.
          </p>
        </div> */}

          <div className="common-project-info-main card-section card-section-left">
            <div className="row gx-5">
              <FormCreator
                ref={bcaGreenMarkProjectRef}
                {...bcaGreenMarkProjectForm}
                onActionChange={formActionHandler}
                onFormFieldValueChange={handleFormFieldChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="common-custom-pagination mt-5">
              <div>
                <Buttons
                  buttonText="Save"
                  buttonTypeClassName="outline-btn"
                  onClick={handleSaveButton}
                  isDisable={props?.isPastCPD}
                />
              </div>
              <div className="d-flex column-gap-3">
                <Buttons
                  buttonText="Reject"
                  buttonTypeClassName="red-btn"
                  isDisable={props?.isPastCPD}
                  onClick={handleRejectbutton}
                />
                <Buttons
                  buttonText="Approve"
                  buttonTypeClassName="success-btn"
                  isDisable={props?.isPastCPD}
                  isLoading={cpdSubmissionData?.approveIsLoading}
                  onClick={handleApproveButton}
                />
              </div>
            </div>
          </div>
          <div className="center-button">
            {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
              onClick={handleSubmit}
            />
          </div> */}
          </div>
        </div>
      ) : (
        <DataLoader />
      )}
    </React.Fragment>
  );
});

export default BcaGreenMarkProject;
