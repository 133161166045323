import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ModeratorFormData } from './ModeratorForm.Data';
import CardSection from '../../../../../components/ui/card/CardSection';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { useStoreBase64ImageMutation } from '../../../../../app/services/common/commonApi';

const Moderator = forwardRef((props, ref) => {
    const [moderatorForms, setModeratorForms] = useState([ModeratorFormData]);
    const formRefs = useRef([]); // Array to hold refs for each FormCreator

    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: profileImageUpload }
    ] = useStoreBase64ImageMutation();


    // Add a new FormCreator section
    const handleAddModeratorSection = () => {
        setModeratorForms(prevForms => [...prevForms, ModeratorFormData]);
    };

    // Delete a specific FormCreator section
    const handleDeleteModeratorSection = (index) => {
        setModeratorForms(prevForms => prevForms.filter((_, i) => i !== index));
        formRefs.current = formRefs.current.filter((_, i) => i !== index); // Remove ref for deleted FormCreator
    };

    // Expose a method to get data from all FormCreators
    const handleSubmit = () => {
        const allFormData = formRefs.current.map(formRef => formRef?.current?.getFormData());
        console.log('All Form Data:', allFormData);
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return formRefs.current.map(formRef => formRef?.current?.getFormData());
        }
    }));

    // for file upload changes
    const handleFormFieldChange = async (dataField, value, currentState, formIndex) => {
        if (dataField === "eventImage") {
            const { base64Data, fileName } = value
            let saveFileRequest = {
                fileName: fileName,
                fileStr: base64Data,
                storagePath: "Events\\AdditionalModerator",
            }
            const response = await saveMainImage(saveFileRequest).unwrap();
            setModeratorForms((prev) => {
                return prev.map((obj, index) =>
                    index === formIndex ?
                        { ...obj, initialState: { ...obj.initialState, ...currentState, eventImageEncryptedName: response.fileName } }
                        : { ...obj }
                )
            }
            )
        }
    };

    //for file clear changes
    const handleClear = (data, dataField, currentState, formIndex) => {
        if (dataField === "eventImage") {
            setModeratorForms((prev) => {
                return prev.map((obj, index) =>
                    index === formIndex ?
                        { ...obj, initialState: { ...obj.initialState, ...currentState, eventImageEncryptedName: '', eventImage: '' } }
                        : { ...obj }
                )
            }
            )
        }
    }

    return (
        <React.Fragment>
            <CardSection cardTitle="Moderator">
                {moderatorForms.map((formData, index) => {
                    // Initialize the ref for each FormCreator if it doesn't already exist
                    if (!formRefs.current[index]) {
                        formRefs.current[index] = React.createRef();
                    }
                    const formRef = formRefs.current[index];

                    return (
                        <div className='row' key={index}>
                            <FormCreator
                                ref={formRef}
                                {...formData}
                                onFormFieldValueChange={(dataField, value, currentState) => handleFormFieldChange(dataField, value, currentState, index)}
                                onActionChange={{
                                    CLEAR: (data, dataField, formData) => handleClear(data, dataField, formData, index),
                                }}
                            />
                            <Buttons
                                buttonText="Delete"
                                buttonTypeClassName="danger-btn"
                                onClick={() => handleDeleteModeratorSection(index)}
                            />
                        </div>
                    );
                })}
                <div className='row'>
                    <div className='col-lg-12'>
                        <Buttons
                            buttonText="Add Moderator"
                            buttonTypeClassName="success-btn mb-3"
                            onClick={handleAddModeratorSection}
                        />
                        <Buttons
                            buttonText="Submit"
                            buttonTypeClassName="primary-btn mb-3"
                            onClick={handleSubmit} // Calls handleSubmit to print all form data
                        />
                    </div>
                </div>
            </CardSection>
        </React.Fragment>
    );
});
export default Moderator;
