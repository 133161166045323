import React, { forwardRef, useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, useParams } from "react-router-dom";
import CardSection from "../../../components/ui/card/CardSection";
import Buttons from "../../../components/ui/button/Buttons";
import PersonalParticulars from "./editComponent/PersonalParticulars/PersonalParticulars";
import AcademicQualification from "./editComponent/AcademicQualification/AcademicQualification";
import AccountInformation from "./editComponent/AccountInformation/AccountInformation";
import { useAppealGMAPMemberApplicationMutation, useLazyGetGMAPAccountInformationByMemberIdQuery, useLazyGetGMAPMemberDetailByMemberIdQuery, useLazyGetGMAPMembershipByMemberIdQuery, useLazyGetLockUnockMemberInformationByIdQuery, useLockUnockMemberInformationMutation, useRejectGMAPMemberApplicationMutation, useSaveGMAPMemberClarificationDetailsMutation, useSetApplicationStatusMutation, useUpdateGMAPMemberDetailsMutation } from "../../../app/services/GMAP/editApplicationTaskListApi";
import { useLazyGetAllDropDownsForMemberDirectoryQuery, useLazyGetAllDropDownsQuery } from "../../../app/services/common/commonApi";
import { setAllDropDownField } from "./editComponent/utils/dropdownUtils";
import { setCourseRequirementGrid, setFieldParameter } from "./editComponent/utils/setEditFieldsUtils";
import CareerHistory from "./editComponent/CareerHistory/CareerHistory";
import CertificateCourse from "./editComponent/CertificateCourse/CertificateCourse";
import GreenMarkProject from "./editComponent/GreenMarkProject/GreenMarkProject";
import { EditApplicationDataContext } from "../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import PageLoader from "../../../components/ui/pageLoader/PageLoader";
import { AppIcons } from "../../../data/appIcons";
import SwalAlert from "../../../services/swalService/SwalService";
import { careerHistoryData } from "./editComponent/CareerHistory/CareerHistory.data";
import { certificateCourseData } from "./editComponent/CertificateCourse/CertificateCourse.Data";
import { greenMarkProjectData } from "./editComponent/GreenMarkProject/GreenMarkProject.Data";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import TinyEditor from "../../../components/ui/inputs/TinyEditor/TinyEditor";
import { decryptAESID } from "../../../utils/Encryption";
import ToastService from "../../../services/toastService/ToastService";
import { useLazyGeneratePDFForGMAAPQuery, useLazyGeneratePDFForGMAPQuery, useLazyGeneratePDFForGMAQuery, useTaskListDataSnapshotMutation } from "../../../app/services/GMAP/gmapApplicationTaskListApi";
import GMAGenratePDF from "./component/GMAGenratePDF";
import ContinuingProfessionalDevelopment from "../EditIndividualMembersForm/component/ContinuingProfessionalDevelopment/ContinuingProfessionalDevelopment";



const EditApplicationTaskList = () => {
    const navigate = useNavigate();
    const personalParticularsRef = useRef(null)
    const academicQualificationRef = useRef(null)
    const accountInformationFormRef = useRef(null)
    const currentEmpInfoRef = useRef(null)
    const { id, taskListId } = useParams();
    const editID = id ? decryptAESID(id, false) : 0
    const listId = taskListId ? decryptAESID(taskListId, false) : 0
    const { confirm, success, error } = SwalAlert();
    const [newMemberData, setNewMemberData] = useState({
        applicationTypeId: null,
        academicCertificate: null,
        academicQualificationCertificationFileName: null,
        certificateMarketRefresherCourse: null,
        GMAPMemberSubscriptionId: null,
        profilePhotoFileName: null,
        profilePhotoFile: null,
        isCurrentlyEmployed: null
    });
    const [rejectComments, setRejectComments] = useState("");
    const [appealComments, setAppealComments] = useState("");
    const [clarificationsComments, setClarificationsComments] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenReject, setIsModalOpenReject] = useState(false);
    const [isModalOpenAppeal, setIsModalOpenAppeal] = useState(false);


    // const screenShotRef = useRef(null);
    // const [image, takeScreenshot] = useScreenshot({
    //     type: 'image/png',
    //     quality: 1.0
    // })

    const contextValue = {
        newMemberData,
        setContextData: (data) => setNewMemberData(data)
    };

    const [accordionItems, setAccordionItems] = useState([
        // { id: 5, header: "Continuing Professional Development", component: <ContinuingProfessionalDevelopment />, locked: true },
        { id: 0, header: "Personal Particulars", component: <PersonalParticulars ref={personalParticularsRef} />, locked: false },
        { id: 1, header: "Academic Qualification", component: <AcademicQualification ref={academicQualificationRef} />, locked: false },
        { id: 2, header: "Career History", component: <CareerHistory ref={currentEmpInfoRef} />, locked: false },
        { id: 3, header: "Course Requirement", component: <CertificateCourse />, locked: false },
        { id: 4, header: "Green Mark Project", component: <GreenMarkProject />, locked: false },
        // { id: 6, header: "GMAP Membership", component: <GmapMembership />, locked: false },

    ]);

    const [getMemberDetailById,
        { isSuccess: isSuccess,
            isLoading: isFetching,
            data: memberDetailsResponse },
    ] = useLazyGetGMAPMemberDetailByMemberIdQuery();

    const [getAccInfoById,
        { isSuccess: isSuccessAccInfo,
            isLoading: isFetchingAccInfo,
            data: accountInformationResponse },
    ] = useLazyGetGMAPAccountInformationByMemberIdQuery();

    const [getMembershipAccDetails,
        { isSuccess: isSuccessGetMembership,
            isLoading: isFetchingGetMembership,
            data: membershipAccResponse },
    ] = useLazyGetGMAPMembershipByMemberIdQuery();

    const [getAllDropDowns,
        { isSuccess: isGetAllDropDownFetched,
            isLoading: isGetDropDownFetching,
            data: allDropDownResponse },
    ] = useLazyGetAllDropDownsForMemberDirectoryQuery();

    const [getAccountInfoSectionDropDown,
        { isSuccess: isSuccessDD,
            isLoading: isLoadingDD,
            data: getAccDDResponse },
    ] = useLazyGetAllDropDownsQuery()

    const [setApplicationStatus,
        { isSuccess: isSuccessApp,
            isLoading: isFetchingApp,
            data: applicationStatusResponse },
    ] = useSetApplicationStatusMutation();

    const [updateGMAPTaskList,
        { isSuccess: isSuccessUpdate,
            isLoading: isFetchingAppUpdate,
            data: updateGMAPTaskListResponse },
    ] = useUpdateGMAPMemberDetailsMutation();

    const [rejectApplication,
        { isSuccess: isSuccessReject,
            isLoading: isFetchingAppReject,
            data: rejectApplicationResponse },
    ] = useRejectGMAPMemberApplicationMutation();

    const [appealApplication,
        { isSuccess: isSuccessAppeal,
            isLoading: isLoadingAppeal,
            data: appealApplicationResponse },
    ] = useAppealGMAPMemberApplicationMutation();


    const [saveClarificationDetails,
        { isSuccess: isSuccessCLR,
            isLoading: isFetchingAppCLR,
            data: clarificationApplicationResponse },
    ] = useSaveGMAPMemberClarificationDetailsMutation();

    const [LockUnlockTab,
        { isSuccess: isSuccessTab,
            isLoading: isFetchingAppTab,
            data: lockUnlockTabResponse },
    ] = useLockUnockMemberInformationMutation();
    const [getLockTabStatus,
        { isSuccess: isSuccessLock,
            isLoading: isFetchingLock,
            data: tabLockStatus },
    ] = useLazyGetLockUnockMemberInformationByIdQuery()

    const [saveSnapshot,
        { isSuccess: isSuccessSaveSnapshot,
            isLoading: isFetchingSaveSnapshot,
            data: saveSnapshotResponse },
    ] = useTaskListDataSnapshotMutation();

    useEffect(() => {
        if (!isFetchingSaveSnapshot && isSuccessSaveSnapshot && saveSnapshotResponse) {
            console.log({ saveSnapshotResponse })
        }
    }, [isFetchingSaveSnapshot, isSuccessSaveSnapshot, saveSnapshotResponse]);

    const [generatePDFForGMA,
        { isSuccess: isSuccessGetPDF,
            isLoading: isLoadingGetPDF,
            data: getPDFData }
    ] = useLazyGeneratePDFForGMAQuery();

    const [generatePDFForGMAP,
        { isSuccess: isSuccessGetPDFGMAP,
            isLoading: isLoadingGetPDFGMAP,
            data: getPDFDataGMAP }
    ] = useLazyGeneratePDFForGMAPQuery();

    const [generatePDFForGMAAP,
        { isSuccess: isSuccessGetPDFGMAAP,
            isLoading: isLoadingGetPDFGMAAP,
            data: getPDFDataGMAAP }
    ] = useLazyGeneratePDFForGMAAPQuery();

    // useEffect(() => {
    //     if (image) {
    //         ImageToPdf(image)
    //     }
    // }, [image])

    const toggleTabLock = (header, locked) => {
        let request = {
            GMAPMemberId: editID,
            Locked: !locked,
            Identities: header,
        }
        confirm(`${!locked ? 'Locked Tab?' : 'Unlocked Tab?'}`, `Are you sure you want to ${!locked ? 'Locked?' : 'Unlocked?'}`, "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    LockUnlockTab(request)

                }
            });
    };

    useEffect(() => {
        if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
            setAllDropDownField(allDropDownResponse);
        }
    }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

    useEffect(() => {
        if (!isLoadingDD && isSuccessDD && getAccDDResponse) {
            setAllDropDownField(getAccDDResponse, "signupDropdown");
        }
    }, [isLoadingDD, isSuccessDD, getAccDDResponse]);


    useEffect(() => {

        getLockTabStatus(editID);
        resetTableHistory(editID);

    }, [editID])

    useEffect(() => {
        if (!isFetching && isSuccess && memberDetailsResponse) {
            const updatedData = {
                ...newMemberData,
                applicationTypeId: memberDetailsResponse.gmapApplicationTypeId,
                profilePhotoFile: memberDetailsResponse?.professionalProfilPhoto,
                profilePhotoFileName: memberDetailsResponse?.professionalProfilPhotoName,
                academicCertificate: memberDetailsResponse?.academicCertificate,
                academicQualificationCertificationFileName: memberDetailsResponse?.academicCertificateName,
                isCurrentlyEmployed: memberDetailsResponse?.isCurrentlyEmployed,
            };
            setNewMemberData({ ...updatedData });
            setFieldParameter(memberDetailsResponse, "memberDetails");
            setCourseRequirementGrid(memberDetailsResponse.gmapApplicationTypeId);

        }
    }, [isFetching, isSuccess, memberDetailsResponse]);

    useEffect(() => {
        if (!isFetchingGetMembership && isSuccessGetMembership && membershipAccResponse) {
            setFieldParameter(membershipAccResponse, "membershipAccResponse");
        }
    }, [isFetchingGetMembership, isSuccessGetMembership, membershipAccResponse]);

    useEffect(() => {
        if (!isFetchingAccInfo && isSuccessAccInfo && accountInformationResponse) {
            setNewMemberData({
                ...newMemberData, GMAPMemberSubscriptionId: accountInformationResponse.gmapMemberSubscriptionId
            });
            setFieldParameter(accountInformationResponse, "accountInformation");
        }
    }, [isFetchingAccInfo, isSuccessAccInfo, accountInformationResponse]);

    useEffect(() => {
        if (!isFetchingApp && isSuccessApp && applicationStatusResponse) {
            if (applicationStatusResponse.subscriptionStatusId === 1) {
                success("", "Application Approved Successfully")
                let memberIdDecrypted = decryptAESID(id);
                let taskIdDecrypted = decryptAESID(taskListId);
                let response = "";
                let requet = { id: memberIdDecrypted.toString(), taskListId: taskIdDecrypted.toString() }
                if (applicationStatusResponse.gmapApplicationTypeId === 1) {
                    response = generatePDFForGMA(requet);
                } else if (applicationStatusResponse.gmapApplicationTypeId === 2) {
                    response = generatePDFForGMAP(requet);
                } else if (applicationStatusResponse.gmapApplicationTypeId === 3) {
                    response = generatePDFForGMAAP(requet);
                }
            }
            else {
                error("Subscription status is not becaome Active ", "Contact SGBC");
            }
        }
    }, [isFetchingApp, isSuccessApp, applicationStatusResponse]);

    useEffect(() => {
        if (!isFetchingAppUpdate && isSuccessUpdate && updateGMAPTaskListResponse) {
            success("", "Application Updated Successfully ")
        }
    }, [isFetchingAppUpdate, isSuccessUpdate, updateGMAPTaskListResponse]);

    useEffect(() => {
        if (!isFetchingAppReject && isSuccessReject && rejectApplicationResponse) {
            success("", "Application Reject Successfully ")
        }
    }, [isFetchingAppReject, isSuccessReject, rejectApplicationResponse]);

    useEffect(() => {
        if (!isLoadingAppeal && isSuccessAppeal && appealApplicationResponse) {
            success("", "Application Appeal Successfully ")
            setIsModalOpenAppeal(!isModalOpenAppeal)
            getAccInfoById(editID);
        }
    }, [isLoadingAppeal, isSuccessAppeal, appealApplicationResponse]);

    useEffect(() => {
        if (!isFetchingAppCLR && isSuccessCLR && clarificationApplicationResponse) {
            success("The clarification email has been successfully sent.", "Sent Email")
            navigate(`/gmapApplicationTasklist`)
        }
    }, [isFetchingAppCLR, isSuccessCLR, clarificationApplicationResponse]);

    useEffect(() => {
        if (!isFetchingAppTab && isSuccessTab && lockUnlockTabResponse) {
            setAccordionItems(prevItems => prevItems.map(item =>
                item.header === lockUnlockTabResponse.identities ? { ...item, locked: lockUnlockTabResponse.locked } : item
            ));
            ToastService.success(`${lockUnlockTabResponse.identities} has been successfully ${lockUnlockTabResponse.locked ? "Locked." : "Unlocked."}`)
        }
    }, [isFetchingAppTab, isSuccessTab, lockUnlockTabResponse]);

    useEffect(() => {
        if (!isFetchingLock && isSuccessLock && tabLockStatus) {
            setAccordionItems(prevItems => prevItems.map((item, index) => {
                return item.header === tabLockStatus[index].identities ? { ...item, locked: tabLockStatus[index].locked || false } : item
            }
            ));
        }
    }, [isFetchingLock, isSuccessLock, tabLockStatus]);

    const resetTableHistory = (id) => {
        const tableIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
        const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
        const greenMarkProjectIndex = greenMarkProjectData.formFields.findIndex(field => field.id === "greenMarkProjectTable");
        if (tableIndex !== -1) {
            const updatedFormData = { ...careerHistoryData };
            updatedFormData.formFields[tableIndex].fieldSetting.dataSource = null;
        }
        if (courseHistoryIndex !== -1) {
            const updatedFormData = { ...certificateCourseData };
            updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = null;
        }
        if (greenMarkProjectIndex !== -1) {
            const updatedFormData = { ...greenMarkProjectData };
            updatedFormData.formFields[greenMarkProjectIndex].fieldSetting.dataSource = null;
        }
        getAccountInfoSectionDropDown();
        getAllDropDowns();

        if (id) {
            getAccInfoById(id);
            getMembershipAccDetails(id);
            getMemberDetailById(id);
        }
    }

    const handleUpdate = () => {
        let personalParticularData = personalParticularsRef.current.getFormData();
        let academicQualificationData = academicQualificationRef.current.getFormData();
        let accountInformationData = accountInformationFormRef.current.getFormData();
        if (!personalParticularData || !academicQualificationData || !accountInformationData) {
            return false
        }

        let request = {
            GMAPMemberId: editID,
            IsAppeal: accountInformationData?.appeal || false,
            VoidLateFees: accountInformationData?.voidLateFee || false,
            IsQualify: accountInformationData?.qualify || false,
            GMAPSalutationId: personalParticularData?.salutationsID,
            FullName: personalParticularData?.fullName,
            PrimaryEmail: personalParticularData?.primaryEmail,
            SecondaryEmail: personalParticularData?.secondaryEmail,
            DateOfBirth: personalParticularData?.dateOfBirthDate,
            MobilePhone: personalParticularData?.mobilePhone,
            IsGenderMale: personalParticularData?.isGenderMale === "male" ? true : false,
            GMAPNationalityId: personalParticularData?.nationalityID,
            AddressLine1: personalParticularData?.addressLine1,
            AddressLine2: personalParticularData?.addressLine2,
            AddressLine3: personalParticularData?.addressLine3,
            PostalCode: personalParticularData?.postalCode,
            CountryofResidenceId: personalParticularData?.countryOfResID,
            ProfessionalProfileDescription: personalParticularData?.comments,
            ProfessionalProfilPhotoName: newMemberData?.profilePhotoFileName || null,
            ProfessionalProfilPhoto: newMemberData?.profilePhotoFile || null,
            LinkedInAddressOrProfessionalHomepage: personalParticularData?.linkedinAdd,
            GMAPCompetenciesId: personalParticularData?.competenciesID?.join(','),
            GMAPOtherProfessionalQualificationId: personalParticularData?.otherProfessionQualificationID?.join(','),
            GMAPHighestRelevantQualificationId: academicQualificationData?.highestQualificationID,
            FieldOfStudy: academicQualificationData?.fieldOfStudy,
            GraduateYearId: academicQualificationData?.graduateYearID,
            AcademicInstitution: academicQualificationData?.academicQualification,
            AcademicCertificate: newMemberData?.academicCertificate || null,
            AcademicCertificateName: newMemberData?.academicQualificationCertificationFileName || null,
            IsPendingExpiry: accountInformationData?.pendingExpiry || false
        }
        updateGMAPTaskList(request)
    };

    const handleAcceptApp = () => {
        confirm("Lock all sections?", '', "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    // const isGMAApplication = memberDetailsResponse.gmapApplicationTypeId === 1;
                    const isGMAApplication = true;
                    let filteredAccordionItems = accordionItems;

                    // if (isGMAApplication) {

                    //     filteredAccordionItems = accordionItems.filter(item => item.id !== 4);
                    //     filteredAccordionItems.every(lock => lock.locked = true)
                    // }
                    filteredAccordionItems.every(lock => lock.locked = true)

                    let arrObjWithoutComponent = filteredAccordionItems.map(({ component, ...rest }) => rest);

                    let allLocked = arrObjWithoutComponent.every(tab => tab.locked === true);
                    if (allLocked) {
                        let request = {
                            GMAPMemberSubscriptionId: newMemberData.GMAPMemberSubscriptionId,
                            ApplicationTasklistId: listId,
                            GMAPMemberId: editID,
                            UpdatedBy: 115,
                            UpdatedAppId: 1,
                            IsLockPersonalParticulars: true,
                            IsLockAcademicQualification: true,
                            IsLockCareerHistory: true,
                            IsLockCourseRequirement: true,
                            IsLockGreenMarkProjectInformation: true,
                            IsUpgrade: accountInformationResponse?.isUpgrade
                            // IsLockGMAPMembership:true,
                            // IsLockContinuingProfessionalDevelopment:true 
                        }
                        setApplicationStatus(request);
                    } else {
                        error("Please ensure all tabs are locked before submitting the application", "")
                    }

                }
            });


    }
    const handleRejectApp = () => {
        if (rejectComments === "" || rejectComments === undefined || rejectComments === null) {
            return error("The comments field for rejecting the application is mandatory and must be filled out.", "error")
        }
        let request = {
            GMAPMemberId: editID,
            IsRejected: true,
            ApplicationTasklistId: listId,
            SendRejectionReason: rejectComments

        }
        confirm("Reject Application?", "Are you sure you want to Reject?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    rejectApplication(request);
                }
            });
    }
    const handleAppeal = () => {
        if (appealComments === "" || appealComments === undefined || appealComments === null) {
            return error("The comments field for Appeal the application is mandatory and must be filled out.", "error")
        }
        let request = {
            GMAPMemberId: editID,
            IsAppeal: true,
            ApplicationTasklistId: listId,
            SendAppealReason: appealComments
        }
        confirm("Appeal Application?", "Are you sure you want to Appeal?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {

                }
                appealApplication(request);
            });
    }
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }
    const openModalRejectApp = () => {
        setIsModalOpenReject(!isModalOpenReject);
    }
    const openModalAppeal = () => {
        setIsModalOpenAppeal(!isModalOpenAppeal);
    }
    const handleSendClearfication = () => {
        let request = {
            GMAPMemberId: editID,
            ApplicationTasklistId: listId,
            ClarificationDetails: clarificationsComments,
        }
        confirm("Send Clarification?", "Are you sure you want to send clarification?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    saveClarificationDetails(request);
                }
            });
    }

    const tabs = parseInt(memberDetailsResponse?.gmapApplicationTypeId) === 1 ? accordionItems?.filter(obj => obj.id !== 4) : accordionItems;

    const handleTinyOnChange = (text, section) => {
        switch (section) {
            case "Clarifications":
                setClarificationsComments(text);
                break;
            case "Reject":
                setRejectComments(text);
                break;
            case "Appeal":
                setAppealComments(text);
                break;
            default:
                break;
        }

    };

    // const ImageToPdf = async (base64Image) => {
    //     try {
    //         const blob = await imgSrcToBlob(base64Image);
    //         // const imageURL = URL.createObjectURL(blob);
    //         const request = {
    //             data: base64Image,
    //         }
    //         saveSnapshot(request);
    //     } catch (error) {
    //         console.error('Error creating PDF:', error);
    //     }
    // }
    // const generatePDFScreenShot = () => {
    //     const element = screenShotRef.current;
    //     if (!element) return;
    //     takeScreenshot(element)
    // };
    const handleBack = () => {
        navigate(`/gmapApplicationTasklist`)
    }
    const tier = accountInformationResponse?.upgradeTier === 2 ? "GMAP" : accountInformationResponse?.upgradeTier === 3 ? "GMAAP" : '';


    return (
        <>
            {/* <div ref={screenShotRef}> */}
            <CardSection cardTitle="Account Information" rightButton={true} rightButtonComponent={<GMAGenratePDF type={memberDetailsResponse?.gmapApplicationTypeId} />} isRightButtonComponent={true}>
                {/* <GMAGenratePDF type={memberDetailsResponse?.gmapApplicationTypeId} /> */}
                <div className="row align-items-end mb-2">
                    <div className="col-10">

                        {isSuccessAccInfo ? <AccountInformation ref={accountInformationFormRef} /> : <PageLoader />}

                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <div className="reset-password-button">
                            <Buttons
                                buttonText="Reset Password"
                                buttonTypeClassName="success-btn"
                            />
                        </div>
                    </div>
                </div>
            </CardSection>
            <CardSection cardTitle="CPD Information">
                <div className="row align-items-end mb-2">
                    <div className="col-lg-12">
                        <ContinuingProfessionalDevelopment isViewButton={false} />
                    </div>
                </div>
            </CardSection>
            <CardSection cardTitle="Upgrade Information">
                <div className="row align-items-end mb-2">
                    <div className="col-lg-12">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='continuing-prodessional-developement row'>
                                    <div>
                                        <p>Upgrade Tier To</p>
                                    </div>
                                    <div>
                                        <h6>{tier}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardSection>
            <div className="common-accordian-view-design">
                {(isSuccess && !isFetching && memberDetailsResponse?.gmapApplicationTypeId)
                    ? (
                        <EditApplicationDataContext.Provider value={contextValue}>
                            <Accordion alwaysOpen>
                                {tabs?.map((item, index) => (
                                    <Accordion.Item key={index} eventKey={index.toString()}>
                                        <Accordion.Header>
                                            {item.id !== 5 && (
                                                <Buttons
                                                    imagePath={item.locked ? AppIcons.lockIcon : AppIcons.unLockIcon}
                                                    buttonTypeClassName="btn-drop"
                                                    textWithIcon={true}
                                                    onClick={() => toggleTabLock(item.header, item.locked)}
                                                />
                                            )}
                                            {item.header}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                {item.component}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </EditApplicationDataContext.Provider>
                    ) : (
                        <PageLoader />
                    )}
            </div>
            {/* </div> */}
            <div className="common-custom-pagination edittasklist-btn-width d-flex flex-nowrap">
                <Buttons
                    buttonText="Accept & Approve Application"
                    isLoading={isFetchingApp}
                    buttonTypeClassName="success-btn btn-sm mx-2"
                    onClick={handleAcceptApp}
                    isDisable={!isSuccess}
                    locked={true}
                />
                <Buttons
                    buttonText="Appeal"
                    buttonTypeClassName="back-btn btn-sm mx-2"
                    onClick={openModalAppeal}

                />
                <Buttons
                    buttonText="Send Clarifications"
                    buttonTypeClassName="back-btn btn-sm mx-2"
                    onClick={openModal}
                />
                <Buttons
                    buttonText="Reject Application"
                    buttonTypeClassName="red-btn btn-sm mx-2"
                    isLoading={isFetchingAppReject}
                    onClick={openModalRejectApp}
                />
                <Buttons
                    buttonText="Update"
                    isLoading={isFetchingAppUpdate}
                    buttonTypeClassName="outline-btn btn-sm mx-2"
                    onClick={handleUpdate}
                />
                <Buttons
                    buttonText="Exit Application"
                    buttonTypeClassName="red-btn btn-sm mx-2"
                    isLoading={isFetchingAppReject}
                    onClick={handleBack}
                />
            </div>
            {isModalOpen &&
                <CenterModel
                    modelTitle={'Send Clarifications'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Clarifications")}
                    />
                    <Buttons
                        buttonText="Send Email"
                        isLoading={isFetchingAppUpdate}
                        buttonTypeClassName="outline-btn mt-4"
                        onClick={handleSendClearfication}
                    />
                </CenterModel>
            }
            {isModalOpenReject &&
                <CenterModel
                    modelTitle={'Reject Application'}
                    show={"test"}
                    handleClose={openModalRejectApp}
                    onUpload={openModalRejectApp}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Reject")}
                    />
                    <Buttons
                        buttonText="Reject Application"
                        buttonTypeClassName="red-btn mt-5"
                        isLoading={isFetchingAppReject}
                        onClick={handleRejectApp}
                    />
                </CenterModel>
            }
            {isModalOpenAppeal &&
                <CenterModel
                    modelTitle={'Appeal Application'}
                    show={"test"}
                    handleClose={openModalAppeal}
                    onUpload={openModalAppeal}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Appeal")}
                    />
                    <Buttons
                        buttonText="Appeal Application"
                        buttonTypeClassName="outline-btn mt-5"
                        isLoading={isLoadingAppeal}
                        onClick={handleAppeal}
                    />
                </CenterModel>
            }
        </>
    );
};

export default forwardRef(EditApplicationTaskList);
