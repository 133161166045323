import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CourseLocationFormData } from '../data/CourseLocationForm.Data';
import FormCreator from '../../../../../components/Forms/FormCreator';
import { FormFieldTypes } from '../../../../../data/formFieldType';
import Buttons from '../../../../../components/ui/button/Buttons';

const CourseLocation = forwardRef((props, ref) => {
    const courseLocationRef = useRef();
    const [courseLocationForm, setCourseLocationForm] = useState(CourseLocationFormData);
    const [formData, setFormData] = useState({ totalCount: "", itemList: [] });



    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (courseLocationRef.current) {
                return courseLocationRef.current.getFormData();
            }
            return null;
        }
    }));

    const updateSessionFields = (newSessionCount) => {
        const currentSessionCount = parseInt(courseLocationForm.initialState.noOfSession, 10) || 0;

        if (newSessionCount > currentSessionCount) {
            const newFields = Array.from({ length: newSessionCount - currentSessionCount }, (_, index) => {
                const sessionIndex = currentSessionCount + index + 1;
                return [
                    {
                        id: `sessionDate${sessionIndex}`,
                        lable: `Date ${sessionIndex}`,
                        fieldType: FormFieldTypes.DATEPICKER,
                        dataField: `sessionDate${sessionIndex}`,
                        validation: [{ type: "require" }],
                        style: { containerCss: "col-md-3 mb-2" },
                    },
                    {
                        id: `startAt${sessionIndex}`,
                        lable: `Start At ${sessionIndex}`,
                        fieldType: FormFieldTypes.DATEPICKER,
                        dataField: `startAt${sessionIndex}`,
                        validation: [{ type: "require" }],
                        style: { containerCss: "col-md-3 mb-2" },
                    },
                    {
                        id: `endsAt${sessionIndex}`,
                        lable: `Ends At ${sessionIndex}`,
                        fieldType: FormFieldTypes.DATEPICKER,
                        dataField: `endsAt${sessionIndex}`,
                        validation: [{ type: "require" }],
                        style: { containerCss: "col-md-3 mb-2" },
                    },
                    {
                        id: `component${sessionIndex}`,
                        fieldType: FormFieldTypes.COMPONENT,
                        additionalComponent: '',
                        style: { containerCss: "col-lg-3 mb-2" },
                    }
                ];
            }).flat();

            setCourseLocationForm((prevForm) => ({
                ...prevForm,
                formFields: [...prevForm.formFields, ...newFields],
                initialState: { ...prevForm.initialState, noOfSession: newSessionCount },
            }));
        } else if (newSessionCount < currentSessionCount) {
            const updatedFields = courseLocationForm.formFields.filter((field) => {
                const sessionNumber = parseInt(field.id?.match(/\d+$/)?.[0] || 0, 10);
                return sessionNumber <= newSessionCount;
            });

            setCourseLocationForm((prevForm) => ({
                ...prevForm,
                formFields: updatedFields,
                initialState: { ...prevForm.initialState, noOfSession: newSessionCount },
            }));
        }
    };

    const handleFormFieldChange = (fieldName, fieldValue) => {
        const updatedItemList = { ...formData.itemList, [fieldName]: fieldValue };
        setFormData({ ...formData, itemList: updatedItemList });

        if (fieldName === "noOfSession" && fieldValue >= 0) {
            updateSessionFields(fieldValue);
        }
    };


    return (
        <React.Fragment>
            <div className="row">
                <FormCreator
                    ref={courseLocationRef}
                    {...courseLocationForm}
                    onFormFieldValueChange={handleFormFieldChange}
                />
            </div>

            <pre>{JSON.stringify(formData, null, 2)}</pre>

            <div className="row mt-5">
                <div className="col-lg-12">
                    <Buttons
                        buttonText="Generate"
                        buttonTypeClassName="success-btn"
                    // onClick={handleGenerate}
                    />
                </div>
            </div>
        </React.Fragment>
    );
});

export default CourseLocation;
