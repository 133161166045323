
import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../../utils/API/responseMiddleware";
import { transformRequest } from "../../../../utils/API/requestMiddleware";
import { defautBaseQuery } from "../../../../utils/API/fetchBaseQuery";

const eventRegistrationApprovalApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'eventRegistrationApprovalApi',
    endpoints: (builder) => ({
        financeGetAllPendingEventApprovalListApi: builder.mutation({
            query: (details) => ({
                url: '/EventManagement/FinanceGetAllPendingEventApprovalList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useFinanceGetAllPendingEventApprovalListApiMutation,
} = eventRegistrationApprovalApi;

export default eventRegistrationApprovalApi;