import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";

export const accountManagementConfig = {
    columns: [
        {
            name: "Name",
            fieldName: "name",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Email Address",
            fieldName: "email",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }
        },
    ],
};

export const AccountListFormData = {
    name: "accountListFormData",
    initialState: {
        name: ''
    },
    formFields: [
        {
            id: "name",
            lable: "Name",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-3 ",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}


export const viewListDataForm = {
    name: "viewListDataForm",
    initialState: {
        name: "",
        email:""
    },
    formFields: [
        {
            id: "name",
            lable: "Name",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "email",
            lable: "Email",
            Field_Name: "Email",
            fieldType: FormFieldTypes.INPUT,
            dataField: "email",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}
