import { GridColumnType } from "../../../../../data/gridColumnType";


// eventQuestionAnswerTasklistTableConfig 
export const eventQuestionAnswerTasklistTableConfig = {
    columns: [
        {
            name: "Event Name",
            fieldName: "eventName",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Event Date",
            fieldName: "eventDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
              }
        },
        {
            name: "Type",
            fieldName: "eventTypeName",
            colType: GridColumnType.Text,
        },
        {
            name: "Booking",
            fieldName: "booking",
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {
                    name: "Download",
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],
        },
    ],
};