import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import { saveAs } from 'file-saver';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { speakingTeachingEngagementData } from './SpeakingTeachingEngagement.Data';
import { useLazyGetSpeakingTeachingEngagementByIdQuery } from '../../../../../app/services/CPD/CpdSubmissionApi';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { useAddEditSpeakingEngagementMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';
const SpeakingTeachingEngagement = forwardRef((props, ref) => {
  const speakingTeachingEngagementRef = useRef();
  const [speakingTeachingEngagementForm, setSpeakingTeachingEngagement] = useState(speakingTeachingEngagementData);
  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  const { success, error } = SwalAlert();
  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const qualificationData = speakingTeachingEngagementRef.current.getFormData();
        return qualificationData;
      }
      return null;
    }
  }));

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [
    getSpeakingTeachingEngagementById,
    {
      isSuccess: getSpeakingTeachingEngagementByIdIsSuccess,
      isLoading: getSpeakingTeachingEngagementByIdIsLoading,
      data: getSpeakingTeachingEngagementByIdData,
    }
  ] = useLazyGetSpeakingTeachingEngagementByIdQuery();


  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isFetching: isLoadingForm,
      data: formResponse }
  ] = useAddEditSpeakingEngagementMutation();


  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);


  useEffect(() => {
    if (getSpeakingTeachingEngagementByIdIsSuccess && !getSpeakingTeachingEngagementByIdIsLoading && getSpeakingTeachingEngagementByIdData) {
      console.log({ getSpeakingTeachingEngagementByIdData });
      let temp = { ...speakingTeachingEngagementForm }
      let tempInitials = temp.initialState;
      tempInitials.typeofCourseEvent = getSpeakingTeachingEngagementByIdData.typeofCourse;
      tempInitials.nameofEventOrganiser = getSpeakingTeachingEngagementByIdData.nameofEventOrganiser;
      tempInitials.dateofCourseEvent = getSpeakingTeachingEngagementByIdData.dateofCourse;
      tempInitials.letterEmailofAppointmentInvitation = getSpeakingTeachingEngagementByIdData.letterOrEmailofAppointment;
      tempInitials.awardRating = getSpeakingTeachingEngagementByIdData.cpdAwardRatingId;
      tempInitials.CpdPoints = getSpeakingTeachingEngagementByIdData.cpdPoints;
      tempInitials.remarks = getSpeakingTeachingEngagementByIdData.remarks;
      tempInitials.processedBy = getSpeakingTeachingEngagementByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getSpeakingTeachingEngagementByIdData.cpdPoints;
      setSpeakingTeachingEngagement(temp);
    }
  }, [getSpeakingTeachingEngagementByIdIsSuccess, getSpeakingTeachingEngagementByIdIsLoading, getSpeakingTeachingEngagementByIdData]);

  useEffect(() => {
    getSpeakingTeachingEngagementById(cpdSubmissionData.cpdSubmissionId);
  }, []);


  const handleDownload = async (data, dataField) => {
    const request = {
      type: "LetterEmailofAppointmentInvitation",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getSpeakingTeachingEngagementByIdData.letterOrEmailofAppointment,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }

  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };

  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "letterEmailofAppointmentInvitation" && value) {
      handleOnChangeFileUpload(value)
    }
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "LetterEmailofAppointmentInvitation");
    uploadPDF(formData);
  }

  const handleSaveButton = () => {
    const formData = speakingTeachingEngagementRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "")
    }
    let request = {
      SpeakingEngagementId: getSpeakingTeachingEngagementByIdData.cpdSpeakingEngagementId,
      GMAPMemberId: getSpeakingTeachingEngagementByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getSpeakingTeachingEngagementByIdData.cpdActivityTypeId,
      TypeofCourseEvent: formData?.typeofCourseEvent,
      NameOfEventOrganiser: formData?.nameofEventOrganiser,
      DateOfCourseEvent: formData?.dateofCourseEvent,
      LetterOfAppointment: fileUploadData?.fileName ? fileUploadData?.fileName : getSpeakingTeachingEngagementByIdData.letterOrEmailofAppointment,
      CPDAwardRatingId: formData?.awardRating?.value ? formData?.awardRating?.value : formData?.awardRating,
      CPDSubmissionId: getSpeakingTeachingEngagementByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }
    handleSubmitForm(request)
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getSpeakingTeachingEngagementByIdData.cpdSubmissionId,
      gmapMemberId: getSpeakingTeachingEngagementByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };

  const handleApproveButton = () => {
    const formData = speakingTeachingEngagementRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getSpeakingTeachingEngagementByIdData.cpdSubmissionId,
      gmapMemberId: getSpeakingTeachingEngagementByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };

  return (
    <React.Fragment>
      {getSpeakingTeachingEngagementByIdData ? <div className="speaking-teaching-engagement">
        {/* <div className="cpd-submission-description">
          <p>Speaking/Teaching engagements must relate to Green Building or sustainability of the built environment and can refer to speaking at seminars/webinars or lecturing for courses.</p>
          <p>
            1 CPD point per hour of delivery.
          </p>
          <p>CPD Points claimed under this category will be capped at 6 CPD points per CPD accumulation period.</p>
        </div> */}

        <div className="common-project-info-main card-section card-section-left">
          <div className="row gx-5  ">
            <FormCreator ref={speakingTeachingEngagementRef} {...speakingTeachingEngagementForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />
          </div>
        </div>
        <div className='row'>
          <div className='common-custom-pagination mt-5'>
            <div>
              <Buttons
                buttonText="Save"
                buttonTypeClassName="outline-btn"
                onClick={handleSaveButton}
                isDisable={props?.isPastCPD}

              />
            </div>
            <div className='d-flex column-gap-3'>
              <Buttons
                buttonText="Reject"
                buttonTypeClassName="red-btn"
                onClick={handleRejectbutton}
                isDisable={props?.isPastCPD}

              />
              <Buttons
                buttonText="Approve"
                buttonTypeClassName="success-btn"
                onClick={handleApproveButton}
                isDisable={props?.isPastCPD}
                isLoading={cpdSubmissionData?.approveIsLoading}
              />
            </div>
          </div>
        </div>
        <div className='center-button'>
          {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
            // onClick={handleSubmit}
            />
          </div> */}
        </div>

      </div>
        : <DataLoader />
      }
    </React.Fragment>
  );
}
)
export default SpeakingTeachingEngagement;
