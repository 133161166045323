import { useEffect, useImperativeHandle, useState } from "react";
import { useRef } from "react";
import { forwardRef } from "react";
import Buttons from "../../../components/ui/button/Buttons";
import FormCreator from "../../../components/Forms/FormCreator";
import { CreateAccountManagementData } from "./data/CreateAccountManagement.data";
import { useAddEditAdminDetailsMutation } from "../../../app/services/GMAP/gmaApplicationApi";
import SwalAlert from "../../../services/swalService/SwalService";
import { useLazyGetAllDropDownsQuery } from "../../../app/services/common/commonApi";
import { setAllDropDownField } from "./utils/dropdownUtils";

const CreateAccountManagement = forwardRef((props, ref) => {
    const createAccountRef = useRef();
    const [formData, setFromData] = useState(CreateAccountManagementData);
    const { success } = SwalAlert();
    const [createAccount, { isSuccess: isSuccessUpdate, isLoading: isFetchingAppUpdate, data: createAccountResponse },] = useAddEditAdminDetailsMutation();
    const [getAllDropDowns, { isSuccess: isGetAllDropDownFetched, isLoading: isGetDropDownFetching, data: allDropDownResponse },] = useLazyGetAllDropDownsQuery();

    useEffect(() => {
        if (!isFetchingAppUpdate && isSuccessUpdate && createAccountResponse) {
            success("", "Application Updated Successfully ")
        }
    }, [isFetchingAppUpdate, isSuccessUpdate, createAccountResponse]);

    useEffect(() => {
        getAllDropDowns();
    }, [])

    useEffect(() => {
        if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
            setAllDropDownField(allDropDownResponse);
        }
    }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const CreateAccountManagementData =
                    createAccountRef.current.getFormData();
                return CreateAccountManagementData;
            }
            return null;
        },
    }));

    const handleCreate = () => {
        let accountInformationData = createAccountRef.current.getFormData();
        if (!accountInformationData) {
            return false
        }

        let request = {
            AdminID: 0,
            Name: accountInformationData?.firstname || null,
            LastName: accountInformationData?.lastName || null,
            Email: accountInformationData?.email || null,
            Password: accountInformationData?.password || null,
            RoleId: accountInformationData?.roleId?.value || accountInformationData?.roleId,
        }
        createAccount(request)
    };


    return (
        <>
            <FormCreator
                ref={createAccountRef}
                {...formData}
            />
            <div className="col-lg-12 d-flex justify-content-end mt-5">
                <Buttons
                    buttonText="Create"
                    buttonTypeClassName="outline-btn"
                    isLoading={isFetchingAppUpdate}
                    onClick={handleCreate}
                />
            </div>
        </>
    );
});

export default CreateAccountManagement;