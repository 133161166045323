import { FormFieldTypes } from "../../../../../data/formFieldType";
export const PartnerFormData = {
    name: "PartnerFormData",
    initialState: {
        nameOfPartner: '',
        company: '',
        designation: '',
        eventImage: '',
        description: '',
    },
    formFields: [
        {
            id: "eventImage",
            lable: 'Upload Event Image',
            subLable: "(Dimension: 814px by 460px Maximum Size: 2MB)",
            Field_Name: 'Upload Event Image',
            fieldType: FormFieldTypes.FILE,
            dataField: 'eventImage',
            fieldSetting: {
                placeholder: '',
                acceptedFiles: '.jpg, .png',
                imageCrop: true,
                viewImage: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3",
            }
        },
        {
            id: "nameOfPartner",
            lable: "Name of Partner",
            Field_Name: "Name of Partner",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameOfPartner",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-md-12 mb-3",
            }
        },
        {
            id: "company",
            lable: "Website URL",
            Field_Name: "Website URL",
            fieldType: FormFieldTypes.INPUT,
            dataField: "company",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-md-12 mb-3",
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
};
