import { GridColumnType } from "../../../data/gridColumnType";



// Current Cpd Cycle Table GridConfig start
export const CurrentCpdCycleGridConfig = {
  columns: [
    {
      name: "CPD No.",
      fieldName: "cpdNo",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Submission Date",
      fieldName: "submittedDate",
      allowShort: false,
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Type of Activity",
      fieldName: "typeOfActivity",
      colType: GridColumnType.Text,
    },
    {
      name: "Title of Activity",
      fieldName: "titleOfActivity",
      colType: GridColumnType.Text,

    },
    {
      name: "Date of Activity",
      fieldName: "dateOfActivity",
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "CPD Points",
      fieldName: "cpdPoints",
      colType: GridColumnType.Text,
    },
    {
      name: "CPD Awarded",
      fieldName: "cpdPointsAwardedId",
      colType: GridColumnType.Text,
    },
    {
      name: "Status",
      fieldName: "cpdStatusName",
      colType: GridColumnType.Text,
    },
    {
      name: "Verification Date",
      fieldName: "verficationDate",
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    // {
    //   name: "",
    //   fieldName: "actions",
    //   colType: GridColumnType.LINK,
    //   colSettings: {
    //     isOpenNewTab: false,
    //     url: "$website",
    //   },
    // },

  ],
};
// Current Cpd Cycle Table GridConfig start
export const PastCpdCycleGridConfig = {
  columns: [
    {
      name: "CPD No.",
      fieldName: "cpdNo",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Submission Date",
      fieldName: "submittedDate",
      allowShort: false,
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Type of Activity",
      fieldName: "typeOfActivity",
      colType: GridColumnType.Text,
    },
    {
      name: "Title of Activity",
      fieldName: "titleOfActivity",
      colType: GridColumnType.Text,

    },
    {
      name: "Date of Activity",
      fieldName: "dateOfActivity",
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "CPD Points",
      fieldName: "cpdPoints",
      colType: GridColumnType.Text,
    },
    {
      name: "CPD Awarded",
      fieldName: "cpdPointsAwardedId",
      colType: GridColumnType.Text,
    },
    {
      name: "Status",
      fieldName: "cpdStatusName",
      colType: GridColumnType.Text,
    },
    {
      name: "Verification Date",
      fieldName: "verficationDate",
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
  ],
};