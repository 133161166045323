import { GridColumnType } from "../../../../data/gridColumnType";

// eventListTableConfig 
export const TransactionReportListTableConfig = {
    columns: [
        {
            name: "Invoice Number",
            fieldName: "invoiceNumber",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Payment Status ",
            fieldName: "paymentStatus",
            colType: GridColumnType.Text,
        },
        {
            name: "Payment Date",
            fieldName: "invoiceDate",
            allowShort: false,
            colType: GridColumnType.Text,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Total Invoice Amount",
            fieldName: "totalAmount",
            colType: GridColumnType.Text,
        },
      
        {
            name: "Full Name",
            fieldName: "fullName",
            colType: GridColumnType.Text,
        },
        {
            name: "Application Type",
            fieldName: "applicationType",
            colType: GridColumnType.Text,
        },
        {
            name: "Reference Number",
            fieldName: "referenceNo",
            colType: GridColumnType.Text,
        },
        
    ],
};
