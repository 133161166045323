import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../../components/ui/button/Buttons";
import GmapApplicationTasklistTable from "./component/GmapApplicationTasklistTable";
import GmapApplicationTasklistForm from "./component/GmapApplicationTasklistForm";
import { useDownloadSheetForGMAPApplicationTaskListMutation } from "../../../app/services/GMAP/gmapApplicationTaskListApi";
import { saveAs } from 'file-saver'
const initState = {
    pageNo: 1,
    pageSize: 20,
    endPage: 100,
    SearchText: "",
    gmapNumber: "",
    submissionStartDate: "",
    submissionEndDate: "",
    totalCount: 0,
    StatusId: null
}

const ApplicationTasklist = () => {
    const applicationTasklistRef = useRef(null)
    const [paginationRequest, setPaginationRequest] = useState(initState);

    const [downloadSheetForGMAPApplicationTaskList,
        { isSuccess: isDownloadSheetForGMAPApplicationTaskListSuccess,
            isLoading: isDownloadSheetForGMAPApplicationTaskListFetching,
            data: DownloadSheetForGMAPApplicationTaskListResponse },
    ] = useDownloadSheetForGMAPApplicationTaskListMutation();

    useEffect(() => {
        if (!isDownloadSheetForGMAPApplicationTaskListFetching && isDownloadSheetForGMAPApplicationTaskListSuccess && DownloadSheetForGMAPApplicationTaskListResponse) {
        }
    }, [isDownloadSheetForGMAPApplicationTaskListFetching, isDownloadSheetForGMAPApplicationTaskListSuccess, DownloadSheetForGMAPApplicationTaskListResponse]);

    const handleSearchApplicationTask = () => {


        if (!applicationTasklistRef.current) {
            return;
        }
        const taskListFormData = applicationTasklistRef.current.getFormData();

        if (Array.isArray(taskListFormData.status)) {
            taskListFormData.status = taskListFormData?.status.join(", ");
        }
        else {
            taskListFormData.status = taskListFormData?.status?.value ? taskListFormData?.status?.value : "";
        }
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            SearchText: taskListFormData.name,
            gmapNumber: taskListFormData.gmapNumber,
            StatusId: taskListFormData.status || null,
            submissionStartDate: taskListFormData.submissionDate,
            submissionEndDate: taskListFormData.submissionDateEnd,
        };
        setPaginationRequest(updatedPaginationRequest);


    };

    const handleClearClick = () => {
        setPaginationRequest(initState);
    }


    const handleDownload = async () => {
        try {
            const response = await downloadSheetForGMAPApplicationTaskList({
                pageNo: 1,
                pageSize: 20,
                endPage: 100,
                SearchText: "",
                gmapNumber: "",
                submissionStartDate: "",
                submissionEndDate: "",
                totalCount: 0,
                StatusId: paginationRequest.StatusId || null,
            });

            if (response && response.data) {
                const { fileData, fileName } = response.data;
                const blob = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, fileName || 'download.xlsx');
            }
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <GmapApplicationTasklistForm ref={applicationTasklistRef} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end align-items-center">
                    <div className="two-button-fixes">

                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearchApplicationTask}
                            />
                        </div>

                    </div>
                </div>

                <div className="flex flex-col space-y-6 mt-2">

                    {paginationRequest.StatusId !== null && (
                        <div className="">
                            <Buttons
                                buttonText="Download Excel"
                                buttonTypeClassName="success-btn"
                                onClick={handleDownload}
                            />
                        </div>
                    )}
                </div>


                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <GmapApplicationTasklistTable paginationReq={paginationRequest} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationTasklist;
