import { FormFieldTypes } from "../../../../../data/formFieldType"
import { GridColumnType } from "../../../../../data/gridColumnType"

export const greenMarkProjectConfig = {
    columns: [
        {
            name: "Project Name",
            fieldName: "projectName",
        },
        {
            name: "Green Mark Number",
            fieldName: "greenMarkNumber",
        },
        {
            name: "Date of Letter of Award",
            fieldName: "dateOfLetterOfAward",
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },

        {
            name: "Developer/ Building Owner",
            fieldName: "developerOrbuildingOwner",
        },
        {
            name: "Description",
            fieldName: "describeRoleInProject",
        },
        {
            name: "Letter Of Award",
            fieldName: "greenMarkProjectLetterOfAwardName",
            colType: GridColumnType.LINK,
            colSettings: {
                isOpenNewTab: true,
                url: "$projectLetterOfAwardLink", // need to add that property in our object  
            },
        },
        {
            name: "Client Letter Of Endorsement",
            fieldName: "clientLetterOfEndorsementName",
            colType: GridColumnType.LINK,
            colSettings: {
                isOpenNewTab: true,
                url: "$clientLetterOfEndorsementLink", // need to add that property in our object  
            },
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }

        },
    ]
}
export const greenMarkProjectData = {
    name: 'GreenMarkProject',
    initialState: {
        gmapPathwayTypeID: 0,
        gmaapPathwayTypeID: 0,
        projectName: '',
        greenMarkNumber: '',
        dateOfLetterOfAward: '',
        gmapAwardRatingId: 0,
        gmaapAwardRatingP1Id: 0,
        gmaapAwardRatingP2Id: 0,
        gmaapAwardRatingP3Id: 0,
        gmapGreenMarkCategoryP1Id: 0,
        gmapGreenMarkCategoryP2Id: 0,
        gmaapGreenMarkCategoryP1Id: 0,
        gmaapGreenMarkCategoryP2Id: 0,
        gmaapGreenMarkCategoryP3Id: 0,
        developerOrbuildingOwner: '',
        describeRoleInProject: '',
        greenMarkProjectLetterOfAward: '',
        clientLetterOfEndorsement: '',
        greenMarkProjectJobDesc: '',
        greenMarkProjectSignificantFile: ''
    },
    formFields: [
        {
            id: "gmapPathwayTypeID",
            lable: "GMAP Pathways",
            Field_Name: "GMAP Pathways",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapPathwayTypeID",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "gmaapPathwayTypeID",
            lable: "GMAAP Pathways",
            Field_Name: "GMAAP Pathways",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapPathwayTypeID",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "greenMarkProjectTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "greenMarkProjectTable",
            fieldSetting: {
                header: greenMarkProjectConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-2 mt-2 text-left",
            },
        },
        {
            id: "projectName",
            lable: "Project Name",
            Field_Name: "Project Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "projectName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "greenMarkNumber",
            lable: "Green Mark Project Number",
            Field_Name: "Green Mark Project Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "greenMarkNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "dateOfLetterOfAward",
            lable: "Date of Letter of Award",
            Field_Name: "Date of Letter of Award",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateOfLetterOfAward",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "gmapAwardRatingId",
            lable: "Award Rating",
            Field_Name: "Award Rating",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapAwardRatingId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "gmaapAwardRatingP1Id",
            lable: "Award Rating",
            Field_Name: "Award Rating",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapAwardRatingP1Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmaapAwardRatingP2Id",
            lable: "Award Rating",
            Field_Name: "Award Rating",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapAwardRatingP2Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmaapAwardRatingP3Id",
            lable: "Award Rating",
            Field_Name: "Award Rating",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapAwardRatingP3Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmapGreenMarkCategoryP1Id",
            lable: " Green Mark Category",
            Field_Name: " Green Mark Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapGreenMarkCategoryP1Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmapGreenMarkCategoryP2Id",
            lable: " Green Mark Category",
            Field_Name: " Green Mark Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapGreenMarkCategoryP2Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmaapGreenMarkCategoryP1Id",
            lable: " Green Mark Category",
            Field_Name: " Green Mark Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapGreenMarkCategoryP1Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "gmaapGreenMarkCategoryP2Id",
            lable: " Green Mark Category",
            Field_Name: " Green Mark Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapGreenMarkCategoryP2Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmaapGreenMarkCategoryP3Id",
            lable: " Green Mark Category",
            Field_Name: " Green Mark Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapGreenMarkCategoryP3Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "developerOrbuildingOwner",
            lable: "Developer/ Building Owner",
            Field_Name: "Developer/ Building Owner",
            fieldType: FormFieldTypes.INPUT,
            dataField: "developerOrbuildingOwner",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "describeRoleInProject",
            lable: "Describe your role in the project",
            Field_Name: "Describe your role in the project",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "describeRoleInProject",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-2",
            },
        },
        {
            id: "greenMarkProjectLetterOfAward",
            lable: 'Green Mark Project Letter of Award Attached (please upload in pdf format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'greenMarkProjectLetterOfAward',
            fieldSetting: {
                // presentFileName: null,
                placeholder: 'Select Letter of Award',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
        {
            id: "clientLetterOfEndorsement",
            lable: 'Client Letter of Endorsement Attached(please upload in pdf format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'clientLetterOfEndorsement',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
        {
            id: "greenMarkProjectJobDesc",
            lable: 'Proof of Job Description  (please upload in pdf format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'greenMarkProjectJobDesc',
            fieldSetting: {
                // presentFileName: null,
                placeholder: 'Select Proof of Job Description',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3 d-none"
            }
        },
        {
            id: "greenMarkProjectSignificantFile",
            lable: 'Proof of significant involvement in implementation of Sustainable Practices in existing building (please upload in pdf format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'greenMarkProjectSignificantFile',
            fieldSetting: {
                // presentFileName: null,
                placeholder: 'Select Proof of Significant Involvement',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3 d-none"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}

