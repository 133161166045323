/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useRef, useState, useContext } from "react";
import { certificateCourseData } from "./CertificateCourse.Data";
import CertificateCourseForm from "./component/CertficateCourseForm";
import { useAddEditGMAPCertificationCourseMutation, useDeleteGMAPCertificationCourseMutation, useLazyGetByIdIHLCertificateTypeQuery, useLazyGetGMAPCertificationCourseListQuery } from "../../../../../app/services/GMAP/CertificateCourseApi";
import { useLazyGetPDFQuery, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import SwalAlert from "../../../../../services/swalService/SwalService";
import ToastService from "../../../../../services/toastService/ToastService";
import Buttons from "../../../../../components/ui/button/Buttons";
import { useParams } from "react-router-dom";
import { EditApplicationDataContext } from "../../../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import { saveAs } from 'file-saver'
import { decryptAESID } from "../../../../../utils/Encryption";
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";
import { handleConditionBaseFields } from "./utils/ValidationBaseFields";

const CertificateCourse = forwardRef((props, ref) => {
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false) : 0
    const certificateCourseRef = useRef();
    const { newMemberData, setContextData } = useContext(EditApplicationDataContext);
    const [certificateCourseFormData, SetCertificateCourseFormData] = useState(certificateCourseData.initialState);
    const [isEdit, setIsEdit] = useState(false);
    const [certificateAttachment, setCertificateAttachment] = useState();
    const [CertificateList, setCertificateList] = useState();
    const { error, confirm } = SwalAlert();

    const [getPDF] = useLazyGetPDFQuery();

    const [addEditGMAPCertificate,
        { isSuccess: isAddGMAPCertificate,
            isLoading: isAddGMAPCertificateLoading,
            data: addEditCertificateData }
    ] = useAddEditGMAPCertificationCourseMutation();

    const [getGMAPCertificateList,
        { isSuccess: isGetGMAPCertificateFetched,
            isFetching: isGetGMAPCertificateFetching,
            data: CertificateListResponse },
    ] = useLazyGetGMAPCertificationCourseListQuery()

    const [deleteGMAPCertificateHistory,
        { isSuccess: isDeleteSuccess,
            data: deleteCertificateeHistory },
    ] = useDeleteGMAPCertificationCourseMutation();

    const [uploadPDF] = useUploadPDFMutation();
    const [getCertificateValidDate, { isSuccess: isSuccess, isLoading: isLoading, data: certificateValidDate },] = useLazyGetByIdIHLCertificateTypeQuery();

    useEffect(() => {
        getCertificateList()
    }, [])

    useEffect(() => {
        if (isAddGMAPCertificate && addEditCertificateData) {
            const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
            if (courseHistoryIndex !== -1) {
                const updatedFormData = { ...certificateCourseData };
                updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = null;
            }
            ToastService.success("Add Item successfully")
            const data = responceValid(addEditCertificateData);
            if (data) {
                getCertificateList();
            }
        }
    }, [isAddGMAPCertificate, addEditCertificateData]);

    useEffect(() => {
        if (isDeleteSuccess && deleteCertificateeHistory) {
            const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
            if (courseHistoryIndex !== -1) {
                const updatedFormData = { ...certificateCourseData };
                updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = null;
            }
            ToastService.error("Delete Item successfully");
            getCertificateList();
        }
    }, [isDeleteSuccess && deleteCertificateeHistory])

    useEffect(() => {
        if (!isGetGMAPCertificateFetching && isGetGMAPCertificateFetched && CertificateListResponse) {
            handleConditionBaseFields(CertificateListResponse?.at(-1)?.gmaPathwayTypeID, "gmaPathwayTypeID");
            let tempCertificateListResponse = CertificateListResponse
            tempCertificateListResponse = tempCertificateListResponse.map((obj) => {
                return { ...obj, certificateLink: `${APIUrl}/Common/getPDF?type=CertificateMarketRefresherCourse&fileName=${obj.certificateAttachment}` }
            })

            setCertificateList(tempCertificateListResponse);

            const tableIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
            if (tableIndex !== -1) {
                const updatedFormData = { ...certificateCourseData };
                updatedFormData.formFields[tableIndex].fieldSetting.dataSource = tempCertificateListResponse;
            }
            resetForm(CertificateListResponse?.at(-1)?.gmaPathwayTypeID);
        }
    }, [isGetGMAPCertificateFetching, isGetGMAPCertificateFetched, CertificateListResponse])

    const responceValid = (responce) => {
        if (responce !== null) {
            const data = responce;
            if (data.errorMessage === "") {
                return data;
            }
            else {
                alert("error")
            }
        }
    }
    const getCertificateList = () => {
        let request = editID;
        getGMAPCertificateList(request);
    }
    const handleAdd = () => {
        let certficateDetails = certificateCourseRef.current.getFormData();
        if (certficateDetails) {
            let pathway = certficateDetails.gmaPathwayTypeID?.value || certficateDetails.gmaPathwayTypeID;
            let certificateValue = null;
            if (pathway === 1) {
                certificateValue = certficateDetails.gmapCertificateTypeP1Id;
            } else if (pathway === 2) {
                certificateValue = certficateDetails.gmapCertificateTypeP2Id;
            }

            let gmaCertificateType = certificateValue?.value || certificateValue;
            let gmapCertificateType = certficateDetails?.gmapCertificateTypeId?.value || certficateDetails?.gmapCertificateTypeId;
            let gmaapCertificateType = certficateDetails?.gmaapCertificateTypeId?.value || certficateDetails?.gmaapCertificateTypeId;
            let ihlCertificateTypeId = certficateDetails?.IHLCertificateTypeId?.value || certficateDetails?.IHLCertificateTypeId;

            let certficateObj = {
                gmaPathwayTypeID: pathway || certficateDetails.gmaPathwayTypeID,
                gmapCertificateTypeId: gmapCertificateType,
                gmaCertificateTypeId: gmaCertificateType,
                gmaapCertificateTypeId: gmaapCertificateType,
                IHLCertificateTypeId: ihlCertificateTypeId,
                certificateName: certficateDetails.certificateName,
                certificateDate: certficateDetails.certificateDate,
                certificateNumber: certficateDetails.certificateNumber,
                certificateAttachment: certificateAttachment || newMemberData?.certificateMarketRefresherCourse || certficateDetails?.certificateAttachment, //encrypted
                certificateAttachmentName: newMemberData?.certificateMarketRefresherCourseName || certficateDetails?.certificateAttachmentName, //original
                base64Data: certficateDetails.certificateAttachment.base64Data,
                AppID: 114,
                gMAPMemberId: editID,
            }
            if (!certficateDetails.gmapCertificationCourseId && !isEdit) {
                addEditGMAPCertificate(certficateObj);
                resetForm(certficateDetails.gmaPathwayTypeID);

            } else if (certficateDetails.gmapCertificationCourseId && isEdit) {
                certficateObj.gmapCertificationCourseId = certficateDetails.gmapCertificationCourseId;
                addEditGMAPCertificate(certficateObj);
                resetForm(certficateDetails.gmaPathwayTypeID);
            }
        }
    }
    const handleTableEdit = (data) => {
        handleConditionBaseFields(data?.gmaPathwayTypeID, "gmaPathwayTypeID");

        setIsEdit(true);
        let EditFormData = {};

        EditFormData.gmapCertificateTypeP1Id = data.gmaCertificateTypeId;
        EditFormData.gmapCertificateTypeP2Id = data.gmaCertificateTypeId;
        EditFormData.IHLCertificateTypeId = data.ihlCertificateTypeId;
        let mergedData = {
            ...data, // Copy existing properties from data
            // Assign properties from EditFormData directly
            gmapCertificateTypeP1Id: EditFormData.gmapCertificateTypeP1Id,
            gmapCertificateTypeP2Id: EditFormData.gmapCertificateTypeP2Id,
            IHLCertificateTypeId: EditFormData?.IHLCertificateTypeId,
            certificateAttachment: data.certificateAttachmentName,
        };
        const updatedContext = {
            ...newMemberData,
            certificateMarketRefresherCourse: data.certificateAttachment,
            certificateMarketRefresherCourseName: data.certificateAttachmentName
        };
        setContextData(updatedContext)
        SetCertificateCourseFormData(mergedData);
    }
    const handleTableDelete = (data) => {
        let key = data.gmapCertificationCourseId;
        let request = {
            Id: key,
            AppId: 114,
            DeletedBy: editID,
        }
        confirm("Delete?", "Are you sure you want to delete?", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    deleteGMAPCertificateHistory(request);
                    resetForm();
                }
            });
    }
    //** Reset Data */
    const resetForm = (gmaPathwayTypeID) => {
        // let form = { ...certificateCourseFormData.initialState };
        // SetCertificateCourseFormData(form);
        SetCertificateCourseFormData(prevFormData => ({
            ...certificateCourseData.initialState,
            gmaPathwayTypeID: gmaPathwayTypeID || '',
        }));
        setIsEdit(false);
    };

    const handleOnChangeFileUpload = async (data) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "CertificateMarketRefresherCourse");
        try {
            const response = await uploadPDF(formData);
            setCertificateAttachment(response.data.fileName);
            const updatedContext = {
                ...newMemberData, certificateMarketRefresherCourse: response.data.fileName, //encrypted file name
                certificateMarketRefresherCourseName: response.data.uploadedFileName // origin file name
            };
            setContextData(updatedContext)
        } catch (e) {
            error(e, "Error uploading file:");
        }
    }

    const handleFormFieldChange = (dataField, value) => { // for file upload changes
        if (dataField === "certificateAttachment" && value) {
            handleOnChangeFileUpload(value)
        }
    };


    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            fileName: newMemberData?.certificateMarketRefresherCourse,
        }
        if (dataField === "certificateAttachment") {
            request.type = "CertificateMarketRefresherCourse"
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }
    useEffect(() => {
        if (!isLoading && isSuccess && certificateValidDate) {
            console.log(certificateValidDate, "certificateValidDate")
        }
    }, [!isLoading, isSuccess, certificateValidDate]);
    const handleGetCertificateDate = (data) => {
        getCertificateValidDate(data.value)
    }


    return (
        <React.Fragment>
            <div className="col-lg-12">

                {/* <ul className="text-danger">
                    {newMemberData.errorCertificateType?.length > 0 && <p className="text-danger">At least one of the following is compulsory:</p>}
                    {newMemberData.errorCertificateType?.map((item) => <li>{item}</li>)}
                </ul> */}
            </div>
            <CertificateCourseForm
                onDownload={handleDownload}
                ref={certificateCourseRef}
                initData={certificateCourseFormData}
                isEdit={isEdit}
                onReset={resetForm}
                onFormFieldValueChange={handleFormFieldChange}
                handleTableEdit={handleTableEdit} handleTableDelete={handleTableDelete}
                handleGetCertificateDate={handleGetCertificateDate}
            />
            <div className="col-md-12 mb-2 mt-2 d-flex justify-content-end">
                <Buttons
                    buttonText={isEdit ? "Update" : "+ Add"}
                    buttonTypeClassName="outline-btn"
                    onClick={handleAdd}
                />
            </div>
        </React.Fragment>
    )
})

export default CertificateCourse



// const link = `${APIURL}/Common/getPDF?type=${type}&fileName=${fileName}`;