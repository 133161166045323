import { FormFieldTypes } from "../../../../../../data/formFieldType";

export const DropdowmComponentData = {
    name: "DropdowmComponentData",
    initialState: {
        required: false,
        dropdowmTextField: '',
        option1: '',
        option2: ''
    },
    formFields: [
        {
            id: "required",
            lable: "Required",
            Field_Name: "Required",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "required",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-12 mt-2",
            },
        },
        {
            id: "dropdowmTextField",
            lable: "Drop-down",
            Field_Name: "Drop-down",
            fieldType: FormFieldTypes.INPUT,
            dataField: "dropdowmTextField",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-lg-12",
            },
        },
        {
            id: "option1",
            lable: "1",
            Field_Name: "Option 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "option1",
            fieldSetting: {
                placeholder: "Answer 1",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-lg-6",
            },
        },
        {
            id: "option2",
            lable: "2",
            Field_Name: "Option 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "option2",
            fieldSetting: {
                placeholder: "Answer 2",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-lg-6",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}