import React, { useEffect, useRef, useState } from 'react'
import { eventListTableConfig } from './data/EventListTable.Data';
import MolGrid from '../../../../components/Grid/MolGrid';
import { useGetEntryDetailListApiMutation } from '../../../../app/services/EventManagement/ViewEditEvent/ViewEditEventApi';

const EventListTable = ({ paginationReq }) => {
    const molGridRef = useRef();
    const [dataSource, setDataSource] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        SearchText: "",
        StatusId: 0,
        HiddenSearch: "",
        totalCount: 0
    });
    const [pendingEventApprovalList, { isSuccess: isViewEditListSuccess, isLoading: isViewEditTaskListFetching, data: viewEditListResponse },] = useGetEntryDetailListApiMutation();

    useEffect(() => {
        pendingEventApprovalList(paginationReq)
    }, [])

    useEffect(() => {
        setDataSource([]);
        pendingEventApprovalList(paginationReq)
    }, [paginationReq])

    useEffect(() => {
        if (!isViewEditTaskListFetching && isViewEditListSuccess && viewEditListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: viewEditListResponse.totalCount })
            setDataSource(viewEditListResponse.itemList)
        }
    }, [isViewEditTaskListFetching, isViewEditListSuccess, viewEditListResponse]);

    const handlePageChange = ({ pageNumber, pageSize }) => {
        pendingEventApprovalList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }


    return (
        <>
            <div>
                <h4>Total Count :{paginationRequest.totalCount} </h4>
            </div>
            <MolGrid
                ref={molGridRef}
                configuration={eventListTableConfig}
                dataSource={dataSource}
                allowPagination={true}
                isLoading={isViewEditTaskListFetching}
                pagination={paginationRequest}
                onPageChange={handlePageChange}
            />
        </>
    )
}

export default EventListTable