import { GridColumnType } from "../../../../../data/gridColumnType";


// eventRegistrationApprovalTasklistTableConfig 
export const eventRegistrationApprovalTasklistTableConfig = {
    columns: [
        {
            name: "Registration Date",
            fieldName: "registrationDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Event Name",
            fieldName: "eventName",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Event Date",
            fieldName: "timeSlotDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Contact Person",
            fieldName: "contactPerson",
            colType: GridColumnType.Text,
        },
        {
            name: "Status",
            fieldName: "bookingStatus",
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {
                    name: "View",
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],
        },
    ],
};