import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid';
import { eventRegistrationApprovalTasklistTableConfig } from './data/EventRegistrationApprovalListTable.Data';
import { useFinanceGetAllPendingEventApprovalListApiMutation } from '../../../../app/services/EventManagement/EventRegistrationApproval/eventRegistrationApprovalApi';

const EventApprovalListTable = ({ paginationReq }) => {
    const molGridRef = useRef();
    const [dataSource, setDataSource] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        SearchText: "",
        totalCount: 0
    });
    const [pendingEventApprovalList, { isSuccess: isEventApprovalListSuccess, isLoading: isEventApprovalTaskListFetching, data: pendingEventApprovalListResponse },] = useFinanceGetAllPendingEventApprovalListApiMutation();

    useEffect(() => {
        pendingEventApprovalList(paginationReq)
    }, [])

    useEffect(() => {
        setDataSource([]);
        pendingEventApprovalList(paginationReq)
    }, [paginationReq])

    useEffect(() => {
        if (!isEventApprovalTaskListFetching && isEventApprovalListSuccess && pendingEventApprovalListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: pendingEventApprovalListResponse.totalCount })
            setDataSource(pendingEventApprovalListResponse.itemList)
        }
    }, [isEventApprovalTaskListFetching, isEventApprovalListSuccess, pendingEventApprovalListResponse]);

    const handlePageChange = ({ pageNumber, pageSize }) => {
        pendingEventApprovalList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }


    return (
        <>
            <div>
                <h4>Total Count :{paginationRequest.totalCount} </h4>
            </div>
            <MolGrid
                ref={molGridRef}
                configuration={eventRegistrationApprovalTasklistTableConfig}
                dataSource={dataSource}
                allowPagination={true}
                isLoading={isEventApprovalTaskListFetching}
                pagination={paginationRequest}
                onPageChange={handlePageChange}
            />
        </>
    )
}

export default EventApprovalListTable