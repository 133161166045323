import { FormFieldTypes } from "../../../../../data/formFieldType";
export const SectionFormData = {
    name: "SectionFormData",
    initialState: {
        nameOfSection: '',
        company: '',
        designation: '',
        eventImage: '',
        description: '',
    },
    formFields: [
        {
            id: "nameOfSection",
            lable: "Name of Section",
            Field_Name: "Name of Section",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameOfSection",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        }
    ],
    formSetting: {
        isViewOnly: false
    }
};
