import { FormFieldTypes } from "../../../../../data/formFieldType";

export const personalParticularsData = {
    name: 'PersonalParticulars',
    initialState: {
        salutationsID: null,
        fullName: '',
        primaryEmail: '',
        secondaryEmail: '',
        dateOfBirthDate: '',
        isGenderMale: "male",
        mobilePhone: '',
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        postalCode: "",
        comments: '',
        nationalityID: '',
        countryOfResID: '',
        profilePhotoFile: "",
        linkedinAdd: '',
        competenciesID: null,
        otherProfessionQualificationID: null,
    },
    formFields: [
        {
            id: "salutationsID",
            lable: "Full Name as in NRIC/Passport",
            Field_Name: ["salutationsID", "& Full Name"],
            fieldType: FormFieldTypes.INPUTGROUPSELECT,
            dataField: ["salutationsID", "fullName"],
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 col-12 col-sm-12 col-md-12 mb-3",
            },
        },
        {
            id: "primaryEmail",
            lable: "Primary Email (Personal Email recommended)",
            Field_Name: "Primary Email (Personal Email recommended)",
            fieldType: FormFieldTypes.INPUT,
            dataField: "primaryEmail",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
                isDisable: true,
            },
            validation: [{ type: "require" }, { type: "email" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "secondaryEmail",
            lable: "Secondary Email (Personal email)",
            Field_Name: "Secondary Email (Personal email)",
            fieldType: FormFieldTypes.INPUT,
            dataField: "secondaryEmail",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
            },
            validation: [{ type: "secondaryEmail" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "dateOfBirthDate",
            lable: "Date of Birth",
            Field_Name: "Date of Birth",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateOfBirthDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "mobilePhone",
            lable: "Mobile Phone",
            Field_Name: "Mobile Phone",
            fieldType: FormFieldTypes.INPUT,
            dataField: "mobilePhone",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2",
            },
        },
        {
            id: "isGenderMale",
            label: "Gender",
            Field_Name: "Gender",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isGenderMale",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Male", value: "male", isDisable: false, optionClass: "", },
                    { label: "Female", value: "female", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2",
            },
        },
        {
            id: "nationalityID",
            lable: "Nationality",
            Field_Name: "Nationality",
            fieldType: FormFieldTypes.SELECT,
            dataField: "nationalityID",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "addressLine1",
            lable: "Address Line 1",
            Field_Name: "Address Line 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "addressLine1",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "addressLine2",
            lable: "Address Line 2",
            Field_Name: "Address Line 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "addressLine2",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "addressLine3",
            lable: "Address Line 3",
            Field_Name: "Address Line 3",
            fieldType: FormFieldTypes.INPUT,
            dataField: "addressLine3",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "postalCode",
            lable: "Postal Code",
            Field_Name: "Postal Code",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "postalCode",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "countryOfResID",
            lable: "Country of Residence",
            Field_Name: "Country of Residence",
            fieldType: FormFieldTypes.SELECT,
            dataField: "countryOfResID",
            fieldSetting: {
                placeholder: " ",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "profilePhotoFile",
            lable: 'Professional Profile Photo (please upload in jpg/png format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'profilePhotoFile',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                // isDownloadable: false,
                acceptedFiles: '.jpg, .png',
                imageCrop: true,
                viewImage: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "comments",
            lable: "Professional Profile Description",
            Field_Name: "Professional Profile Description",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "comments",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2",
            },
        },
        {
            id: "linkedinAdd",
            lable: "LinkedIn Address / Professional Homepage",
            Field_Name: "LinkedIn Address / Professional Homepage",
            fieldType: FormFieldTypes.INPUT,
            dataField: "linkedinAdd",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "isUrl" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "competenciesID",
            lable: "Competencies",
            Field_Name: "Competencies",
            fieldType: FormFieldTypes.SELECT,
            dataField: "competenciesID",
            fieldSetting: {
                placeholder: "",
                options: [],
                isMultiSelect: true,
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "otherProfessionQualificationID",
            lable: "Other Professional Qualifications",
            Field_Name: "Other Professional Qualifications",
            fieldType: FormFieldTypes.SELECT,
            dataField: "otherProfessionQualificationID",
            fieldSetting: {
                placeholder: "",
                options: [],
                isMultiSelect: true,
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}