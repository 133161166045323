import React, { forwardRef, useContext, useImperativeHandle, useRef, useState, useMemo } from 'react';
import FormCreator from '../../../../../../components/Forms/FormCreator';
import BasicDetailContext from '../../../../utils/BasicDetailContext';
import AddQuestionButton from '../AddQuestionButton/AddQuestionButton';
import TextBoxComponent from '../TextBox/TextBoxComponent';
import DropdownComponent from '../Dropdown/DropdownComponent';
import { AppIcons } from '../../../../../../data/appIcons';
import Buttons from '../../../../../../components/ui/button/Buttons';
import { FeedbackQuestionsFormData } from './FeedbackQuestionsForm.Data';

const FeedbackQuestions = forwardRef((props, ref) => {
    const feedbackQuestionsRef = useRef();

    // Initialize form data with state
    const [feedbackQuestionsForm, setFeedbackQuestionsForm] = useState(FeedbackQuestionsFormData);

    // Destructure context values for handling form details and question types
    const { feedbackQuestionTypeList, setFeedQuestionTypeList } = useContext(BasicDetailContext);
    // Memoize component map to avoid unnecessary re-creation on each render
    const componentMap = useMemo(() => ({
        radio: <h1>Radio</h1>,
        textbox: <TextBoxComponent />,
        dropdown: <DropdownComponent />,
        multiselectdropdown: <DropdownComponent />,
        checkbox: <h1>Checkbox</h1>
    }), []);

    // Handler to delete a question at a specified index
    const handleDeleteQuestion = (index) => {
        setFeedQuestionTypeList(prevList => prevList.filter((_, i) => i !== index));
    };

    // General handler to move items up or down in the list based on the direction (-1 for up, +1 for down)
    const moveItem = (index, direction) => {
        const newIndex = index + direction;
        // Check boundaries to ensure items don't move out of bounds
        if (newIndex < 0 || newIndex >= feedbackQuestionTypeList.length) return;

        // Create a new array with swapped items for movement
        const updatedList = [...feedbackQuestionTypeList];
        [updatedList[index], updatedList[newIndex]] = [updatedList[newIndex], updatedList[index]];

        // Update question type list with new arrangement
        setFeedQuestionTypeList(updatedList);
    };

    // Component for question control buttons (Up, Down, Delete)
    const QuestionControls = ({ index }) => (
        <>
            <Buttons
                buttonText="Up"
                imagePath={AppIcons.checkNot}
                buttonTypeClassName="black-btn mb-3"
                onClick={() => moveItem(index, -1)} // Move item up
            />
            <Buttons
                buttonText="Down"
                imagePath={AppIcons.arrowIcon}
                buttonTypeClassName="black-btn mb-3"
                onClick={() => moveItem(index, 1)} // Move item down
            />
            <Buttons
                buttonText="Delete"
                imagePath={AppIcons.checkNot}
                buttonTypeClassName="red-btn mb-3"
                onClick={() => handleDeleteQuestion(index)} // Delete item
            />
        </>
    );

    return (
        <>
            {/* Conditionally render questions section if additional information is required */}
            {feedbackQuestionTypeList && (
                <div className="row mt-5">
                    {/* Display the list of question types and associated controls */}
                    <div className="col-lg-9 mt-5">
                        <div className="  mb-3 container-fluid">
                            {feedbackQuestionTypeList?.map((type, index) => (
                                // Render only if type exists in componentMap
                                componentMap[type] && (
                                    <div className="row mb-4" key={index}>
                                        {/* Column for the question component itself */}
                                        <div className="col-lg-11 py-3 card">
                                            {componentMap[type]} {/* Render the component based on type */}
                                        </div>
                                        {/* Column for Up, Down, and Delete buttons */}
                                        <div className="col-lg-1">
                                            <QuestionControls index={index} /> {/* Render control buttons */}
                                        </div>
                                    </div>

                                )
                            ))}
                        </div>
                    </div>

                    {/* Button to add more questions */}
                    <div className="col-lg-3 mt-5">
                        <AddQuestionButton mode="Feedback" />
                    </div>
                </div>
            )}
        </>
    );
});

export default FeedbackQuestions;
