import { FormFieldTypes } from "../../../../../data/formFieldType";

export const AdditionalDescriptionFormData = {
    name: "AdditionalDescription",
    initialState: {
        eventImage: '',
        description: '',
    },
    formFields: [
        {
            id: "description",
            lable: "Description",
            Field_Name: "Description",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "description",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6  mb-2",
            },
        },
        {
            id: "eventImage",
            lable: 'Upload Speaker Image',
            subLable: "(Dimension: 814px by 460px Maximum Size: 2MB)",
            Field_Name: 'Upload Description Image',
            fieldType: FormFieldTypes.FILE,
            dataField: 'eventImage',
            fieldSetting: {
                placeholder: '',
                acceptedFiles: '.jpg, .png',
                imageCrop: true,
                viewImage: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}