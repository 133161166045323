/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useRef, useImperativeHandle, useState, useContext, useEffect } from "react";
import FormCreator from "../../../../../components/Forms/FormCreator";
import { academicQualificationData } from "./AcademicQualification.Data";
import { EditApplicationDataContext } from "../../../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import { useLazyGetPDFQuery, useStoreBase64ImageMutation, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import { saveAs } from "file-saver";
import ToastService from "../../../../../services/toastService/ToastService";
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";

const AcademicQualification = forwardRef((props, ref) => {


    const academicQualificationRef = useRef();
    const [academicQualificationForm, SetAcademicQualificationForm] = useState(academicQualificationData);
    const { newMemberData, setContextData } = useContext(EditApplicationDataContext)

    const [uploadPDF, { isSuccess: isUploadSuccess, isLoading: isUploadLoading, data: fileUploadData }] = useUploadPDFMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();
    // Upload image api call
    const [saveMainImage, { isSuccess: isSuccessImage, isLoading: isLoadingImage, data: profileImageUpload }] = useStoreBase64ImageMutation();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const academicData = academicQualificationRef.current.getFormData();
                return academicData;
            }
            return null;
        }
    }))

    useEffect(() => {
        if (!isLoadingImage && isSuccessImage && profileImageUpload) {
            setContextData({ ...newMemberData, academicCertificate: profileImageUpload?.fileName, academicQualificationCertificationFile: profileImageUpload?.fileName, academicQualificationCertificationFileName: profileImageUpload.uploadedFileName });
            ToastService.success("Image uploaded successfully !")
        }
    }, [isLoadingImage, isSuccessImage, profileImageUpload,]);


    const handleClear = () => {
        const updatedForm = { ...academicQualificationForm };
        updatedForm.initialState.academicQualificationCertificationFile = null;
        SetAcademicQualificationForm(updatedForm);
    }

    // const handleDownload = async (data, dataField) => {
    //     debugger;
    //     const downloadType = data?.contractInputFile?.split('.')[1] === "pdf" ? "AcademincQualification" : "AcademincQualificationImage";
    //     const request = {
    //         type: downloadType,
    //         fileName: newMemberData?.academicQualificationCertificationFile,
    //     }
    //     // if (dataField === "academicQualificationCertificationFile") {
    //     //     request.type = "AcademincQualification"
    //     // }

    //     const response = await getPDF(request);
    //     saveAs(response?.data?.fileData, response?.data?.fileName);
    // }
    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            // fileName: newMemberData?.academicCertificate,
            fileName: newMemberData?.academicCertificate || data?.contractInputFile,
        }
        if (dataField === "academicQualificationCertificationFile") {
            request.type = "AcademincQualification"
        }

        const response = await getPDF(request);
        const fileUrl = `${APIUrl}/Common/getPDF?type=AcademincQualification&fileName=${response?.data?.fileName}`;
        window.open(fileUrl, "_blank");
        //saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
    }

    const handleOnChangeFileUpload = async (data) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "AcademincQualification");
        try {
            const response = await uploadPDF(formData);
            // const newData = { ...newMemberData }
            // newData.academicQualificationCertificationFile = response.data.fileName;
            setContextData({ ...newMemberData, academicCertificate: response.data.fileName, academicQualificationCertificationFile: response.data.fileName, academicQualificationCertificationFileName: response.data.uploadedFileName });
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }
    // for file upload changes
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "academicQualificationCertificationFile" && value) {
            if (value.type === "application/pdf") {
                handleOnChangeFileUpload(value);
            } else {
                handleOnChangeImageUpload(value);
            }
        }
    };

    // Upload individual signup images method
    const handleOnChangeImageUpload = (data) => {
        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "AcademincQualificationImage",
        }
        saveMainImage(saveFileRequest)
    }

    return (<FormCreator ref={academicQualificationRef}
        {...academicQualificationForm}
        onActionChange={formActionHandler}
        onFormFieldValueChange={handleFormFieldChange}
    />)
})

export default AcademicQualification