import React from "react"
import EventFeedbackQuestionList from "../../features/eventManagement/EventFeedbackQuestionList";

const EventFeedbackQuestion = (props) => {
    return (
        <div>
            <EventFeedbackQuestionList />
        </div>
    )
};

export default EventFeedbackQuestion;
