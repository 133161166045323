import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import CardSection from '../../../../../components/ui/card/CardSection';
import { SpeakerFormData } from './SpeakerForm.Data';

const Speaker = forwardRef((props, ref) => {
    const [speakerForms, setSpeakerForms] = useState([SpeakerFormData]);
    const speakerFormRefs = useRef([]); // Array to hold refs for each FormCreator

    // Add a new FormCreator section
    const handleAddSpeakerSection = () => {
        setSpeakerForms(prevForms => [...prevForms, SpeakerFormData]);
    };

    // Delete a specific FormCreator section
    const handleDeleteSpeakerSection = (index) => {
        setSpeakerForms(prevForms => prevForms.filter((_, i) => i !== index));
        speakerFormRefs.current = speakerFormRefs.current.filter((_, i) => i !== index); // Remove ref for deleted FormCreator
    };

    // Expose a method to get data from all FormCreators
    const handleSubmit = () => {
        const allFormData = speakerFormRefs.current.map(speakerFormRef => speakerFormRef?.current?.getFormData());
        console.log('All Form Data:', allFormData);
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return speakerFormRefs.current.map(speakerFormRef => speakerFormRef?.current?.getFormData());
        }
    }));

    return (
        <React.Fragment>
            <CardSection cardTitle="Speaker">
                {speakerForms.map((formData, index) => {
                    // Initialize the ref for each FormCreator if it doesn't already exist
                    if (!speakerFormRefs.current[index]) {
                        speakerFormRefs.current[index] = React.createRef();
                    }
                    const speakerFormRef = speakerFormRefs.current[index];

                    return (
                        <div className='row' key={index}>
                            <FormCreator
                                ref={speakerFormRef}
                                {...formData}
                            />
                            <Buttons
                                buttonText="Delete"
                                buttonTypeClassName="danger-btn"
                                onClick={() => handleDeleteSpeakerSection(index)}
                            />
                        </div>
                    );
                })}
                <div className='row'>
                    <div className='col-lg-12'>
                        <Buttons
                            buttonText="Add Speaker"
                            buttonTypeClassName="success-btn mb-3"
                            onClick={handleAddSpeakerSection}
                        />
                        <Buttons
                            buttonText="Submit"
                            buttonTypeClassName="primary-btn mb-3"
                            onClick={handleSubmit} // Calls handleSubmit to print all form data
                        />
                    </div>
                </div>
            </CardSection>
        </React.Fragment>
    );
});

export default Speaker;
