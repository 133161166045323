import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { GuestFormData } from './GuestForm.Data';
import CardSection from '../../../../../components/ui/card/CardSection';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';

const Guest = forwardRef((props, ref) => {
    const [guestForms, setGuestForms] = useState([GuestFormData]);
    const guestFormRefs = useRef([]); // Array to hold refs for each FormCreator

    // Add a new FormCreator section
    const handleAddGuestSection = () => {
        setGuestForms(prevForms => [...prevForms, GuestFormData]);
    };

    // Delete a specific FormCreator section
    const handleDeleteGuestSection = (index) => {
        setGuestForms(prevForms => prevForms.filter((_, i) => i !== index));
        guestFormRefs.current = guestFormRefs.current.filter((_, i) => i !== index); // Remove ref for deleted FormCreator
    };

    // Expose a method to get data from all FormCreators
    const handleSubmit = () => {
        const allFormData = guestFormRefs.current.map(guestFormref => guestFormref?.current?.getFormData());
        console.log('All Form Data:', allFormData);
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return guestFormRefs.current.map(guestFormref => guestFormref?.current?.getFormData());
        }
    }));

    return (
        <React.Fragment>
            <CardSection cardTitle="Guest Of Honour">
                {guestForms.map((formData, index) => {
                    // Initialize the ref for each FormCreator if it doesn't already exist
                    if (!guestFormRefs.current[index]) {
                        guestFormRefs.current[index] = React.createRef();
                    }
                    const guestFormRef = guestFormRefs.current[index];

                    return (
                        <div className='row' key={index}>
                            <FormCreator
                                ref={guestFormRef}
                                {...formData}
                            />
                            <Buttons
                                buttonText="Delete"
                                buttonTypeClassName="danger-btn"
                                onClick={() => handleDeleteGuestSection(index)}
                            />
                        </div>
                    );
                })}
                <div className='row'>
                    <div className='col-lg-12'>
                        <Buttons
                            buttonText="Add Guest"
                            buttonTypeClassName="success-btn mb-3"
                            onClick={handleAddGuestSection}
                        />
                        <Buttons
                            buttonText="Submit"
                            buttonTypeClassName="primary-btn mb-3"
                            onClick={handleSubmit} // Calls handleSubmit to print all form data
                        />
                    </div>
                </div>
            </CardSection>
        </React.Fragment>
    );
});

export default Guest;
