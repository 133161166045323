import React, { Suspense, useEffect, useRef, useState } from "react"
import { updatePasswordData } from "./Data/ResetPasswordForm.Data";
import PageLoader from "../../../../../components/ui/pageLoader/PageLoader";
import SwalAlert from "../../../../../services/swalService/SwalService";
import { useResetPasswordForWebMemberMutation } from "../../../../../app/services/GMAP/login";
import FormCreator from "../../../../../components/Forms/FormCreator";
import Buttons from "../../../../../components/ui/button/Buttons";
import { decryptAESID } from "../../../../../utils/Encryption";
import { useNavigate, useParams } from "react-router-dom";

const UpdatePasswordForm = (props) => {
    const updateFormRef = useRef();
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false) : 0
    const navigate = useNavigate();
    const [formData, setFormData] = useState(updatePasswordData);
    const { success, error } = SwalAlert();
    const [updatePassword, { isLoading: isLoading, isSuccess: isSuccess, data: updatePasswordRes }] = useResetPasswordForWebMemberMutation();

    useEffect(() => {
        if (!isLoading && isSuccess && updatePasswordRes) {
            // navigate("/")
        }
    }, [isLoading, isSuccess, updatePasswordRes]);

    const handleUpdatePassword = () => {
        const formData = updateFormRef.current?.getFormData();
        if (!formData) return;
        if (formData?.password === formData?.confirmPassword) {
            const request = {
                GmapMemberId: editID || null,
                Password: formData.password || null,
            };
            updatePassword(request);
            success("Your Password has been successfully Updated");

        } else {
            error("The passwords do not match. Please try again.", "");
        }
    }

    return (
        <div className='container resetPasswordForm'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-6 resetPasswordCard py-4 my-5'>
                    <h1 className='text-center'>Update Password</h1>
                    <h6 className='text-center'>Enter new password to retrieve your account</h6>
                    <hr />
                    <div className='p-4'>
                        <p class="lead">Password must be at least 6 alphanumeric characters and contain at least one uppercase, one special character such as @#$%</p>
                        <Suspense fallback={<PageLoader />}>
                            <FormCreator
                                ref={updateFormRef}
                                {...formData}
                            />
                            <div className='col-lg-12 mt-3'>
                                <Buttons
                                    buttonTypeClassName="btn success-btn"
                                    buttonText="Reset Your Password"
                                    onClick={handleUpdatePassword}
                                    isLoading={isLoading}
                                />
                            </div>
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UpdatePasswordForm;
