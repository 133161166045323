import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FormCreator from '../../../../../../components/Forms/FormCreator';
import { DropdowmComponentData } from './DropdownComponent.Data';
import Buttons from '../../../../../../components/ui/button/Buttons';
import { FormFieldTypes } from '../../../../../../data/formFieldType';
import SwalAlert from '../../../../../../services/swalService/SwalService';

// DropdownComponent is a form component built with React and forwardRef
// It uses FormCreator to render form fields, and it allows dynamically adding/removing option fields
const DropdownComponent = forwardRef((props, ref) => {
    // Refs
    const dropdownRef = useRef();
    //alert function
    const { error } = SwalAlert();
    // State to manage the dropdown form structure and data
    const [dropdownForm, setDropdownForm] = useState(DropdowmComponentData);

    // useEffect to log dropdownForm changes (helpful for debugging and ensuring state updates)
    useEffect(() => {
        console.log(dropdownForm.formFields, "dropdownForm");
    }, [dropdownForm]);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const dropdownFormData = dropdownRef.current.getFormData();
                return dropdownFormData;
            }
            return null;
        }
    }));

    // Handles form field value changes, specifically for required field validation
    const handleFormFieldChange = (fieldName, fieldValue) => {
        // If "required" field is changed, update validation of "dropdowmTextField" field
        if (fieldName === "required") {
            const { formFields } = DropdowmComponentData;
            const dropdowmTextIndex = formFields.findIndex(field => field.id === "dropdowmTextField");

            if (dropdowmTextIndex > -1) {
                const updatedFormData = {
                    ...DropdowmComponentData,
                    formFields: formFields.map((field, index) =>
                        index === dropdowmTextIndex
                            ? { ...field, validation: fieldValue ? [{ type: "require" }] : [] }
                            : field
                    )
                };
                setDropdownForm(updatedFormData);
            }
        }
    };

    // Function to add a new option field to the dropdown form dynamically
    const handlePushOptionObject = () => {
        // Determine the current count of option fields to set new option ID and label
        const optionCount = dropdownForm.formFields.filter(field => field.id.startsWith("option")).length;
        const newOption = {
            id: `option${optionCount + 1}`,
            lable: `${optionCount + 1}`,
            Field_Name: `Option ${optionCount + 1}`,
            fieldType: FormFieldTypes.INPUT,
            dataField: `option${optionCount + 1}`,
            fieldSetting: {
                placeholder: `Answer ${optionCount + 1}`,
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-lg-6",
            },
        };

        // Update the dropdownForm state by adding the new option field
        setDropdownForm(prevForm => ({
            ...prevForm,
            formFields: [...prevForm.formFields, newOption],
        }));
    };

    // Function to remove the last added option field from the dropdown form
    const handlePopOutOptionObject = () => {
        // Get all current option fields
        const optionFields = dropdownForm.formFields.filter(field => field.id.startsWith("option"));

        // Check if there are more than three options before removing
        if (optionFields.length > 2) {
            // Remove the last option field
            const updatedFormFields = dropdownForm.formFields.filter(field =>
                !field.id.startsWith("option") || field.id !== `option${optionFields.length}`
            );
            setDropdownForm(prevForm => ({
                ...prevForm,
                formFields: updatedFormFields,
            }));
        } else {
            // Notify user or handle case when there are 3 or fewer options left
            error("Cannot remove option. Minimum of 2 options required.", "Remove");
        }
    };

    // Render the DropdownComponent UI
    return (
        <div className='container-fluid'>
            <div className='row'>
                {/* Render FormCreator component with the current dropdownForm data and handle form field changes */}
                <FormCreator ref={dropdownRef} {...dropdownForm} onFormFieldValueChange={handleFormFieldChange} />
            </div>
            <div className='row my-3'>
                <div className='col-lg-12 d-flex justify-content-end'>
                    <div className='d-flex'>
                        {/* Button to remove the last option field */}
                        <Buttons
                            buttonText="-"
                            buttonTypeClassName="outline-btn"
                            onClick={handlePopOutOptionObject}
                        />
                        {/* Button to add a new option field */}
                        <Buttons
                            buttonText="+"
                            buttonTypeClassName="success-btn mx-4"
                            onClick={handlePushOptionObject}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DropdownComponent;
