import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { PartnerFormData } from './PartnerForm.Data';
import { SectionFormData } from './SectionForm.Data';
import CardSection from '../../../../../components/ui/card/CardSection';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';

const Partner = forwardRef((props, ref) => {
    const [sectionForms, setSectionForms] = useState([SectionFormData]);
    const [addSection, setAddSection] = useState([{ id: 0, partners: [] }]);
    const partnerFormRefs = useRef([]);

    const handleAddPartnerSection = (sectionIndex) => {
        // Add a new partner to the specific section
        const newSection = [...addSection];
        newSection[sectionIndex].partners.push(PartnerFormData);
        setAddSection(newSection);
        console.log("addSection", addSection)
    };

    const handleAddSection = () => {
        setSectionForms((prevForms) => [...prevForms, SectionFormData]);
        setAddSection((prevSections) => [
            ...prevSections,
            { id: prevSections.length, partners: [] },
        ]);
        console.log("addSection", addSection)
        console.log("sectionForms", sectionForms)
    };

    const handleRemoveSection = (index) => {
        setSectionForms((prevForms) => prevForms.filter((_, i) => i !== index));
        setAddSection((prevSections) => prevSections.filter((_, i) => i !== index));
        partnerFormRefs.current = partnerFormRefs.current.filter((_, i) => i !== index);
    };

    const handleDeletePartnerSection = (sectionIndex, partnerIndex) => {
        const newSections = [...addSection];
        newSections[sectionIndex].partners = newSections[sectionIndex].partners.filter(
            (_, i) => i !== partnerIndex
        );
        setAddSection(newSections);
        partnerFormRefs.current = partnerFormRefs.current.filter((_, i) => i !== partnerIndex);
    };

    const handleSubmit = () => {
        const allFormData = partnerFormRefs.current.map((partnerFormRef) =>
            partnerFormRef?.current?.getFormData()
        );
        console.log('All Form Data:', allFormData);
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return partnerFormRefs.current.map((partnerFormRef) =>
                partnerFormRef?.current?.getFormData()
            );
        },
    }));

    return (
        <React.Fragment>
            <CardSection cardTitle="Partnership">
                {sectionForms.map((formData, sectionIndex) => {
                    return (
                        <div key={sectionIndex}>
                            <div className="row">
                                <FormCreator {...formData} />
                                <Buttons
                                    buttonText="Delete Section"
                                    buttonTypeClassName="danger-btn"
                                    onClick={() => handleRemoveSection(sectionIndex)}
                                />
                                <Buttons
                                    buttonText="Add Partner"
                                    buttonTypeClassName="success-btn mb-3"
                                    onClick={() => handleAddPartnerSection(sectionIndex)}
                                />
                            </div>
                            {/* Render partners for this section */}
                            {addSection[sectionIndex].partners.map((partnerFormData, partnerIndex) => {
                                if (!partnerFormRefs.current[partnerIndex]) {
                                    partnerFormRefs.current[partnerIndex] = React.createRef();
                                }
                                const partnerFormRef = partnerFormRefs.current[partnerIndex];

                                return (
                                    <div className="row" key={partnerIndex}>
                                        <FormCreator ref={partnerFormRef} {...partnerFormData} />
                                        <Buttons
                                            buttonText="Delete Partner"
                                            buttonTypeClassName="danger-btn"
                                            onClick={() => handleDeletePartnerSection(sectionIndex, partnerIndex)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <Buttons
                    buttonText="Add Section"
                    buttonTypeClassName="success-btn"
                    onClick={handleAddSection}
                />
            </CardSection>
        </React.Fragment>
    );
});

export default Partner;
