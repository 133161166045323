import { FormFieldTypes } from "../../../../../data/formFieldType";


export const SpeakerFormData = {
    name: "SpeakerFormData",
    initialState: {
        nameOfSpeaker: '',
        company: '',
        designation: '',
        eventImage: '',
        description: '',
    },
    formFields: [
        {
            id: "nameOfSpeaker",
            lable: "Name of Speaker",
            Field_Name: "Name of Speaker",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameOfSpeaker",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
        {
            id: "company",
            lable: "Company",
            Field_Name: "Company",
            fieldType: FormFieldTypes.INPUT,
            dataField: "company",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
        {
            id: "designation",
            lable: "Designation",
            Field_Name: "Designation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "designation",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2",
            },
        },
        {
            id: "eventImage",
            lable: 'Upload Speaker Image',
            subLable: "(Dimension: 814px by 460px Maximum Size: 2MB)",
            Field_Name: 'Upload Speaker Image',
            fieldType: FormFieldTypes.FILE,
            dataField: 'eventImage',
            fieldSetting: {
                placeholder: '',
                acceptedFiles: '.jpg, .png',
                imageCrop: true,
                viewImage: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            id: "description",
            lable: "Description",
            Field_Name: "Description",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "description",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}