import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid';
import { eventFeedbackQuestionTasklistTableConfig } from './data/EventFeedbackQuestionListTable.data';
import { useGetFeedbackQuestionEntryDetailListApiMutation } from '../../../../app/services/EventManagement/EventFeedbackQuestion/eventFeedbackQuestionApi';

const EventFeedbackListTable = ({ paginationReq }) => {
    const molGridRef = useRef();
    const [dataSource, setDataSource] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        SearchText: "",
        totalCount: 0
    });
    const [pendingEventApprovalList, { isSuccess: isFeedbackListSuccess, isLoading: isFeedbackListFetching, data: feedbackListResponse },] = useGetFeedbackQuestionEntryDetailListApiMutation();

    useEffect(() => {
        pendingEventApprovalList(paginationReq)
    }, [])

    useEffect(() => {
        setDataSource([]);
        pendingEventApprovalList(paginationReq)
    }, [paginationReq])

    useEffect(() => {
        if (!isFeedbackListFetching && isFeedbackListSuccess && feedbackListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: feedbackListResponse.totalCount })
            setDataSource(feedbackListResponse.itemList)
        }
    }, [isFeedbackListFetching, isFeedbackListSuccess, feedbackListResponse]);

    const handlePageChange = ({ pageNumber, pageSize }) => {
        pendingEventApprovalList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }


    return (
        <>
            <div>
                <h4>Total Count :{paginationRequest.totalCount} </h4>
            </div>
            <MolGrid
                ref={molGridRef}
                configuration={eventFeedbackQuestionTasklistTableConfig}
                dataSource={dataSource}
                allowPagination={true}
                isLoading={isFeedbackListFetching}
                pagination={paginationRequest}
                onPageChange={handlePageChange}
            />
        </>
    )
}

export default EventFeedbackListTable