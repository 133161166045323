import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";

const TransactionReportApi= createApi({
    reducerPath: 'TransactionReport',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getTransactionRocordList: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/GetTransactionRecordList',
                method: 'POST',
                body: transformRequest(details)
            }),
             transformResponse: transformSucessResponse,
             transformErrorResponse: transformErrorResponse,
        }),
        downloadSheetForFinacialRecord: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/DownloadSheetForFinacialTransaction',
                method: 'POST',
                body: transformRequest(details),
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetTransactionRocordListMutation,
    useDownloadSheetForFinacialRecordMutation,
} = TransactionReportApi;

export default TransactionReportApi;