import React, { useImperativeHandle, useRef, useState, forwardRef } from 'react'
import FormCreator from '../../../components/Forms/FormCreator';
import { TransactionReportFormData } from './data/TransactionReportList.Data';

const TransationReportListFilterForm = forwardRef((props, ref) => {
    const filterFormRef = useRef();
    const [filterForm, setFilterForm] = useState(TransactionReportFormData);

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const filterFormData = filterFormRef.current.getFormData();
                return filterFormData;
            }
            return null;
        }
    }));

    return (
        <FormCreator ref={filterFormRef} {...filterForm} />
    )

})

export default TransationReportListFilterForm