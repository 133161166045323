import { GridColumnType } from "../../../../../data/gridColumnType";

// eventListTableConfig 
export const eventListTableConfig = {
    columns: [
        {
            name: "Event Name",
            fieldName: "eventName",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Event Date",
            fieldName: "eventDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Type",
            fieldName: "eventTypeName",
            colType: GridColumnType.Text,
        },
        {
            name: "Hidden",
            fieldName: "isHidden",
            colType: GridColumnType.Text,
        },
        {
            name: "Booking",
            fieldName: "booking",
            colType: GridColumnType.Text,
        },
        {
            name: "Status",
            fieldName: "status",
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {

                    name: "UPDATE",
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],
        },
    ],
};
