import { FormFieldTypes } from "../../../../data/formFieldType"

export const TransactionReportFormData = {
    name: "TransactionReportFormData",
    initialState: {
        startDate:null,
        endDate:null
       
    },
    formFields: [
        {
            id: "startDate",
            lable: "Start Date",
            Field_Name: "Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "startDate",
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-4 mb-2",
            },
        },
       
        {
            id: "endDate",
            lable: "End Date",
            Field_Name: "End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "endDate",
            fieldSetting: {
                placeholder: "Select",
            },
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}