import { FormFieldTypes } from "../../../../../data/formFieldType";

export const CourseLocationFormData = {
    name: "CourseLocationFormData",
    initialState: {
        courseLocation: "sgbc",
        address: '',
        postalCode: '',
        howToGetThere: '',
        noOfSession: '',
        noOfSeats: '',
        sessionDate: '',
        startAt: '',
        endsAt: ''
    },
    formFields: [
        {
            id: "courseLocation",
            label: "",
            Field_Name: "Course Location",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "courseLocation",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "SGBC", value: "sgbc", isDisable: false, optionClass: "", },
                    { label: "Other Address", value: "other", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-4",
            },
        },
        {
            id: "address",
            lable: "Address",
            Field_Name: "Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "address",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            id: "postalCode",
            lable: "Postal Code",
            Field_Name: "Postal Code",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "postalCode",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
        {
            id: "howToGetThere",
            lable: "How to get there",
            Field_Name: "How to get there",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "howToGetThere",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-2",
            },
        },
        {
            id: "noOfSession",
            lable: "No. of sessions",
            Field_Name: "No. of sessions",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "noOfSession",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            id: "noOfSeats",
            lable: "No. of seats",
            Field_Name: "No. of seats",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "noOfSeats",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
        // {
        //     id: "sessionDate",
        //     lable: "Date",
        //     Field_Name: "Date",
        //     fieldType: FormFieldTypes.DATEPICKER,
        //     dataField: "sessionDate",
        //     fieldSetting: {
        //         placeholder: "",
        //         options: [],
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "col-md-3 mb-2"
        //     }
        // },
        // {
        //     id: "startAt",
        //     lable: "Start At",
        //     Field_Name: "Start At",
        //     fieldType: FormFieldTypes.DATEPICKER,
        //     dataField: "startAt",
        //     fieldSetting: {
        //         placeholder: "",
        //         options: [],
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "col-md-3 mb-2"
        //     }
        // },
        // {
        //     id: "endsAt",
        //     lable: "Ends At",
        //     Field_Name: "Ends At",
        //     fieldType: FormFieldTypes.DATEPICKER,
        //     dataField: "endsAt",
        //     fieldSetting: {
        //         placeholder: "",
        //         options: [],
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "col-md-3 mb-2"
        //     }
        // },
        // {
        //     fieldType: FormFieldTypes.COMPONENT,
        //     additionalComponent: '',
        //     style: {
        //         containerCss: "col-lg-3 mb-2",
        //     }
        // },
    ],
    formSetting: {
        isViewOnly: false
    }
}