/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useRef, useImperativeHandle, useState, useContext, useEffect } from "react";
import FormCreator from "../../../../../components/Forms/FormCreator";
import { academicQualificationData } from "./AcademicQualification.Data";
import { EditApplicationDataContext } from "../../../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import { useLazyGetPDFQuery, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import { saveAs } from "file-saver";
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";

const AcademicQualification = forwardRef((props, ref) => {

    const academicQualificationRef = useRef();
    const [academicQualificationForm, SetAcademicQualificationForm] = useState(academicQualificationData);
    const { newMemberData, setContextData } = useContext(EditApplicationDataContext)
    const [getPDF] = useLazyGetPDFQuery();
    const [uploadPDF] = useUploadPDFMutation()
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const academicData = academicQualificationRef.current.getFormData();
                return academicData;
            }
            return null;
        }
    }))



    const handleClear = () => {
        const updatedForm = { ...academicQualificationForm };
        SetAcademicQualificationForm(updatedForm);
    }


    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            // fileName: newMemberData?.academicCertificate,
            fileName: newMemberData?.academicCertificate || data?.contractInputFile,
        }
        if (dataField === "academicQualificationCertificationFile") {
            request.type = "AcademincQualification"
        }

        const response = await getPDF(request);
        const fileUrl = `${APIUrl}/Common/getPDF?type=AcademincQualification&fileName=${response?.data?.fileName}`;
        window.open(fileUrl, "_blank");
        //saveAs(response?.data?.fileData, response?.data?.fileName);
    }


    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
    }

    const handleOnChangeFileUpload = async (data) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "AcademincQualification");
        try {
            const response = await uploadPDF(formData);
            setContextData({
                ...newMemberData, academicCertificate: response.data.fileName,
                academicQualificationCertificationFileName: response.data.uploadedFileName
            });
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }
    // for file upload changes
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "academicQualificationCertificationFile" && value) {
            handleOnChangeFileUpload(value)
        }
    };
    return (<FormCreator ref={academicQualificationRef}
        {...academicQualificationForm}
        onActionChange={formActionHandler}
        onFormFieldValueChange={handleFormFieldChange}
    />)
})

export default AcademicQualification