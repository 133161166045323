import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";


export const UploadCpdSearchFormData = {
  name: "stutusInfo",
  initialState: {
    status: null
  },
  formFields: [
    {
      id: "name",
      lable: "Name/GMP Number",
      Field_Name: "Name/GmaNumber",
      fieldType: FormFieldTypes.INPUT,
      dataField: "searchText",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "col-lg-4 mt-2",
      },
    },
    //   {
    //     id: "status",
    //     lable: "Status",
    //     Field_Name: "status",
    //     fieldType: FormFieldTypes.SELECT,
    //     dataField: "statusId",
    //     fieldSetting: {
    //       placeholder: "Status",
    //       options: [
    //         { value: 11, label: "Pending" },
    //         { value: 4, label: "Completed" },
    //       ],
    //     },
    //     style: {
    //       containerCss: "col-lg-2"
    //     }
    //   }
  ],
  formSetting: {
    isViewOnly: false
  }
}

export const uploadCPDConfig = {
  columns: [
    {
      name: "upload Date",
      fieldName: "uploadDate",
      allowShort: false,
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Event Name",
      fieldName: "eventName",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Name",
      fieldName: "gmaName",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "GMP Number",
      fieldName: "gmaNumber",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Status",
      fieldName: "status",
      colType: GridColumnType.Text,
    },
  ],
};
