import React, { useEffect, useState } from 'react'
import { CpdRenewalForm } from './DataFile/CpdRenewalCycleForm.Data'
import FormCreator from '../../../components/Forms/FormCreator'
import { useRef } from 'react';
import CardSection from '../../../components/ui/card/CardSection';
import CpdRenewalCycleTable from './component/CpdRenewalCycleTable';
import Buttons from '../../../components/ui/button/Buttons';
import { useGetCPDRenewalCycleListMutation } from '../../../app/services/CPD/CPDApi';
import { useLazyGetAllDropDownsQuery } from '../../../app/services/common/commonApi';
import { setDropdownFieldsCpdRenewalCycle } from './Utils/CpdRenewalCycleUtils';
const CpdRenewalCycleForm = () => {
    const [cpdRenewalForm, setCpdRenewalForm] = useState(CpdRenewalForm);
    const cpdRenewalref = useRef();
    const [dataSource, setDataSource] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        searchText: "",
        totalCount: 0,
        appilcationType: '',
        renewalCycle: '',
        renewalApplicationAndPaymentStatus: '',
        qualificationEndDate: ""
    });

    const [getAllDropDowns, {
        data: allDropDownResponse,
        isSuccess: isGetAllDropDownSuccess,
        isFetching: isGetDropDownFetching,
        isLoading: dropdownListLoading,
    },] = useLazyGetAllDropDownsQuery()

    const [getCpdRenewalCycleList, { isSuccess, isLoading, data: cpdRenewalCycleResponse }] =
        useGetCPDRenewalCycleListMutation();

    useEffect(() => { getAllDropDowns() }, [])

    useEffect(() => {
        if (allDropDownResponse) {
            setDropdownFieldsCpdRenewalCycle(allDropDownResponse, cpdRenewalForm)
        }
    }, [dropdownListLoading, isGetDropDownFetching, isGetAllDropDownSuccess, allDropDownResponse,]);

    useEffect(() => {
        if (!isLoading && isSuccess && cpdRenewalCycleResponse) {
            setPaginationRequest({
                ...paginationRequest,
                totalCount: cpdRenewalCycleResponse.totalCount,
            });
            setDataSource(cpdRenewalCycleResponse?.itemList);
        }
    }, [isLoading, isSuccess, cpdRenewalCycleResponse]);

    const getCpdRenewalList = (req) => {
        getCpdRenewalCycleList({ ...paginationRequest, ...req });
    };

    const handleSearch = () => {
        if (!cpdRenewalref.current) {
            return;
        }
        const formData = cpdRenewalref.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            searchText: formData.searchText || "",
            appilcationType: formData.appilcationType?.value || "",
            renewalCycle: formData.renewalCycle?.value || "",
            renewalApplicationAndPaymentStatus: formData.renewalApplicationAndPaymentStatus?.value || "",
            qualificationEndDate: formData.qualificationEndDate || "",
        };
        setPaginationRequest(updatedPaginationRequest);
        getCpdRenewalList(updatedPaginationRequest);
    };

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getCpdRenewalList({
            ...paginationRequest,
            pageNo: pageNumber,
            pageSize: pageSize,
        });
    };

    return (
        <>
            <div>
                <div className='row'>
                    <div className='current-cpd-cycle-point'>
                        <div className='row '>
                            <FormCreator ref={cpdRenewalref} {...cpdRenewalForm} />
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn mt-4 ml-auto"
                                onClick={handleSearch}
                            />
                        </div>

                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="table-responsive green-head-table">
                            <CpdRenewalCycleTable
                                dataSource={dataSource}
                                isLoading={isLoading}
                                paginationRequest={paginationRequest}
                                onPageChange={handlePageChange}
                                getCpdRenewalCycleList={getCpdRenewalCycleList} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CpdRenewalCycleForm