import { FormFieldTypes } from "../../../../../data/formFieldType";

export const eventQuestionAnswerTasklistFormData = {
    name: "EventListFilterFormData",
    initialState: {
        eventName: '',
    },
    formFields: [
        {
            id: "eventName",
            lable: "Search by Event Name",
            Field_Name: "Search by Event Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "eventName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-4 mb-2 p-3 mx-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}