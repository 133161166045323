import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { cpdprogrammesbyOverseasgreenbuildingCouncilsData } from './CpdprogrammesbyOverseasgreenbuildingCouncils.Data';
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import { saveAs } from 'file-saver'
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { useLazyGetCpdprogrammesbyOverseasgreenbuildingCouncilsByIdQuery } from '../../../../../app/services/CPD/CpdSubmissionApi';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { useAddEditCPDProgrammesMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';

const CpdprogrammesbyOverseasgreenbuildingCouncils = forwardRef((props, ref) => {
  const councilsFormRef = useRef();
  const [councilsForm, setCouncilsForm] = useState(cpdprogrammesbyOverseasgreenbuildingCouncilsData);
  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  const { success, error } = SwalAlert();

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = councilsFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isFetching: isLoadingForm,
      data: formResponse }
  ] = useAddEditCPDProgrammesMutation();


  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);


  const [
    getCpdProgrammesbyOverseasGreenBuildingCouncilsById,
    {
      isSuccess: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdIsSuccess,
      isLoading: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdIsLoading,
      data: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData,
    }
  ] = useLazyGetCpdprogrammesbyOverseasgreenbuildingCouncilsByIdQuery();

  useEffect(() => {
    if (getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdIsSuccess && !getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdIsLoading && getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData) {
      console.log({ getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData });
      let temp = { ...councilsForm }
      let tempInitials = temp.initialState;
      tempInitials.typeofCpdProgramme = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.typeOfCPDProgramme;
      tempInitials.nameofEventOrganiser = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.nameOfEventOrganiser;
      tempInitials.dateofProgramme = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.dateOfProgramme;
      tempInitials.certificateofAttendance = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.certificateOfAttendance;
      tempInitials.CpdPoints = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdPoints;
      tempInitials.remarks = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.remarks;
      tempInitials.processedBy = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdPoints;
      setCouncilsForm(temp);
    }
  }, [getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdIsSuccess, getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdIsLoading, getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData]);

  useEffect(() => {
    getCpdProgrammesbyOverseasGreenBuildingCouncilsById(cpdSubmissionData.cpdSubmissionId);
  }, []);

  const handleDownload = async (data, dataField) => {
    const request = {
      type: "AttendanceCert",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.certificateOfAttendance,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }


  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };

  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "certificateofAttendance" && value) {
      handleOnChangeFileUpload(value)
    }
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "AttendanceCert");
    uploadPDF(formData);
  }

  const handleSaveButton = () => {
    const formData = councilsFormRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "")
    }
    let request = {
      CPDProgrammesId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdProgrammesId,
      GMAPMemberId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdActivityTypeId,
      TypeofCPDProgramme: formData?.typeofCpdProgramme,
      NameofEventOrganiser: formData?.nameofEventOrganiser || null,
      DateOfProgramme: formData?.dateofProgramme || null,
      CertificateofAttendance: fileUploadData?.fileName ? fileUploadData?.fileName : getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.certificateOfAttendance,
      CPDSubmissionId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }
    handleSubmitForm(request)
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdSubmissionId,
      gmapMemberId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };


  const handleApproveButton = () => {
    const formData = councilsFormRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.cpdSubmissionId,
      gmapMemberId: getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };
  return (
    <React.Fragment>
      {getCpdProgrammesbyOverseasGreenBuildingCouncilsByIdData ? <div className="speaking-teaching-engagement">
        {/* <div className="cpd-submission-description">
          <p>Programmes refer to on-site events and e-learning programmes organised by overseas Green Building Councils (GBCs).</p>
          <p>
            SGBC will award the same quantum of CPD points as awarded by the respective GBCs.
          </p>
          <p>SGBC accepts CPD programmes from the following GBCs as being equivalent to SGBC’s CPD programme : </p>
          <p>
            Green Building Council of Australia (GBCA) and Hong Kong Green Building Council (HKGBC).
          </p>
          <p>Click <a href="https://mcusercontent.com/cf5daef3e2509b2b8d51afefa/files/c3273b32-98fb-4825-a45a-92ef71e48f7f/Guide_to_e_Learning_Courses_by_GBCA.01.pdf" target="none"> here </a> for more information on enrolling in GBCA's e-learning programmes and upload of CPD points.</p>
        </div> */}
        <div className="common-project-info-main card-section card-section-left">
          <div className="row gx-5">
            <FormCreator ref={councilsFormRef} {...councilsForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />

          </div>
        </div>
        <div className='row'>
          <div className='common-custom-pagination mt-5'>
            <div>
              <Buttons
                buttonText="Save"
                buttonTypeClassName="outline-btn"
                onClick={handleSaveButton}
                isDisable={props?.isPastCPD}
              />
            </div>
            <div className='d-flex column-gap-3'>
              <Buttons
                buttonText="Reject"
                buttonTypeClassName="red-btn"
                onClick={handleRejectbutton}
                isDisable={props?.isPastCPD}
              />
              <Buttons
                buttonText="Approve"
                buttonTypeClassName="success-btn"
                onClick={handleApproveButton}
                isLoading={cpdSubmissionData?.approveIsLoading}
                isDisable={props?.isPastCPD}
              />
            </div>
          </div>
        </div>
        <div className='center-button'>
          {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
            // onClick={handleSubmit}
            />
          </div> */}
        </div>

      </div>
        : <DataLoader />
      }
    </React.Fragment>
  );
})

export default CpdprogrammesbyOverseasgreenbuildingCouncils;
