import { useGetGMAPMembersCertificateInformationByIdMutation } from "../../../../../app/services/GMAP/membershipFinancialService";
import Buttons from "../../../../../components/ui/button/Buttons";
import { useEffect, useState } from "react";
import { useLazyGetPDFQuery } from "../../../../../app/services/common/commonApi";
import ToastService from "../../../../../services/toastService/ToastService";
import { decryptAESID } from "../../../../../utils/Encryption";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";

const CertificateRecord = () => {
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false) : 0;
    const [certificateFileName, setCertificateFileName] = useState({});
    const [getPDF, { data: getPDFData },] = useLazyGetPDFQuery();
    const [getGMAPFinanceInfo, { data: certificateInfoResponse, isSuccess: isCertificateInfoSuccess, isLoading: isCertificateInfoLoading, },] = useGetGMAPMembersCertificateInformationByIdMutation();

    const getGMAPCertificateRecordDetails = () => {
        let certificateInfoRequest = { GMAPMemberId: editID };
        getGMAPFinanceInfo(certificateInfoRequest);
    };

    useEffect(() => {
        getGMAPCertificateRecordDetails();
    }, []);

    useEffect(() => {
        if (!isCertificateInfoLoading && isCertificateInfoSuccess && certificateInfoResponse) {
            let getcertificateArr = certificateInfoResponse.certificatePath
                ? certificateInfoResponse.certificatePath.split("\\")
                : [];
            let certificateFilename =
                getcertificateArr.length > 1
                    ? getcertificateArr[getcertificateArr.length - 1]
                    : "";
            setCertificateFileName(certificateFilename);
        }
    }, [isCertificateInfoLoading, isCertificateInfoSuccess, certificateInfoResponse]);

    const onInvoiceReceiptFileDownload = async (flag, name) => {
        const fileName = name.split("\\").pop();
        let request = {
            type: flag,
            fileName: name,
        };
        const response = await getPDF(request);
        if (response?.data) {
            saveAs(response?.data?.fileData, fileName);
        } else {
            ToastService.error("Something Went Wrong");
        }
    };

    return (
        <>
            {certificateInfoResponse?.map((item, key) => (
                <div className="text-end record-btn" key={key} style={{ marginBottom: "15px" }}>
                    <Buttons
                        buttonText="View Certificate"
                        buttonTypeClassName="outline-btn"
                        disabled={!item?.certificatePath}
                        onClick={() =>
                            item?.certificatePath
                                ? onInvoiceReceiptFileDownload("FullFilePath", item?.certificatePath)
                                : console.warn("Certificate is not existed")
                        }
                    />
                </div>
            ))}
        </>
    );
};

export default CertificateRecord;
