import React, { useEffect, useRef, useState } from 'react'
import UploadGMAMember from './component/UploadGMAMember'
import MolGrid from '../../components/Grid/MolGrid';
import { GMACourseFormData, uploadGMAMemberConfig } from './data/UploadGMAMember.Data';
import { useGmaCourseMembersListMutation } from '../../app/services/GMAP/gmaApplicationApi';
import FormCreator from '../../components/Forms/FormCreator';
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from "../../app/services/common/commonApi";
import Buttons from '../../components/ui/button/Buttons';

const GMACourseForm = () => {
    const molGridRef = useRef();
    const GMACourseFormDataRef = useRef();
    const [gmaCourseForm, setGmaCourseForm] = useState(GMACourseFormData);
    const [dataSource, setDataSource] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        SearchText: "",
        totalCount: 0,
        statusId: null,
    });

    const [getMemberList, { isSuccess, isLoading, data: memberListResponse }] = useGmaCourseMembersListMutation();
    const [getAllDropdownData, { isLoading: getDropdownDataIsLoading, data: getDropdownData, isSuccess: getAllDropdownDataIsSuccess }] = useLazyGetAllDropDownsForMemberDirectoryQuery();

    useEffect(() => {
        getMemberList(paginationRequest);
    }, []);

    useEffect(() => {
        if (!isLoading && isSuccess && memberListResponse) {
            setPaginationRequest(prev => ({ ...prev, totalCount: memberListResponse.totalCount }));
            setDataSource(memberListResponse?.itemList);
        }
    }, [isLoading, isSuccess, memberListResponse]);

    const handleSearch = () => {
        if (!GMACourseFormDataRef.current) {
            return;
        }
        const taskListFormData = GMACourseFormDataRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            SearchText: taskListFormData.name || "",
            StatusId: taskListFormData?.statusId?.value || taskListFormData?.statusId
        };
        setPaginationRequest(updatedPaginationRequest);
        getMemberList(updatedPaginationRequest);
    };

    const handlePageChange = ({ pageNumber, pageSize }) => {
        const updatedPaginationRequest = { ...paginationRequest, pageNo: pageNumber, pageSize: pageSize };
        setPaginationRequest(updatedPaginationRequest);
        getMemberList(updatedPaginationRequest);
    };

    const handleReloadList = () => {
        getMemberList(paginationRequest);
    };

    useEffect(() => {
        getAllDropdownData();
    }, []);

    useEffect(() => {
        if (getDropdownData) {
            let statusDropdown = filterDropDownList("Status");
            statusDropdown = statusDropdown.filter(obj => obj.label === 'Pending' || obj.label === 'Completed');
            let trmpFormField = { ...GMACourseFormData };
            trmpFormField = trmpFormField?.formFields?.map((obj) =>
                obj?.id === "status" ? {
                    ...obj, fieldSetting: {
                        placeholder: "",
                        options: statusDropdown
                    }
                }
                    : obj
            )
            setGmaCourseForm({ ...GMACourseFormData, formFields: trmpFormField, });
        }
    }, [getDropdownDataIsLoading, getDropdownData, getAllDropdownDataIsSuccess]);

    const filterDropDownList = (dropdownName) => {
        return getDropdownData.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    };

    return (
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-lg-6 mt-4'>
                    <UploadGMAMember handleReloadList={handleReloadList} />
                </div>
                <FormCreator ref={GMACourseFormDataRef} {...gmaCourseForm} />
                <div className='col-lg-1 d-flex justify-content-start'>
                    <Buttons
                        buttonText="Search"
                        buttonTypeClassName="success-btn ml-2 mt-4"
                        onClick={handleSearch}
                    />
                </div>

            </div>

            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <MolGrid
                        ref={molGridRef}
                        configuration={uploadGMAMemberConfig}
                        dataSource={dataSource}
                        allowPagination={true}
                        isLoading={isLoading}
                        pagination={paginationRequest}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>


    );
};

export default GMACourseForm;
