import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid';
import { eventQuestionAnswerTasklistTableConfig } from './data/EventQuestionAnswerListTable.data';
import { saveAs } from "file-saver";
import { useGetFormQuestionAnswerEntryDetailListMutation, useLazyGetAllFormQuestionAnswerListByEventIdQuery } from '../../../../app/services/EventManagement/EventQuestionAnswer/eventQuestionAnswerApi';
import ToastService from '../../../../services/toastService/ToastService';

const EventQuestionAnswerListTable = ({ paginationReq }) => {
    const molGridRef = useRef();
    const [dataSource, setDataSource] = useState();

    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        SearchText: "",
        totalCount: 0
    });
    const [getFormQuestionAnswerEntryDetailList, { isSuccess: isSuccessList, isLoading: isFetchingList, data: QuestionAnswerListResponse },] = useGetFormQuestionAnswerEntryDetailListMutation();
    const [GetExcelSheet] = useLazyGetAllFormQuestionAnswerListByEventIdQuery();
    useEffect(() => {
        getFormQuestionAnswerEntryDetailList(paginationRequest)
    }, [])

    useEffect(() => {
        setDataSource([]);
        getFormQuestionAnswerEntryDetailList(paginationReq)
    }, [paginationReq])

    useEffect(() => {
        if (!isFetchingList && isSuccessList && QuestionAnswerListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: QuestionAnswerListResponse.totalCount })
            setDataSource(QuestionAnswerListResponse.itemList)
        }

    }, [isFetchingList, isSuccessList, QuestionAnswerListResponse]);

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getFormQuestionAnswerEntryDetailList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }
    const handleDownloadClick = async (data) => {

    }

    const actionHandler = {
        DOWNLOAD: handleDownloadClick,
    }

    return (
        <>
            <div>
                <h4>Total Count :{paginationRequest.totalCount} </h4>
            </div>
            <MolGrid
                ref={molGridRef}
                configuration={eventQuestionAnswerTasklistTableConfig}
                dataSource={dataSource}
                allowPagination={true}
                isLoading={isFetchingList}
                pagination={paginationRequest}
                onPageChange={handlePageChange}
                onActionChange={actionHandler}
            />
        </>
    )
}

export default EventQuestionAnswerListTable