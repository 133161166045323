import React from "react";
import { FormFieldTypes } from "../../data/formFieldType";
import { TextInputType } from "../../data/formControlTypes";
import Line from "../ui/separator/Line";
import FormMainTitle from "../ui/formTitle/FormMainTitle";
import FormInputGroupSelect from "./formField/FormInputGroupSelect";
import CKEditorField from "./formField/FormCkEditorField";
import AdditionalComponent from "../ui/component/Component";

const FormInputFields = React.lazy(() => import("./formField/FormInputFields"));
const FormSelectField = React.lazy(() => import("./formField/FormSelectField"));
const FormCheckboxField = React.lazy(() => import("./formField/FormCheckBoxFields"));
const FormDatePickerField = React.lazy(() => import("./formField/FormDatePickerField"));
const FormRadioButtonField = React.lazy(() => import("./formField/FormRadioButtonField"));
const FormTextEditorField = React.lazy(() => import("./formField/FormTextEditorField"));
const FormTextAreaFields = React.lazy(() => import("./formField/FormTextAreaField"));
const FormTinyEditorField = React.lazy(() => import("./formField/FormTinyEditorField"));
const FormFileUploadField = React.lazy(() => import("./formField/FormFileUploadField"));
const FormEditableSelectField = React.lazy(() => import("./formField/FormEditableSelectField"));
const FormTable = React.lazy(() => import("./formField/FormTable"));

const FormFields = ({
  fields,
  formData,
  validState,
  onFormStateChange,
  onUpdateValidation,
  formSetting,
  onActionChange,
  onFormFieldChange,
  fieldValiadtionRules,
  handleTableEdit,
  handleTableDelete
}) => {
  const handleInputChange = (dataField, value) => {
    const updatedData = { ...formData, [dataField]: value };
    onFormStateChange && onFormStateChange(updatedData);
    onFormFieldChange && onFormFieldChange(dataField, value, updatedData);
  };

  const renderField = (field, index) => {
    const { containerCss } = field.style || { containerCss: "col-md-6" };
    const isRequired = fieldValiadtionRules && fieldValiadtionRules[field.dataField]?.length > 0;
    const key = field.dataField;
    const isMultiSelect = field.fieldSetting?.isMultiSelect;
    switch (field.fieldType) {
      case FormFieldTypes.INPUT:
      case FormFieldTypes.PASSWORD:
      case FormFieldTypes.NUMERIC:
        return (
          <div className={containerCss} key={key}>
            <FormInputFields
              key={key}
              dataField={field.dataField}
              labelName={field.lable}
              name={field.id}
              type={fieldTypeToInputType(field.fieldType)}
              value={
                typeof formData?.[field.dataField] === "number"
                  ? formData?.[field.dataField]
                  : formData?.[field.dataField] || ""
              }
              onChange={handleInputChange}
              error={validState.error[field.dataField] || ""}
              onValidation={onUpdateValidation}
              isRequired={isRequired}
              formSetting={formSetting}
              {...field.fieldSetting}
            />
          </div>
        );

      case FormFieldTypes.TEXTAREA:
        return (
          <div className={containerCss} key={key}>
            <FormTextAreaFields
              key={key}
              dataField={field.dataField}
              labelName={field.lable}
              name={field.id}
              type={fieldTypeToInputType(field.fieldType)}
              value={formData?.[field.dataField] || ""}
              onChange={handleInputChange}
              error={validState.error[field.dataField] || ""}
              onValidation={onUpdateValidation}
              formSetting={formSetting}
              isRequired={isRequired}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.CHECKBOX:
        // Render a checkbox input
        return (
          <div className={containerCss} key={key}>
            <FormCheckboxField
              labelName={field.lable}
              dataField={field.dataField}
              type={fieldTypeToInputType(field.fieldType)}
              checked={formData?.[field.dataField] || false}
              name={field.id}
              onChange={handleInputChange}
              onValidation={onUpdateValidation}
              formSetting={formSetting}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.RADIOBUTTON:
        return (
          <div className={containerCss} key={key}>
            <FormRadioButtonField
              key={key}
              labelName={field.label}
              name={field.id}
              onChange={handleInputChange}
              onValidation={onUpdateValidation}
              dataField={field.dataField}
              selectedOption={formData?.[field.dataField] || ""}
              error={validState.error[field.dataField] || ""}
              formSetting={formSetting}
              options={field.fieldSetting.options}
              isRequired={isRequired}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.DATEPICKER:
        // Render a checkbox input
        return (
          <div className={containerCss} key={key}>
            <FormDatePickerField
              labelName={field.lable}
              dataField={field.dataField}
              selectedDate={formData?.[field.dataField] || null} //TODO : Check if datefield value is in proper date format
              name={field.id}
              onChange={handleInputChange}
              onValidation={onUpdateValidation}
              error={validState.error[field.dataField] || ""}
              formSetting={formSetting}
              isRequired={isRequired}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.SELECT:
        return (
          <div className={containerCss} key={key}>
            <FormSelectField
              labelName={field.lable}
              dataField={field.dataField}
              selectedOption={field.value}
              options={field.options}
              name={field.id}
              onValidation={onUpdateValidation}
              value={formData?.[field.dataField] || null}
              error={validState.error[field.dataField] || ""}
              onChange={handleInputChange}
              fieldActions={onActionChange}
              formSetting={formSetting}
              isRequired={isRequired}
              isMultiSelect={isMultiSelect}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.SUNEDITOR:
        return (
          <div className={containerCss} key={key}>
            <FormTextEditorField
              key={key}
              labelName={field.lable}
              dataField={field.dataField}
              name={field.id}
              onValidation={onUpdateValidation}
              value={formData?.[field.dataField] || ""}
              onChange={handleInputChange}
              error={validState.error[field.dataField] || ""}
              formSetting={formSetting}
              isRequired={isRequired}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.TINYEDITOR:
        return (
          <div className={containerCss} key={key}>
            <FormTinyEditorField
              key={key}
              labelName={field.lable}
              dataField={field.dataField}
              name={field.id}
              onValidation={onUpdateValidation}
              value={formData?.[field.dataField] || ""}
              onChange={handleInputChange}
              error={validState.error[field.dataField] || ""}
              formSetting={formSetting}
              isRequired={isRequired}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.FILE:
        return (
          <div className={containerCss} key={key}>
            <FormFileUploadField
              key={key}
              labelName={field.lable}
              subLabelName={field.subLable}
              dataField={field.dataField}
              name={field.id}
              onValidation={onUpdateValidation}
              value={formData?.[field.dataField] || null}
              onChange={handleInputChange}
              error={validState.error[field.dataField] || ""}
              formSetting={formSetting}
              fieldActions={onActionChange}
              isRequired={isRequired}
              {...field.fieldSetting}
            // isModelOpen={formData.isModelOpen}
            />
          </div>
        );
      case FormFieldTypes.EDITABLEDROPDOWN:
        return (
          <FormEditableSelectField
            key={key}
            labelName={field.lable}
            dataField={field.dataField}
            name={field.id}
            onValidation={onUpdateValidation}
            value={formData?.[field.dataField] || ""}
            onChange={handleInputChange}
            error={validState.error[field.dataField] || ""}
            formSetting={formSetting}
            fieldActions={onActionChange}
            isRequired={isRequired}
            {...field.fieldSetting}
          />
        )
      case FormFieldTypes.SEPARATOR:
        return <Line containerCss={containerCss} key={key} />;
      case FormFieldTypes.MAINFORMTITLE:
        return (
          <FormMainTitle key={key} {...field.fieldSetting} containerCss={containerCss} />
        );
      case FormFieldTypes.INPUTGROUPSELECT:
        return (
          <div className={containerCss} key={key}>
            <FormInputGroupSelect
              selectedOption={field.value}
              options={field.options}
              fieldActions={onActionChange}
              isMultiSelect={isMultiSelect}
              {...field.fieldSetting}
              key={key}
              dataField={field.dataField}
              labelName={field.lable}
              name={field.id}
              type={fieldTypeToInputType(field.fieldType)}
              value={field?.fieldSetting?.isEdit ? [formData?.[field?.dataField[0]], formData?.[field?.dataField[1]]] : [formData?.[field?.dataField[0]], formData?.[field?.dataField[1]]]}
              onChange={handleInputChange}
              dropDownError={validState.error[field.dataField[0]] || ""}
              inputError={validState.error[field.dataField[1]] || ""}
              // error={validState.error[field.dataField[1]] || ""}
              onValidation={onUpdateValidation}
              isRequired={true}
              formSetting={formSetting}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.TABLE:
        return (
          <div className={containerCss} key={key}>
            <FormTable
              tableConfig={field?.fieldSetting}
              handleTableEdit={handleTableEdit}
              handleTableDelete={handleTableDelete}
            />
          </div>
        );
      case FormFieldTypes.CKEDITOR:
        return (
          <div className={containerCss}>
            <CKEditorField
              key={field.dataField}
              labelName={field.lable}
              dataField={field.dataField}
              name={field.id}
              onValidation={onUpdateValidation}
              value={formData?.[field.dataField] || ""}
              onChange={handleInputChange}
              error={validState.error[field.dataField] || ""}
              formSetting={formSetting}
              formData={formData}
              changeAction={field.changeAction}
              // overRideProps={overRideProps?.[field.dataField]}
              isRequired={isRequired}
              {...field.fieldSetting}
            />
          </div>
        );
      case FormFieldTypes.COMPONENT:
        return <AdditionalComponent
          key={key}
          content={field.additionalComponent}
          containerCss={containerCss}
        />;
      // Add similar cases for other field types (FileUpload, Radio, Date, DateTime)
      default:
        return null;
    }
  };

  return <>{fields.map(renderField)}</>;
};

const fieldTypeToInputType = (fieldtype) => {
  switch (fieldtype) {
    case FormFieldTypes.PASSWORD:
      return TextInputType.PASSWORD;
    case FormFieldTypes.NUMERIC:
      return TextInputType.NUMBER;
    case FormFieldTypes.CHECKBOX:
      return TextInputType.CHECKBOX;
    case FormFieldTypes.EDITABLEDROPDOWN:
      return TextInputType.TEXT;
    case FormFieldTypes.DATEPICKER:
      return TextInputType.DATEPICKER;
    case FormFieldTypes.TEXTAREA:
      return TextInputType.TEXT;
    case FormFieldTypes.TEXTEDITOR:
      return TextInputType.TEXT;
    case FormFieldTypes.TINYEDITOR:
      return TextInputType.TEXT;
    case FormFieldTypes.CKEDITOR:
      return TextInputType.TEXT;
    case FormFieldTypes.FILE:
      return TextInputType.FILE;
    case FormFieldTypes.RADIOBUTTON:
      return TextInputType.RADIO;
    default:
      return TextInputType.TEXT;
  }
};
export default FormFields;
