import React, { forwardRef, useContext, useState } from 'react';
import './AddQuestionButton.css';
import { AppIcons } from '../../../../../../data/appIcons';
import Image from '../../../../../../components/image/Image';
import Buttons from '../../../../../../components/ui/button/Buttons';
import BasicDetailContext from '../../../../utils/BasicDetailContext';
import SwalAlert from '../../../../../../services/swalService/SwalService';

// Define AddQuestionButton component with forwardRef to allow ref forwarding
const AddQuestionButton = forwardRef((props, ref) => {
    // Destructure question type lists and state setters from context
    const { questionTypeList, setQuestionTypeList, feedbackQuestionTypeList, setFeedQuestionTypeList } = useContext(BasicDetailContext);
    // State to control question list visibility
    const [showQuestionList, setShowQuestionList] = useState(false);
    const { error } = SwalAlert();

    // Define available input types with icons, titles, and subtitles for display
    const inputTypes = [
        { id: 'textbox', icon: AppIcons.workshopIcon, title: 'Textbox', subtitle: 'Provide an open-text response' },
        { id: 'dropdown', icon: AppIcons.swapIcon, title: 'Drop-down', subtitle: 'Select a single answer from a list of options' },
        { id: 'multiselectdropdown', icon: AppIcons.workshopIcon, title: 'Multi-Select Drop-down', subtitle: 'Select multiple answers from a list of options' },
        { id: 'radio', icon: AppIcons.swapIcon, title: 'Radio Buttons', subtitle: 'Select a single answer from a list of options' },
        { id: 'checkbox', icon: AppIcons.workshopIcon, title: 'Checkboxes', subtitle: 'Select multiple answers from a list of options' },
    ];

    // Handle selection of input type, updating the appropriate question type list based on mode
    const handleInputClick = (type) => {
        const isAdditionalMode = props?.mode === "Additional";
        const isFeedbackMode = props?.mode === "Feedback";

        if (isAdditionalMode && questionTypeList.length < 5) {
            setQuestionTypeList([...questionTypeList, type]);
        } else if (isFeedbackMode && feedbackQuestionTypeList.length < 5) {
            setFeedQuestionTypeList([...feedbackQuestionTypeList, type]);
        } else {
            error("You can only select up to 5 question types.");
        }
        // Toggle the question list visibility after selecting a type
        setShowQuestionList(!showQuestionList);
    };

    // Toggle display of available question types
    const handleAddQuestionModule = () => {
        setShowQuestionList(!showQuestionList);
    };

    return (
        <div className="container-fluid">
            {/* Button to add a new question */}
            <Buttons
                buttonText="Add a Question"
                buttonTypeClassName="success-btn mb-3"
                onClick={handleAddQuestionModule}
            />

            {/* Display input types if showQuestionList is true */}
            {showQuestionList && inputTypes.map((input) => (
                <div
                    key={input.id}
                    className="input-type"
                    onClick={() => handleInputClick(input.id)}
                >
                    {/* Display icon for the input type */}
                    <div className="icon-box">
                        <Image
                            imagePath={input.icon}
                            altText={input.title}
                        />
                    </div>
                    {/* Display input type title and subtitle */}
                    <div className="input-details">
                        <p className="input-title">{input.title}</p>
                        <p className="input-subtitle">{input.subtitle}</p>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default AddQuestionButton;
