import React, { useEffect, useRef, useState } from "react";
import UploadCpdButton from "./Components/UploadCpdButton";
import Buttons from "../../../components/ui/button/Buttons";
import FormCreator from "../../../components/Forms/FormCreator";
import {
  uploadCPDConfig,
  UploadCpdSearchFormData,
} from "./dataFile/UploadCpdSearchForm.Data";
import MolGrid from "../../../components/Grid/MolGrid";
import { useGetUploadedCPDListMutation } from "../../../app/services/CPD/CPDApi";
import UploadCpdDataGrid from "./Components/UploadCpdDataGrid";

const UploadCpd = () => {
  const [uploadCPDSearchFormData, setUploadCPDSearchFormData] = useState(
    UploadCpdSearchFormData
  );
  const uploadCPDSearchFormRef = useRef();

  const [dataSource, setDataSource] = useState();
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 20,
    endPage: 100,
    searchText: "",
    totalCount: 0,
  });

  const [getCpdList, { isSuccess, isLoading, data: cpdListResponse }] =
    useGetUploadedCPDListMutation();

  useEffect(() => {
    if (!isLoading && isSuccess && cpdListResponse) {
      setPaginationRequest({
        ...paginationRequest,
        totalCount: cpdListResponse.totalCount,
      });
      setDataSource(cpdListResponse?.itemList);
    }
  }, [isLoading, isSuccess, cpdListResponse]);

  const handleSearch = () => {
    if (!uploadCPDSearchFormRef.current) {
      return;
    }
    const formData = uploadCPDSearchFormRef.current.getFormData();
    const updatedPaginationRequest = {
      pageNo: 1,
      pageSize: 20,
      endPage: 100,
      searchText: formData.searchText || "",
    };
    setPaginationRequest(updatedPaginationRequest);
    getUploadedCpdList(updatedPaginationRequest);
  };

  const getUploadedCpdList = (req) => {
    getCpdList({ ...paginationRequest, ...req });
  };

  const handlePageChange = ({ pageNumber, pageSize }) => {
    getUploadedCpdList({
      ...paginationRequest,
      pageNo: pageNumber,
      pageSize: pageSize,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-between">
        <div className='col-lg-7 mt-4'>
          <UploadCpdButton getUploadedCpdList={getUploadedCpdList} />
        </div>
        <FormCreator
          ref={uploadCPDSearchFormRef}
          {...uploadCPDSearchFormData}
        />
        <div className="col-lg-1">
          <Buttons
            buttonText="Search"
            buttonTypeClassName="success-btn mt-4"
            onClick={handleSearch}
          />
        </div>
      </div>
      <UploadCpdDataGrid
        dataSource={dataSource}
        isLoading={isLoading}
        paginationRequest={paginationRequest}
        onPageChange={handlePageChange}
        getUploadedCpdList={getUploadedCpdList}
      />
    </div>
  );
};

export default UploadCpd;
