import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { createApi } from "@reduxjs/toolkit/query/react";

const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        userLogin: builder.mutation({
            query: (details) => ({
                url: '/Account/AdminLogin',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        forgotPasswordMember: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/ForgotPasswordMember',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        resetPasswordForWebMember: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/ResetPasswordForWebMember',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),

})

export const {  
    useUserLoginMutation,
    useForgotPasswordMemberMutation,
    useResetPasswordForWebMemberMutation
} = loginApi;

export default loginApi;