/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { gmapApplicationTasklistFormData } from "./GmapApplicationTasklistForm.Data";
import FormCreator from "../../../../components/Forms/FormCreator";
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from "../../../../app/services/common/commonApi";

const GmapApplicationTasklistForm = forwardRef((props, ref) => {
    const GmapApplicationTasklistRef = useRef();
    const [GmapApplicationTasklistForm, SetgmapApplicationTasklist] = useState(gmapApplicationTasklistFormData);

    const [getAllDropDowns, { data: allDropDownResponse, isSuccess: isGetAllDropDownSuccess, isFetching: isGetDropDownFetching, isLoading: dropdownListLoading, },] = useLazyGetAllDropDownsForMemberDirectoryQuery()

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const ViewIndividualMembersFormData = GmapApplicationTasklistRef.current.getFormData();
                return ViewIndividualMembersFormData;
            }
            return null;
        }
    }));

    const filterDropDownList = (dropdownName) => {
        return allDropDownResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return acc;
        }, []);
    };

    useEffect(() => {
        if (allDropDownResponse) {
            let statusDropdown = filterDropDownList("Status");
            statusDropdown = statusDropdown.filter(obj => obj.label !== 'Completed' && obj.label !== 'Pending Approval')
            let trmpFormField = { ...GmapApplicationTasklistForm };
            trmpFormField = trmpFormField?.formFields?.map((obj) =>
                obj?.id === "status" ? {
                    ...obj, fieldSetting: {
                        placeholder: "",
                        options: statusDropdown,
                        isMultiSelect: true,
                    }
                }
                    : obj
            )
            SetgmapApplicationTasklist({ ...GmapApplicationTasklistForm, formFields: trmpFormField })
        }
    }, [dropdownListLoading, isGetDropDownFetching, isGetAllDropDownSuccess, allDropDownResponse,]);



    useEffect(() => { getAllDropDowns() }, [])

    return (
        <FormCreator ref={GmapApplicationTasklistRef} {...GmapApplicationTasklistForm} />
    )

})

export default GmapApplicationTasklistForm