import React, { useEffect, useRef, useState } from 'react'
import TransationReportListFilterForm from './TransationReportListFilterForm'
import Buttons from '../../../components/ui/button/Buttons'
import TransactionReportListTable from './TransactionReportListTable'
import { useDownloadSheetForFinacialRecordMutation } from '../../../app/services/Financials/TransactionReport'
import { saveAs } from 'file-saver'
const TransactionReportList = () => {
    const initState = {
        pageNo: 1,
        pageSize: 20,
        endPage: 100,
        totalCount: 0,
        StartDate: null,
        EndDate: null
    }
    const transactionReportListRef = useRef(null)
    const [paginationRequest, setPaginationRequest] = useState(initState);
    const handleSearchTransaction = () => {
        const taskListFormData = transactionReportListRef.current.getFormData();
        if (!transactionReportListRef.current) {
            return;
        }
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            StartDate: taskListFormData?.startDate || null,
            EndDate: taskListFormData?.endDate || null
        };
        setPaginationRequest(updatedPaginationRequest);
    };
    const [downloadSheetForFinacialRecord,
        { isSuccess: isDownloadSheetForDownloadSheetForFinacialRecordSuccess,
            isLoading: isDownloadSheetForDownloadSheetForFinacialRecordFetching,
            data: DownloadSheetForFinacialRecordResponse },
    ] = useDownloadSheetForFinacialRecordMutation();

    useEffect(() => {
        if (!isDownloadSheetForDownloadSheetForFinacialRecordFetching && isDownloadSheetForDownloadSheetForFinacialRecordSuccess && DownloadSheetForFinacialRecordResponse) {
        }
    }, [isDownloadSheetForDownloadSheetForFinacialRecordFetching, isDownloadSheetForDownloadSheetForFinacialRecordSuccess, DownloadSheetForFinacialRecordResponse]);

    const handleDownload = async () => {
        const requestPayload = {
            ...paginationRequest,
            StartDate: paginationRequest.StartDate || null,
            EndDate: paginationRequest.EndDate || null
        };

        try {
            const response = await downloadSheetForFinacialRecord(requestPayload);

            if (response && response.data) {
                const { fileData, fileName } = response.data;
                const blob = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, fileName || 'download.xlsx');
            }
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
    return (
        <div className="common-first-section" >
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <TransationReportListFilterForm ref={transactionReportListRef} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end align-items-center">
                    <div className="two-button-fixes">
                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearchTransaction}
                            />
                        </div>
                    </div>
                    <div className=" two-button-fixes mx-4">
                        <Buttons
                            buttonText="Download Excel"
                            buttonTypeClassName="success-btn"
                            onClick={handleDownload}
                        />
                    </div>

                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <TransactionReportListTable paginationReq={paginationRequest} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            {/* <EventApprovalPage paginationReq={paginationRequest} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TransactionReportList