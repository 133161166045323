// dropdownUtils.js

import { CreateAccountManagementData } from "../data/CreateAccountManagement.data";

export const setAllDropDownField = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name,
                    Point: item?.valueOrdering || 0
                });
            }
            return acc;
        }, []);
    }

    const dropdownLists = {
        "roleId": filterDropDownList("Roles"),
    };

    const formFields = [
        ...CreateAccountManagementData.formFields,
    ];

    formFields.forEach(field => {
        const dropdownList = dropdownLists[field?.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
