import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../components/ui/button/Buttons";
import EventListTable from "./component/ViewEventManagementList/EventListTable";
import EventListFilterForm from "./component/ViewEventManagementList/EventListFilterForm";
import { useLazyGetAllDropDownsForEventManagementQuery } from "../../app/services/common/commonApi";
import { EventListFilterFormData } from "./component/ViewEventManagementList/data/EventListForm.Data";

const initState = {
    pageNo: 1,
    pageSize: 20,
    endPage: 100,
    SearchText: "",
    StatusId: 0,
    HiddenSearch: "",
    totalCount: 0
}

const ViewEditEventManagementList = () => {
    const eventListRef = useRef(null)
    const [paginationRequest, setPaginationRequest] = useState(initState);
    const [eventListFilterForm, setEventListFilterForm] = useState(EventListFilterFormData);
    const [getAllDropdownData, { isLoading: getDropdownDataIsLoading, data: getDropdownData, isSuccess: getAllDropdownDataIsSuccess }] = useLazyGetAllDropDownsForEventManagementQuery();

    const handleSearchApplicationTask = () => {
        const taskListFormData = eventListRef.current.getFormData();
        if (!eventListRef.current) {
            return;
        }
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            SearchText: taskListFormData.eventName,
            HiddenSearch: taskListFormData.hidden?.label || taskListFormData.hidden,
            StatusId: taskListFormData?.status?.value || taskListFormData?.status
        };
        setPaginationRequest(updatedPaginationRequest);
    };

    useEffect(() => {
        getAllDropdownData();
    }, []);

    useEffect(() => {
        if (getDropdownData) {
            let statusDropdown = filterDropDownList("Status");
            statusDropdown = statusDropdown.filter(obj => obj.label === 'Draft' || obj.label === 'Submitted' || obj.label === "Approved");
            let trmpFormField = { ...EventListFilterFormData };
            trmpFormField = trmpFormField?.formFields?.map((obj) =>
                obj?.id === "status" ? {
                    ...obj, fieldSetting: {
                        placeholder: "Select",
                        options: statusDropdown
                    }
                }
                    : obj
            )
            setEventListFilterForm({ ...EventListFilterFormData, formFields: trmpFormField, });
        }
    }, [getDropdownDataIsLoading, getDropdownData, getAllDropdownDataIsSuccess]);

    const filterDropDownList = (dropdownName) => {
        return getDropdownData.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    };

    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <EventListFilterForm ref={eventListRef} {...eventListFilterForm} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end align-items-center">
                    <div className="two-button-fixes">

                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearchApplicationTask}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <EventListTable paginationReq={paginationRequest} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewEditEventManagementList;
