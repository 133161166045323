import React from "react"
import EventQuestionAnswerList from "../../features/eventManagement/EventQuestionAnswerList";

const EventQuestionAnswer = (props) => {
    return (
        <div>
            <EventQuestionAnswerList />
        </div>
    )
};

export default EventQuestionAnswer;
