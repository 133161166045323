import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { accreditedCpdEventsData } from './SGBCAccreditedCpdEvents.Data';
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { saveAs } from 'file-saver';
import { useGetSGBCAccreditedCpdEventDetailByIdMutation } from '../../../../../app/services/CPD/CpdSubmissionApi';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import { useSGBCAccreditiedCPDEventsMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';

const SGBCAccreditedCpdEvents = forwardRef((props, ref) => {

  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  const accreditedCpdEventsRef = useRef();
  const { success, error } = SwalAlert();
  const [accreditedCpdEventsForm, setAccreditedCpdEventsForm] = useState(accreditedCpdEventsData);

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = accreditedCpdEventsRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const [getSGBCAccreditedCpdEventDetailById,
    { isSuccess: getSGBCAccreditedCpdEventDetailByIdIsSuccess,
      isLoading: getSGBCAccreditedCpdEventDetailByIdisLoading,
      data: getSGBCAccreditedCpdEventDetailByIdData }
  ] = useGetSGBCAccreditedCpdEventDetailByIdMutation();

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isFetching: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();


  useEffect(() => {
    if (getSGBCAccreditedCpdEventDetailByIdIsSuccess && !getSGBCAccreditedCpdEventDetailByIdisLoading && getSGBCAccreditedCpdEventDetailByIdData) {
      console.log({ getSGBCAccreditedCpdEventDetailByIdData })
      let temp = { ...accreditedCpdEventsForm }

      temp.initialState.titleOfEvent = getSGBCAccreditedCpdEventDetailByIdData.titleOfEvent
      temp.initialState.eventOrg = getSGBCAccreditedCpdEventDetailByIdData.eventOrganiserId
      temp.initialState.eventStartDate = getSGBCAccreditedCpdEventDetailByIdData.eventstartdate
      temp.initialState.durations = getSGBCAccreditedCpdEventDetailByIdData.durationofEvent
      temp.initialState.certificateOfAttendance = getSGBCAccreditedCpdEventDetailByIdData.certificateofAttendance

      temp.initialState.CpdPoints = getSGBCAccreditedCpdEventDetailByIdData.cpdPoints;
      temp.initialState.remarks = getSGBCAccreditedCpdEventDetailByIdData.remarks;
      temp.initialState.processedBy = getSGBCAccreditedCpdEventDetailByIdData.cpdReceivedById;
      temp.initialState.cpdPointsAwarded = getSGBCAccreditedCpdEventDetailByIdData.cpdPoints;

      setAccreditedCpdEventsForm(temp);
    }
  }, [getSGBCAccreditedCpdEventDetailByIdIsSuccess, getSGBCAccreditedCpdEventDetailByIdisLoading, getSGBCAccreditedCpdEventDetailByIdData])

  useEffect(() => {
    getSGBCAccreditedCpdEventDetailById(cpdSubmissionData.cpdSubmissionId)
  }, [])

  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isFetching: isLoadingForm,
      data: formResponse }
  ] = useSGBCAccreditiedCPDEventsMutation();


  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);

  const handleDownload = async (data, dataField) => {
    const request = {
      type: "CertificateofAttendance",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getSGBCAccreditedCpdEventDetailByIdData.certificateofAttendance,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }

  const handleClearFile = (data, dataField) => {
    if (dataField === "certificateOfAttendance") {
      const updatedForm = { ...accreditedCpdEventsForm };
      updatedForm.initialState.certificateOfAttendance = null;
      setAccreditedCpdEventsForm(updatedForm);
    }
  }


  const formActionHandler = {
    DOWNLOAD: handleDownload,
    CLEAR: handleClearFile
  };

  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "certificateOfAttendance" && value) {
      handleOnChangeFileUpload(value)
    }
    else if (dataField === "durations") {
      handleDurationChange(value);
    };
  };

  const handleDurationChange = (duration) => {
    const updatedForm = { ...accreditedCpdEventsForm };
    const cpdPoints = duration > 6 ? 6 : parseInt(duration);
    updatedForm.initialState.durations = duration;
    const cpdPointsField = updatedForm.formFields.find(field => field.dataField === 'CpdPoints');
    if (cpdPointsField) {
      cpdPointsField.fieldSetting.value = cpdPoints;
    }
    updatedForm.initialState.durations = cpdPoints;
    setAccreditedCpdEventsForm(updatedForm);
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "CertificateofAttendance");
    uploadPDF(formData);
  }

  const handleSaveButton = () => {
    const formData = accreditedCpdEventsRef.current.getFormData();
    if (!formData) return error("Please fill all mandatory fields.", "");

    const selectedDuration = formData?.durations || null;
    const cpdPoints = selectedDuration > 6 ? 6 : parseInt(formData?.durations);

    let request = {
      GMAPMemberId: getSGBCAccreditedCpdEventDetailByIdData.gmapMemberId,
      CPDAccreditedEventsId: getSGBCAccreditedCpdEventDetailByIdData.cpdAccreditedEventsId,
      CPDPoints: cpdPoints || null,
      CPDActivityTypeId: getSGBCAccreditedCpdEventDetailByIdData.cpdActivityTypeId,
      Remarks: formData?.remarks,

      TitleOfEvent: formData.titleOfEvent,
      EventOrganiserId: formData?.eventOrg?.value ? formData?.eventOrg?.value : getSGBCAccreditedCpdEventDetailByIdData?.eventOrganiserId,
      Eventstartdate: formData.eventStartDate,
      CertificateofAttendance: fileUploadData?.fileName ? fileUploadData?.fileName : getSGBCAccreditedCpdEventDetailByIdData.certificateofAttendance,
      DurationofEvent: selectedDuration,

      CreatedBy: 115,
      CreatedAppId: 1,
      CPDSubmissionId: getSGBCAccreditedCpdEventDetailByIdData.cpdSubmissionId,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }
    handleSubmitForm(request);
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getSGBCAccreditedCpdEventDetailByIdData.cpdSubmissionId,
      gmapMemberId: getSGBCAccreditedCpdEventDetailByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };

  const handleApproveButton = () => {
    const formData = accreditedCpdEventsRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getSGBCAccreditedCpdEventDetailByIdData.cpdSubmissionId,
      gmapMemberId: getSGBCAccreditedCpdEventDetailByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };

  console.log({ props })
  return (
    <React.Fragment>
      {getSGBCAccreditedCpdEventDetailByIdData ? <div className="speaking-teaching-engagement">
        {/* <div className="cpd-submission-description">
          <p>
            CPD points for all SGBC-accredited events (webinars, conferences, seminars, courses, learning journeys, etc) will be credited into the respective GMAPs' accounts according to the CPD attendance sheet that has been verified and submitted by the event organiser.
          </p>
          <p>For missing CPD records, please wait at least 2 weeks after the event before proceeding with self-submission for SGBC-accredited events.</p>
          <p>For any clarification on missing CPD records, please contact SGBC via email at: GMAP@sgbc.sg.</p>
        </div> */}
        <div className='common-project-info-main card-section card-section-left'>
          <div className='row gx-5'>
            {true && <FormCreator ref={accreditedCpdEventsRef} {...accreditedCpdEventsForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />}
          </div>
        </div>
        <div className='row'>
          <div className='common-custom-pagination mt-5'>
            <div>
              <Buttons
                buttonText="Save"
                buttonTypeClassName="outline-btn"
                onClick={handleSaveButton}
                isDisable={props?.isPastCPD}
                isLoading={isLoadingForm}
              />
            </div>
            <div className='d-flex column-gap-3'>
              <Buttons
                buttonText="Reject"
                buttonTypeClassName="red-btn"
                isDisable={props?.isPastCPD}
                onClick={handleRejectbutton}
              />
              <Buttons
                buttonText="Approve"
                buttonTypeClassName="success-btn"
                isDisable={props?.isPastCPD}
                onClick={handleApproveButton}
                isLoading={cpdSubmissionData?.approveIsLoading}
              />
            </div>
          </div>
        </div>
        {/*<div className='center-button'>
          <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
              onClick={handleSubmit}
            />
          </div>
        </div> */}
      </div>
        : <DataLoader />}
    </React.Fragment>
  );
});

export default SGBCAccreditedCpdEvents;
