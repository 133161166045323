
import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../../utils/API/responseMiddleware";
import { transformRequest } from "../../../../utils/API/requestMiddleware";
import { defautBaseQuery } from "../../../../utils/API/fetchBaseQuery";

const ViewEditEventApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'ViewEditEventApi',
    endpoints: (builder) => ({
        getEntryDetailListApi: builder.mutation({
            query: (details) => ({
                url: '/EventManagement/GetEntryDetailList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useGetEntryDetailListApiMutation,
} = ViewEditEventApi;

export default ViewEditEventApi;