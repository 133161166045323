import { ONE, THREE } from "../../../../../../data/constantVariables";
import { GridColumnType } from "../../../../../../data/gridColumnType";
import { greenMarkProjectConfig, greenMarkProjectData } from "../GreenMarkProject.Data";

const defaultCss = "col-md-6 mb-2";
const hiddenCss = "col-md-6 mb-2 d-none";
const defaultValidation = [{ type: "require" }];
const removeValidation = [];

const getFieldById = (id) => greenMarkProjectData.formFields.find((item) => item.id === id);

const getColumnByFieldName = (fieldName) => greenMarkProjectConfig.columns.findIndex((item) => item.fieldName === fieldName);

const setFieldStyleAndValidation = (field, containerCss, validation) => {
    field.style.containerCss = containerCss;
    field.validation = validation;
};
const setFieldSubLabel = (field, content) => {
    field.fieldSetting.subLabel = content
}

export const handleConditionBaseFields = (value, section) => {
    const awardRatingField = getFieldById("gmapAwardRatingId");
    const gmaapAwardRatingP1Field = getFieldById("gmaapAwardRatingP1Id");
    const gmaapAwardRatingP2Field = getFieldById("gmaapAwardRatingP2Id");
    const gmaapAwardRatingP3Field = getFieldById("gmaapAwardRatingP3Id");
    const gmapGreenMarkCategoryP1Field = getFieldById("gmapGreenMarkCategoryP1Id");
    const gmapGreenMarkCategoryP2Field = getFieldById("gmapGreenMarkCategoryP2Id");
    const gmaapGreenMarkCategoryP1Field = getFieldById("gmaapGreenMarkCategoryP1Id");
    const gmaapGreenMarkCategoryP2Field = getFieldById("gmaapGreenMarkCategoryP2Id");
    const gmaapGreenMarkCategoryP3Field = getFieldById("gmaapGreenMarkCategoryP3Id");
    const greenMarkProjectJobDescField = getFieldById("greenMarkProjectJobDesc");
    const greenMarkProjectSignificantFileField = getFieldById("greenMarkProjectSignificantFile");
    const formMainTitle = getFieldById("greenMarkProjectInformationTitle");

    if (section === "gmapPathwayTypeID") {
        setFieldStyleAndValidation(awardRatingField, (value === 1 || value === 2) ? defaultCss : hiddenCss, (value === 1 || value === 2) ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmapGreenMarkCategoryP1Field, value === 1 ? defaultCss : hiddenCss, value === 1 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmapGreenMarkCategoryP2Field, value === 2 ? defaultCss : hiddenCss, value === 2 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmaapAwardRatingP1Field, hiddenCss, removeValidation);
        setFieldStyleAndValidation(gmaapAwardRatingP2Field, hiddenCss, removeValidation);
        setFieldStyleAndValidation(gmaapAwardRatingP3Field, hiddenCss, removeValidation);
        setFieldStyleAndValidation(gmaapGreenMarkCategoryP1Field, hiddenCss, removeValidation);
        setFieldStyleAndValidation(gmaapGreenMarkCategoryP2Field, hiddenCss, removeValidation);
        setFieldStyleAndValidation(gmaapGreenMarkCategoryP3Field, hiddenCss, removeValidation);
        setFieldStyleAndValidation(greenMarkProjectJobDescField, hiddenCss, removeValidation);
        setFieldStyleAndValidation(greenMarkProjectSignificantFileField, hiddenCss, removeValidation);
    }

    else if (section === "gmaapPathwayTypeID") {
        setFieldStyleAndValidation(gmaapAwardRatingP1Field, value === 1 ? defaultCss : hiddenCss, value === 1 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmaapAwardRatingP2Field, value === 2 ? defaultCss : hiddenCss, value === 2 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmaapAwardRatingP3Field, value === 3 ? defaultCss : hiddenCss, value === 3 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmaapGreenMarkCategoryP1Field, value === 1 ? defaultCss : hiddenCss, value === 1 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmaapGreenMarkCategoryP2Field, value === 2 ? defaultCss : hiddenCss, value === 2 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmaapGreenMarkCategoryP3Field, value === 3 ? defaultCss : hiddenCss, value === 3 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(greenMarkProjectJobDescField, value === 3 ? "col-md-12 mb-3" : hiddenCss, value === 3 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(greenMarkProjectSignificantFileField, value === 3 ? "col-md-12 mb-3" : hiddenCss, value === 3 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(awardRatingField, hiddenCss, removeValidation);
        setFieldStyleAndValidation(gmapGreenMarkCategoryP1Field, hiddenCss, value === 3 ? removeValidation : removeValidation);
        setFieldStyleAndValidation(gmapGreenMarkCategoryP2Field, hiddenCss, value === 3 ? removeValidation : removeValidation);
        // Nikunj molGrid Column Add and remove
        const check1 = getColumnByFieldName("proofofJobDescriptionName");
        const check2 = getColumnByFieldName("proofofsignificantinvolvementName");

        if (value === THREE && check1 === -1 && check2 === -1) {
            let indexToAdd = getColumnByFieldName("clientLetterOfEndorsementName")
            greenMarkProjectConfig.columns.splice(indexToAdd + ONE, 0, {
                name: "Proof of Job Description",
                fieldName: "proofofJobDescriptionName",
                colType: GridColumnType.LINK,
                colSettings: {
                    isOpenNewTab: true,
                    url: "$proofofJobDescriptionLink",
                },
            },);

            indexToAdd = getColumnByFieldName("proofofJobDescriptionName")
            greenMarkProjectConfig.columns.splice(indexToAdd + ONE, 0, {
                name: "Proof of significant involvement",
                fieldName: "proofofsignificantinvolvementName",
                colType: GridColumnType.LINK,
                colSettings: {
                    isOpenNewTab: true,
                    url: "$proofofsignificantinvolvementLink",
                },
            },);
        }
        else if (value !== THREE && check1 !== -1 && check2 !== -1) {
            let indexToRemove = getColumnByFieldName("proofofJobDescriptionName");
            greenMarkProjectConfig.columns.splice(indexToRemove, ONE)
            indexToRemove = getColumnByFieldName("proofofsignificantinvolvementName");
            greenMarkProjectConfig.columns.splice(indexToRemove, ONE)
        }
    }
};


