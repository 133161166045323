import { FormFieldTypes } from "../../../../data/formFieldType";

export const CreateAccountManagementData = {
    name: "CreateAccountManagement",
    initialState: {
        firstname: '',
        profilePhotoFile: '',
        lastName: '',
        email: '',
        password: '',
        roleId: '',
    },
    formFields: [
        {
            id: "firstname",
            lable: "First Name",
            Field_Name: "First Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "firstname",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },

        {
            id: "lastName",
            lable: "Last Name",
            Field_Name: "Last Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "lastName",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "email",
            lable: "Email",
            Field_Name: "Email",
            fieldType: FormFieldTypes.INPUT,
            dataField: "email",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
            },
            validation: [{ type: "require" }, { type: "email" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "password",
            lable: "Password",
            Field_Name: "Password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "password",
            fieldSetting: {
                placeholder: "",
            },
            validation: [{ type: "require" }, { type: "password" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "roleId",
            lable: "Role",
            Field_Name: "Role",
            fieldType: FormFieldTypes.SELECT,
            dataField: "roleId",
            fieldSetting: {
                placeholder: " ",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        // {
        //     id: "profilePhotoFile",
        //     lable: 'Professional Profile Photo (please upload in jpg/png format max 2mb)',
        //     Field_Name: 'file',
        //     fieldType: FormFieldTypes.FILE,
        //     dataField: 'profilePhotoFile',
        //     fieldSetting: {
        //         placeholder: '',
        //         acceptedFiles: '.jpg, .png',
        //         imageCrop: true,
        //         viewImage: true
        //     },
        //     style: {
        //         containerCss: "col-md-6 mb-3"
        //     }
        // },

    ],
    formSetting: {
        isViewOnly: false
    }
}