import React, { useRef, useState } from "react"
import EventApprovalListFilterForm from "./component/EventApprovalList/EventApprovalListFilterForm";
import Buttons from "../../components/ui/button/Buttons";
import EventApprovalListTable from "./component/EventApprovalList/EventApprovalListTable";
import RegistrationApprovalDetails from "./component/EventApprovalList/component/RegistrationApprovalDetails";
import EventApprovalPage from "./component/EventApprovalList/component/RegistrationApprovalDetails";

const initState = {
    pageNo: 1,
    pageSize: 20,
    endPage: 100,
    EventName: "",
    totalCount: 0,
}

const EventRegistrationApprovalList = (props) => {
    const eventRegistrationListRef = useRef(null)
    const [paginationRequest, setPaginationRequest] = useState(initState);
    const handleSearchApplicationTask = () => {
        const taskListFormData = eventRegistrationListRef.current.getFormData();
        if (!eventRegistrationListRef.current) {
            return;
        }
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            EventName: taskListFormData.eventName,
        };
        setPaginationRequest(updatedPaginationRequest);
    };

    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <EventApprovalListFilterForm ref={eventRegistrationListRef} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end align-items-center">
                    <div className="two-button-fixes">
                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearchApplicationTask}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <EventApprovalListTable paginationReq={paginationRequest} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            {/* <EventApprovalPage paginationReq={paginationRequest} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventRegistrationApprovalList;
