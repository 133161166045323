import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { decryptAESID } from '../../../utils/Encryption';
import PersonalParticulars from './editComponent/PersonalParticulars/PersonalParticulars';
import AcademicQualification from './editComponent/AcademicQualification/AcademicQualification';
import CareerHistory from './editComponent/CareerHistory/CareerHistory';
import CertificateCourse from './editComponent/CertificateCourse/CertificateCourse';
import GreenMarkProject from './editComponent/GreenMarkProject/GreenMarkProject';
import Buttons from '../../../components/ui/button/Buttons';
import TinyEditor from '../../../components/ui/inputs/TinyEditor/TinyEditor';
import CenterModel from '../../../components/ui/centerModel/CenterModel';
import { Accordion } from 'react-bootstrap';
import CardSection from '../../../components/ui/card/CardSection';
import AccountInformation from './editComponent/AccountInformation/AccountInformation';
import { useAppealGMAPMemberApplicationMutation, useLazyGetGMAPAccountInformationByMemberIdQuery, useLazyGetGMAPMemberDetailByMemberIdQuery, useLazyGetGMAPMembershipByMemberIdQuery, useLazyGetLockUnockMemberInformationByIdQuery, useLockUnockMemberInformationMutation, useRejectGMAPMemberApplicationMutation, useSaveGMAPMemberClarificationDetailsMutation, useSetApplicationStatusMutation, useUpdateGMAPMemberDetailsMutation } from '../../../app/services/GMAP/editApplicationTaskListApi';
import { careerHistoryData } from './editComponent/CareerHistory/CareerHistory.data';
import { certificateCourseData } from './editComponent/CertificateCourse/CertificateCourse.Data';
import { greenMarkProjectData } from './editComponent/GreenMarkProject/GreenMarkProject.Data';
import { useLazyGetAllDropDownsForMemberDirectoryQuery, useLazyGetAllDropDownsQuery } from '../../../app/services/common/commonApi';
import PageLoader from '../../../components/ui/pageLoader/PageLoader';
import { AppIcons } from '../../../data/appIcons';
import SwalAlert from '../../../services/swalService/SwalService';
import { setAllDropDownField } from './editComponent/utils/dropdownUtils';
import { setFieldParameter } from './editComponent/utils/setEditFieldsUtils';
import GMAGenratePDF from '../gmapApplicationTasklist/component/GMAGenratePDF';
import ToastService from '../../../services/toastService/ToastService';
import ContinuingProfessionalDevelopment from '../EditIndividualMembersForm/component/ContinuingProfessionalDevelopment/ContinuingProfessionalDevelopment';
import { handleConditionBaseFields } from './editComponent/CertificateCourse/utils/ValidationBaseFields';

export const EditDiscretionaryApplicationDataContext = React.createContext(null);

const EditDiscretionaryRenewalTaskListApplication = () => {

    const navigate = useNavigate();
    const personalParticularsRef = useRef(null)
    const academicQualificationRef = useRef(null)
    const accountInformationFormRef = useRef(null)
    const currentEmpInfoRef = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenReject, setIsModalOpenReject] = useState(false);
    const [isModalOpenAppeal, setIsModalOpenAppeal] = useState(false);
    const { confirm, success, error } = SwalAlert();
    const { id, taskListId } = useParams();
    const editID = id ? decryptAESID(id, false) : 0
    const listId = taskListId ? decryptAESID(taskListId, false) : 0
    const [clarificationsComments, setClarificationsComments] = useState("");
    const [rejectComments, setRejectComments] = useState("");
    const [appealComments, setAppealComments] = useState("");
    const [newMemberData, setNewMemberData] = useState({
        applicationTypeId: null,
        academicCertificate: null,
        certificateMarketRefresherCourse: null,
        GMAPMemberSubscriptionId: null,
        profilePhotoFile: null,
        profilePhotoFileName: null,
        academicQualificationCertificationFileName: null,
        academicQualificationCertificationFile: null,
        isCurrentlyEmployed: null
    });
    const [accordionItems, setAccordionItems] = useState([
        // { id: 5, header: "Continuing Professional Development", component: <ContinuingProfessionalDevelopment />, locked: true },
        { id: 0, header: "Personal Particulars", component: <PersonalParticulars ref={personalParticularsRef} />, locked: false },
        { id: 1, header: "Academic Qualification", component: <AcademicQualification ref={academicQualificationRef} />, locked: false },
        { id: 2, header: "Career History", component: <CareerHistory ref={currentEmpInfoRef} />, locked: false },
        { id: 3, header: "Course Requirement", component: <CertificateCourse />, locked: false },
        { id: 4, header: "Green Mark Project", component: <GreenMarkProject />, locked: false },
        // { id: 6, header: "GMAP Membership", component: <GmapMembership />, locked: false },

    ]);
    const contextValue = {
        newMemberData,
        setContextData: (data) => setNewMemberData(data)
    };
    const [getMemberDetailById,
        { isSuccess: isSuccess,
            isLoading: isFetching,
            data: memberDetailsResponse },
    ] = useLazyGetGMAPMemberDetailByMemberIdQuery();

    const [getLockTabStatus,
        { isSuccess: isSuccessLock,
            isLoading: isFetchingLock,
            data: tabLockStatus },
    ] = useLazyGetLockUnockMemberInformationByIdQuery()

    const [getAccountInfoSectionDropDown,
        { isSuccess: isSuccessDD,
            isLoading: isLoadingDD,
            data: getAccDDResponse },
    ] = useLazyGetAllDropDownsQuery()

    const [getAllDropDowns,
        { isSuccess: isGetAllDropDownFetched,
            isLoading: isGetDropDownFetching,
            data: allDropDownResponse },
    ] = useLazyGetAllDropDownsForMemberDirectoryQuery();

    const [getAccInfoById,
        { isSuccess: isSuccessAccInfo,
            isLoading: isFetchingAccInfo,
            data: accountInformationResponse },
    ] = useLazyGetGMAPAccountInformationByMemberIdQuery();

    const [getMembershipAccDetails,
        { isSuccess: isSuccessGetMembership,
            isLoading: isFetchingGetMembership,
            data: membershipAccResponse },
    ] = useLazyGetGMAPMembershipByMemberIdQuery();
    const [setApplicationStatus,
        { isSuccess: isSuccessApp,
            isLoading: isFetchingApp,
            data: applicationStatusResponse },
    ] = useSetApplicationStatusMutation();
    const [LockUnlockTab,
        { isSuccess: isSuccessTab,
            isLoading: isFetchingAppTab,
            data: lockUnlockTabResponse },
    ] = useLockUnockMemberInformationMutation();

    const [saveClarificationDetails,
        { isSuccess: isSuccessCLR,
            isLoading: isFetchingAppCLR,
            data: clarificationApplicationResponse },
    ] = useSaveGMAPMemberClarificationDetailsMutation();

    const [appealApplication,
        { isSuccess: isSuccessAppeal,
            isLoading: isLoadingAppeal,
            data: appealApplicationResponse },
    ] = useAppealGMAPMemberApplicationMutation();

    const [updateGMAPTaskList,
        { isSuccess: isSuccessUpdate,
            isLoading: isFetchingAppUpdate,
            data: updateGMAPTaskListResponse },
    ] = useUpdateGMAPMemberDetailsMutation();

    const [rejectApplication,
        { isSuccess: isSuccessReject,
            isLoading: isFetchingAppReject,
            data: rejectApplicationResponse },
    ] = useRejectGMAPMemberApplicationMutation();

    useEffect(() => {
        getLockTabStatus(editID);
        resetTableHistory(editID);
    }, [editID])



    useEffect(() => {
        if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
            setAllDropDownField(allDropDownResponse);
        }
    }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

    useEffect(() => {
        if (!isLoadingDD && isSuccessDD && getAccDDResponse) {
            setAllDropDownField(getAccDDResponse, "signupDropdown");
        }
    }, [isLoadingDD, isSuccessDD, getAccDDResponse]);

    useEffect(() => {
        if (!isFetching && isSuccess && memberDetailsResponse) {
            const updatedData = {
                ...newMemberData,
                applicationTypeId: memberDetailsResponse.gmapApplicationTypeId,
                profilePhotoFile: memberDetailsResponse?.professionalProfilPhoto,
                profilePhotoFileName: memberDetailsResponse?.professionalProfilPhotoName,
                academicQualificationCertificationFile: memberDetailsResponse?.academicCertificate,
                academicQualificationCertificationFileName: memberDetailsResponse?.academicCertificateName,
                academicCertificate: memberDetailsResponse?.academicCertificate,
                isCurrentlyEmployed: memberDetailsResponse?.isCurrentlyEmployed,
            };
            setNewMemberData(updatedData);
            setFieldParameter(memberDetailsResponse, "memberDetails");
            if (memberDetailsResponse?.gmapApplicationTypeId === 1) {
                handleConditionBaseFields(2, 'gmaPathwayTypeID')
            }
        }
    }, [isFetching, isSuccess, memberDetailsResponse]);

    useEffect(() => {
        if (!isFetchingGetMembership && isSuccessGetMembership && membershipAccResponse) {
            setFieldParameter(membershipAccResponse, "membershipAccResponse");
        }
    }, [isFetchingGetMembership, isSuccessGetMembership, membershipAccResponse]);

    useEffect(() => {
        if (!isFetchingAccInfo && isSuccessAccInfo && accountInformationResponse) {
            setNewMemberData({
                ...newMemberData, GMAPMemberSubscriptionId: accountInformationResponse.gmapMemberSubscriptionId
            });
            setFieldParameter(accountInformationResponse, "accountInformation");
        }
    }, [isFetchingAccInfo, isSuccessAccInfo, accountInformationResponse]);

    useEffect(() => {
        if (!isFetchingApp && isSuccessApp && applicationStatusResponse) {
            if (applicationStatusResponse.subscriptionStatusId === 1) {
                success("", "Application Approved Successfully")
            }
            else {
                error("Subscription status is not becaome Active ", "Contact SGBC");
            }
        }
    }, [isFetchingApp, isSuccessApp, applicationStatusResponse]);

    useEffect(() => {
        if (!isFetchingAppUpdate && isSuccessUpdate && updateGMAPTaskListResponse) {
            success("", "Application Updated Successfully ")
        }
    }, [isFetchingAppUpdate, isSuccessUpdate, updateGMAPTaskListResponse]);

    useEffect(() => {
        if (!isFetchingAppReject && isSuccessReject && rejectApplicationResponse) {
            success("", "Application Reject Successfully ")
            setIsModalOpenReject(!isModalOpenReject)
            getAccInfoById(editID);
        }
    }, [isFetchingAppReject, isSuccessReject, rejectApplicationResponse]);

    useEffect(() => {
        if (!isLoadingAppeal && isSuccessAppeal && appealApplicationResponse) {
            success("", "Application Appeal Successfully ")
            setIsModalOpenAppeal(!isModalOpenAppeal)
            getAccInfoById(editID);
        }
    }, [isLoadingAppeal, isSuccessAppeal, appealApplicationResponse]);

    useEffect(() => {
        if (!isFetchingAppCLR && isSuccessCLR && clarificationApplicationResponse) {
            success("The clarification email has been successfully sent.", "Sent Email")
            navigate(`/gmapApplicationTaskListDiscretionaryRenewal`)
        }
    }, [isFetchingAppCLR, isSuccessCLR, clarificationApplicationResponse]);

    useEffect(() => {
        if (!isFetchingAppTab && isSuccessTab && lockUnlockTabResponse) {
            setAccordionItems(prevItems => prevItems.map(item =>
                item.header === lockUnlockTabResponse.identities ? { ...item, locked: lockUnlockTabResponse.locked } : item
            ));
            ToastService.success(`${lockUnlockTabResponse.identities} has been successfully ${lockUnlockTabResponse.locked ? "Locked." : "Unlocked."}`)
        }
    }, [isFetchingAppTab, isSuccessTab, lockUnlockTabResponse]);

    useEffect(() => {
        if (!isFetchingLock && isSuccessLock && tabLockStatus) {
            setAccordionItems(prevItems => prevItems.map((item, index) => {
                return item.header === tabLockStatus[index].identities ? { ...item, locked: tabLockStatus[index].locked || false } : item
            }
            ));
        }
    }, [isFetchingLock, isSuccessLock, tabLockStatus]);

    const resetTableHistory = (id) => {
        const tableIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
        const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
        const greenMarkProjectIndex = greenMarkProjectData.formFields.findIndex(field => field.id === "greenMarkProjectTable");
        if (tableIndex !== -1) {
            const updatedFormData = { ...careerHistoryData };
            updatedFormData.formFields[tableIndex].fieldSetting.dataSource = null;
        }
        if (courseHistoryIndex !== -1) {
            const updatedFormData = { ...certificateCourseData };
            updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = null;
        }
        if (greenMarkProjectIndex !== -1) {
            const updatedFormData = { ...greenMarkProjectData };
            updatedFormData.formFields[greenMarkProjectIndex].fieldSetting.dataSource = null;
        }
        getAccountInfoSectionDropDown();
        getAllDropDowns();

        if (id) {
            getAccInfoById(id);
            getMembershipAccDetails(id);
            getMemberDetailById(id);
        }
    }

    const handleUpdate = () => {
        let personalParticularData = personalParticularsRef.current.getFormData();
        let academicQualificationData = academicQualificationRef.current.getFormData();
        let accountInformationData = accountInformationFormRef.current.getFormData();
        if (!personalParticularData || !academicQualificationData || !accountInformationData) {
            return false
        }

        let request = {
            GMAPMemberId: editID,
            IsAppeal: accountInformationData?.appeal || false,
            VoidLateFees: accountInformationData?.voidLateFee || false,
            IsQualify: accountInformationData?.qualify || false,
            GMAPSalutationId: personalParticularData?.salutationsID,
            FullName: personalParticularData?.fullName,
            PrimaryEmail: personalParticularData?.primaryEmail,
            SecondaryEmail: personalParticularData?.secondaryEmail,
            DateOfBirth: personalParticularData?.dateOfBirthDate,
            MobilePhone: personalParticularData?.mobilePhone,
            IsGenderMale: personalParticularData?.isGenderMale === "male" ? true : false,
            GMAPNationalityId: personalParticularData?.nationalityID?.value ? personalParticularData?.nationalityID?.value : personalParticularData?.nationalityID,
            AddressLine1: personalParticularData?.addressLine1,
            AddressLine2: personalParticularData?.addressLine2,
            AddressLine3: personalParticularData?.addressLine3,
            PostalCode: personalParticularData?.postalCode,
            CountryofResidenceId: personalParticularData?.countryOfResID?.value ? personalParticularData?.countryOfResID?.value : personalParticularData?.countryOfResID,
            ProfessionalProfileDescription: personalParticularData?.comments,
            ProfessionalProfilPhoto: newMemberData?.profilePhotoFile || null,
            ProfessionalProfilPhotoName: newMemberData?.profilePhotoFileName || null,
            LinkedInAddressOrProfessionalHomepage: personalParticularData?.linkedinAdd,
            GMAPCompetenciesId: personalParticularData?.competenciesID?.join(','),
            GMAPOtherProfessionalQualificationId: personalParticularData?.otherProfessionQualificationID?.join(','),
            GMAPHighestRelevantQualificationId: academicQualificationData?.highestQualificationID?.value ? academicQualificationData?.highestQualificationID?.value : academicQualificationData?.highestQualificationID,
            FieldOfStudy: academicQualificationData?.fieldOfStudy,
            GraduateYearId: academicQualificationData?.graduateYearID?.value ? academicQualificationData?.graduateYearID?.value : academicQualificationData?.graduateYearID,
            AcademicInstitution: academicQualificationData?.academicQualification,
            AcademicCertificate: newMemberData?.academicCertificate || null,
            AcademicCertificateName: newMemberData?.academicQualificationCertificationFileName || null,
            IsPendingExpiry: accountInformationData?.pendingExpiry || false
        }
        updateGMAPTaskList(request)
    };
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }
    const openModalRejectApp = () => {
        setIsModalOpenReject(!isModalOpenReject);
    }
    const openModalAppeal = () => {
        setIsModalOpenAppeal(!isModalOpenAppeal);
    }
    const handleSendClearfication = () => {
        let request = {
            GMAPMemberId: editID,
            ApplicationTasklistId: listId,
            ClarificationDetails: clarificationsComments,
        }
        confirm("Send Clarification?", "Are you sure you want to send clarification?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    saveClarificationDetails(request);
                }
            });
    }

    const handleBack = () => {
        navigate(`/gmapApplicationTaskListDiscretionaryRenewal`)
    }

    const toggleTabLock = (header, locked) => {
        let request = {
            GMAPMemberId: editID,
            Locked: !locked,
            Identities: header,
        }
        confirm(`${!locked ? 'Locked Tab?' : 'Unlocked Tab?'}`, `Are you sure you want to ${!locked ? 'Locked?' : 'Unlocked?'}`, "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    LockUnlockTab(request)

                }
            });
    };
    const handleAcceptApp = () => {
        confirm("Lock all sections?", '', "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    // const isGMAApplication = memberDetailsResponse.gmapApplicationTypeId === 1;
                    const isGMAApplication = true;
                    let filteredAccordionItems = accordionItems;

                    // if (isGMAApplication) {

                    //     filteredAccordionItems = accordionItems.filter(item => item.id !== 4);
                    //     filteredAccordionItems.every(lock => lock.locked = true)
                    // }
                    filteredAccordionItems.every(lock => lock.locked = true)

                    let arrObjWithoutComponent = filteredAccordionItems.map(({ component, ...rest }) => rest);

                    let allLocked = arrObjWithoutComponent.every(tab => tab.locked === true);
                    if (allLocked) {
                        let request = {
                            GMAPMemberSubscriptionId: newMemberData.GMAPMemberSubscriptionId,
                            ApplicationTasklistId: listId,
                            GMAPMemberId: editID,
                            UpdatedBy: 115,
                            UpdatedAppId: 1,
                            IsLockPersonalParticulars: true,
                            IsLockAcademicQualification: true,
                            IsLockCareerHistory: true,
                            IsLockCourseRequirement: true,
                            IsLockGreenMarkProjectInformation: true,
                            // IsLockGMAPMembership:true,
                            // IsLockContinuingProfessionalDevelopment:true 
                        }
                        setApplicationStatus(request);
                    } else {
                        error("Please ensure all tabs are locked before submitting the application", "")
                    }

                }
            });


    }
    const handleRejectApp = () => {
        if (rejectComments === "" || rejectComments === undefined || rejectComments === null) {
            return error("The comments field for rejecting the application is mandatory and must be filled out.", "error")
        }
        let request = {
            GMAPMemberId: editID,
            IsRejected: true,
            ApplicationTasklistId: listId,
            SendRejectionReason: rejectComments

        }
        confirm("Reject Application?", "Are you sure you want to Reject?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    rejectApplication(request);
                }
            });
    }
    const handleAppeal = () => {
        if (appealComments === "" || appealComments === undefined || appealComments === null) {
            return error("The comments field for Appeal the application is mandatory and must be filled out.", "error")
        }
        let request = {
            GMAPMemberId: editID,
            IsAppeal: true,
            ApplicationTasklistId: listId,
            SendAppealReason: appealComments
        }
        confirm("Appeal Application?", "Are you sure you want to Appeal?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    appealApplication(request);
                }
            });
    }
    const tabs = parseInt(memberDetailsResponse?.gmapApplicationTypeId) === 1 ? accordionItems?.filter(obj => obj.id !== 4) : accordionItems;

    const handleTinyOnChange = (text, section) => {
        switch (section) {
            case "Clarifications":
                setClarificationsComments(text);
                break;
            case "Reject":
                setRejectComments(text);
                break;
            case "Appeal":
                setAppealComments(text);
                break;
            default:
                break;
        }

    };
    return (
        <>
            {/* <div ref={screenShotRef}> */}
            <CardSection cardTitle="Account Information" rightButton={true} rightButtonComponent={<GMAGenratePDF type={memberDetailsResponse?.gmapApplicationTypeId} />} isRightButtonComponent={true}>
                <div className="row align-items-end mb-2">
                    <div className="col-10">
                        {isSuccessAccInfo ? <AccountInformation ref={accountInformationFormRef} /> : <PageLoader />}
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <div className="reset-password-button">
                            <Buttons
                                buttonText="Reset Password"
                                buttonTypeClassName="success-btn"
                            />
                        </div>
                    </div>
                </div>
            </CardSection>
            <CardSection cardTitle="CPD Information">
                <div className="row align-items-end mb-2">
                    <div className="col-lg-12">
                        <ContinuingProfessionalDevelopment isViewButton={false} />
                    </div>
                </div>
            </CardSection>
            <div className="common-accordian-view-design">
                {(isSuccess && !isFetching && memberDetailsResponse?.gmapApplicationTypeId) ?
                    <EditDiscretionaryApplicationDataContext.Provider value={contextValue}>
                        <Accordion alwaysOpen defaultActiveKey="3">
                            {tabs?.map((item, index) => {
                                return <Accordion.Item key={index} eventKey={index.toString()}>
                                    <Accordion.Header>
                                        {item.id !== 5 && <Buttons
                                            imagePath={item.locked ? AppIcons.lockIcon : AppIcons.unLockIcon}
                                            buttonTypeClassName="btn-drop"
                                            textWithIcon={true}
                                            onClick={() => toggleTabLock(item.header, item.locked)}
                                        />}
                                        {item.header}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            {item.component}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            })}
                        </Accordion>
                    </EditDiscretionaryApplicationDataContext.Provider> :
                    <PageLoader />
                }

            </div>
            {/* </div> */}
            <div className="common-custom-pagination edittasklist-btn-width d-flex flex-nowrap">
                <Buttons
                    buttonText="Accept & Approve Application"
                    buttonTypeClassName="success-btn btn-sm mx-2"
                    isLoading={isFetchingApp}
                    onClick={handleAcceptApp}
                    isDisable={!isSuccess}
                    locked={true}
                />
                <Buttons
                    buttonText="Appeal"
                    buttonTypeClassName="back-btn btn-sm mx-2"
                    onClick={openModalAppeal}

                />
                <Buttons
                    buttonText="Send Clarifications"
                    buttonTypeClassName="back-btn btn-sm mx-2"
                    onClick={openModal}
                />
                <Buttons
                    buttonText="Reject Application"
                    buttonTypeClassName="red-btn btn-sm mx-2"
                    isLoading={isFetchingAppReject}
                    onClick={openModalRejectApp}
                />
                <Buttons
                    buttonText="Update"
                    buttonTypeClassName="outline-btn btn-sm mx-2"
                    isLoading={isFetchingAppUpdate}
                    onClick={handleUpdate}
                />
                <Buttons
                    buttonText="Exit Application"
                    buttonTypeClassName="red-btn btn-sm mx-2"
                    onClick={handleBack}
                />
            </div>
            {isModalOpen &&
                <CenterModel
                    modelTitle={'Send Clarifications'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Clarifications")}
                    />
                    <Buttons
                        buttonText="Send Email"
                        buttonTypeClassName="outline-btn mt-4"
                        isLoading={isFetchingAppCLR}
                        onClick={handleSendClearfication}
                    />
                </CenterModel>
            }
            {isModalOpenReject &&
                <CenterModel
                    modelTitle={'Reject Application'}
                    show={"test"}
                    handleClose={openModalRejectApp}
                    onUpload={openModalRejectApp}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Reject")}
                    />
                    <Buttons
                        buttonText="Reject Application"
                        buttonTypeClassName="red-btn mt-5"
                        isLoading={isFetchingAppReject}
                        onClick={handleRejectApp}
                    />
                </CenterModel>
            }
            {isModalOpenAppeal &&
                <CenterModel
                    modelTitle={'Appeal Application'}
                    show={"test"}
                    handleClose={openModalAppeal}
                    onUpload={openModalAppeal}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Appeal")}
                    />
                    <Buttons
                        buttonText="Appeal Application"
                        buttonTypeClassName="outline-btn mt-5"
                        isLoading={isLoadingAppeal}
                        onClick={handleAppeal}
                    />
                </CenterModel>
            }
        </>
    )
}

export default EditDiscretionaryRenewalTaskListApplication
