import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../../utils/API/responseMiddleware";
import { transformRequest } from "../../../../utils/API/requestMiddleware";
import { defautBaseQuery } from "../../../../utils/API/fetchBaseQuery";

const eventQuestionAnswerApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'eventQuestionAnswerApi',
    endpoints: (builder) => ({
        getFormQuestionAnswerEntryDetailList: builder.mutation({
            query: (details) => ({
                url: '/EventManagement/GetFormQuestionAnswerEntryDetailList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAllFormQuestionAnswerListByEventId: builder.query({
            query: (details) => ({
                url: `/EventManagement/GetAllFormQuestionAnswerListByEventId?id=${Number(details)}`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useGetFormQuestionAnswerEntryDetailListMutation,
    useLazyGetAllFormQuestionAnswerListByEventIdQuery
} = eventQuestionAnswerApi;

export default eventQuestionAnswerApi;