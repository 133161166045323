import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, useParams } from "react-router-dom";
import CardSection from "../../../components/ui/card/CardSection";
import Buttons from "../../../components/ui/button/Buttons";
import PersonalParticulars from "./component/PersonalParticulars/PersonalParticulars";
import AcademicQualification from "./component/AcademicQualification/AcademicQualification";
import AccountInformation from "./component/AccountInformation/AccountInformation";
import { useGmpAccountInformationMutation, useLazyGetGMAPAccountInformationByMemberIdQuery, useLazyGetGMAPMemberDetailByMemberIdQuery, useLazyGetGMAPMembershipByMemberIdQuery, useLazyGetIndividualDetailByMemberIdQuery, useUpdateGMAPMemberDetailsMutation } from "../../../app/services/GMAP/editApplicationTaskListApi";
import { useLazyGetAllDropDownsForMemberDirectoryQuery, useLazyGetAllDropDownsQuery } from "../../../app/services/common/commonApi";
import { setAllDropDownField } from "./component/utils/dropdownUtils";
import { setCourseRequirementGrid, setFieldParameter } from "./component/utils/setEditFieldsUtils";
import CareerHistory from "./component/CareerHistory/CareerHistory";
import CertificateCourse from "./component/CertificateCourse/CertificateCourse";
import GreenMarkProject from "./component/GreenMarkProject/GreenMarkProject";
import { EditApplicationDataContext } from "../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import { ZERO } from "../../../data/constantVariables";
import GmapMembership from "./component/GmapMembership/GmapMembership";
import PageLoader from "../../../components/ui/pageLoader/PageLoader";
import { AppIcons } from "../../../data/appIcons";
import SwalAlert from "../../../services/swalService/SwalService";
import { careerHistoryData } from "./component/CareerHistory/CareerHistory.data";
import { certificateCourseConfig, certificateCourseData } from "./component/CertificateCourse/CertificateCourse.Data";
import { greenMarkProjectConfig, greenMarkProjectData } from "./component/GreenMarkProject/GreenMarkProject.Data";
import { decryptAESID } from "../../../utils/Encryption";
import { useLazyGetGMAPAccountInformationInIndividualByMemberIdQuery } from "../../../app/services/GMAP/gmapViewIndividualMember";
import FinancialRecord from "../viewIndividualMembers/component/finacialRecord/FinancialRecord";
import GMAGenratePDF from "../gmapApplicationTasklist/component/GMAGenratePDF";
import ContinuingProfessionalDevelopment from "./component/ContinuingProfessionalDevelopment/ContinuingProfessionalDevelopment";
import PendingApplications from "../gmapApplicationTasklist/component/PendingApplications";
import CertificateRecord from "../viewIndividualMembers/component/certificateRecord/CertificateRecord";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import UpdatePasswordForm from "./component/ResetPassword/UpdatePasswordForm";

const EditIndividualMembersForm = () => {
  const navigate = useNavigate();
  const accountInformationRef = useRef(null)
  const personalParticularsRef = useRef(null)
  const academicQualificationRef = useRef(null)
  const currentEmpInfoRef = useRef(null)
  const { id } = useParams();
  const editID = id ? decryptAESID(id, false) : 0
  const { confirm, success, error } = SwalAlert();
  const [isModelOpen, setIsModalOpen] = useState(false);

  const [modelDetail, setModelDetail] = useState({
    value: 0,
    label: "",
    component: null,
  });

  const [newMemberData, setNewMemberData] = useState({
    applicationTypeId: null,
    certificateMarketRefresherCourse: null,
    academicQualificationCertificationFileName: null,
    academicCertificate: null,
    profilePhotoFileName: null,
    profilePhotoFile: null,
  });

  const [getMemberDetailById,
    { isSuccess: isSuccess,
      isFetching: isFetching,
      data: memberDetailsResponse },
  ] = useLazyGetIndividualDetailByMemberIdQuery();

  const [getAccInfoById,
    { isSuccess: isSuccessAccInfo,
      isFetching: isFetchingAccInfo,
      data: accountInformationResponse },
  ] = useLazyGetGMAPAccountInformationInIndividualByMemberIdQuery();

  const [getMembershipAccDetails,
    { isSuccess: isSuccessGetMembership,
      isFetching: isFetchingGetMembership,
      data: membershipAccResponse },
  ] = useLazyGetGMAPMembershipByMemberIdQuery();

  const [getAllDropDowns,
    { isSuccess: isGetAllDropDownFetched,
      isFetching: isGetDropDownFetching,
      data: allDropDownResponse },
  ] = useLazyGetAllDropDownsForMemberDirectoryQuery();

  const [getAccountInfoSectionDropDown,
    { isSuccess: isSuccessDD,
      isLoading: isLoadingDD,
      data: getAccDDResponse },
  ] = useLazyGetAllDropDownsQuery()

  const [updateGMAPTaskList,
    { isSuccess: isSuccessUpdate,
      isLoading: isFetchingAppUpdate,
      data: updateGMAPTaskListResponse },
  ] = useUpdateGMAPMemberDetailsMutation();

  const [updateGmpAccountInformation,
    { isSuccess: isSuccessGmpAccount,
      isLoading: isFetchingGmpAccount,
      data: updateGmpAccountResponse },
  ] = useGmpAccountInformationMutation();

  const contextValue = {
    newMemberData,
    setContextData: (data) => setNewMemberData(data)
  };
  useEffect(() => {
    if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
      setAllDropDownField(allDropDownResponse);
    }
  }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

  useEffect(() => {
    if (!isLoadingDD && isSuccessDD && getAccDDResponse) {
      setAllDropDownField(getAccDDResponse, "signupDropdown");
    }
  }, [isLoadingDD, isSuccessDD, getAccDDResponse]);

  useEffect(() => {
    if (editID > 0) {
      resetTableHistory(editID);
    }
  }, [editID])
  const resetTableHistory = (id) => {
    const tableIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
    const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
    const greenMarkProjectIndex = greenMarkProjectData.formFields.findIndex(field => field.id === "greenMarkProjectTable");
    if (tableIndex !== -1) {
      const updatedFormData = { ...careerHistoryData };
      updatedFormData.formFields[tableIndex].fieldSetting.dataSource = null;
    }
    if (courseHistoryIndex !== -1) {
      const updatedFormData = { ...certificateCourseData };
      updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = null;
    }
    if (greenMarkProjectIndex !== -1) {
      const updatedFormData = { ...greenMarkProjectData };
      updatedFormData.formFields[greenMarkProjectIndex].fieldSetting.dataSource = null;
    }

    getAccountInfoSectionDropDown();
    getAllDropDowns();
    if (id) {
      getMemberDetailById(id);
      getAccInfoById(id);
      getMembershipAccDetails(id);

    }

  }
  useEffect(() => {
    if (!isFetching && isSuccess && memberDetailsResponse) {
      setFieldParameter(memberDetailsResponse, "memberDetails");
      setNewMemberData(prev => ({
        ...prev, applicationTypeId: memberDetailsResponse.gmapApplicationTypeId,
        profilePhotoFile: memberDetailsResponse?.professionalProfilPhoto,
        profilePhotoFileName: memberDetailsResponse?.professionalProfilPhotoName,
        academicCertificate: memberDetailsResponse?.academicCertificate,
        academicQualificationCertificationFileName: memberDetailsResponse?.academicCertificateName,
      }));
      // setNewMemberData({
      //   ...newMemberData,
      //   applicationTypeId: memberDetailsResponse.gmapApplicationTypeId,
      //   profilePhotoFile: memberDetailsResponse?.professionalProfilPhoto,
      //   profilePhotoFileName: memberDetailsResponse?.professionalProfilPhotoName,
      //   academicCertificate: memberDetailsResponse?.academicCertificate,
      //   academicQualificationCertificationFileName: memberDetailsResponse?.academicCertificateName,
      // });

      setCourseRequirementGrid(memberDetailsResponse.gmapApplicationTypeId);
    }
  }, [isFetching, isSuccess, memberDetailsResponse]);

  useEffect(() => {
    if (!isFetchingGetMembership && isSuccessGetMembership && membershipAccResponse) {
      setFieldParameter(membershipAccResponse, 'membershipAccResponse');
    }
  }, [isFetchingGetMembership, isSuccessGetMembership, membershipAccResponse]);

  useEffect(() => {
    if (!isFetchingAccInfo && isSuccessAccInfo && accountInformationResponse) {
      setFieldParameter(accountInformationResponse, "accountInformation");
    }
  }, [isFetchingAccInfo, isSuccessAccInfo, accountInformationResponse]);

  useEffect(() => {
    if (!isFetchingAppUpdate && isSuccessUpdate && updateGMAPTaskListResponse) {
      success("", "Application Updated Successfully ")
    }
  }, [isFetchingAppUpdate, isSuccessUpdate, updateGMAPTaskListResponse]);

  const handleBack = () => {
    navigate(`/viewIndividualMembers`)
  }
  const handleUpdate = () => {
    let accountInformationData = accountInformationRef?.current?.getFormData();
    let personalParticularData = personalParticularsRef?.current?.getFormData();
    let academicQualificationData = academicQualificationRef?.current?.getFormData();
    // let currentEmpData = currentEmpInfoRef?.current?.getFormData();
    let accountInfo = {
      GmapmemberId: editID,
      IsAppeal: accountInformationData?.appeal || false,
      IsQualify: accountInformationData?.qualify || false,
      IsVoidLateFee: accountInformationData?.voidLateFee || false,
      IsPendingExpiry: accountInformationData?.pendingExpiry || false,
    }

    let request = {
      GMAPMemberId: editID,
      GMAPSalutationId: personalParticularData?.salutationsID?.value || personalParticularData?.salutationsID,
      FullName: personalParticularData?.fullName,
      PrimaryEmail: personalParticularData?.primaryEmail,
      SecondaryEmail: personalParticularData?.secondaryEmail,
      DateOfBirth: personalParticularData?.dateOfBirthDate,
      MobilePhone: personalParticularData?.mobilePhone,
      IsGenderMale: personalParticularData?.isGenderMale === "male" ? true : false,
      GMAPNationalityId: personalParticularData?.nationalityID?.value || personalParticularData?.nationalityID,
      AddressLine1: personalParticularData?.addressLine1,
      AddressLine2: personalParticularData?.addressLine2,
      AddressLine3: personalParticularData?.addressLine3,
      PostalCode: personalParticularData?.postalCode,
      CountryofResidenceId: personalParticularData?.countryOfResID?.value || personalParticularData?.countryOfResID,
      ProfessionalProfileDescription: personalParticularData?.comments,
      ProfessionalProfilPhotoName: newMemberData?.profilePhotoFileName || null,
      ProfessionalProfilPhoto: newMemberData?.profilePhotoFile,
      LinkedInAddressOrProfessionalHomepage: personalParticularData?.linkedinAdd,
      GMAPCompetenciesId: personalParticularData?.competenciesID?.join(',') || null,
      GMAPOtherProfessionalQualificationId: personalParticularData?.otherProfessionQualificationID?.join(',') || null,
      GMAPHighestRelevantQualificationId: academicQualificationData?.highestQualificationID?.value || academicQualificationData?.highestQualificationID,
      FieldOfStudy: academicQualificationData?.fieldOfStudy,
      GraduateYearId: academicQualificationData?.graduateYearID?.value || academicQualificationData?.graduateYearID,
      AcademicInstitution: academicQualificationData?.academicQualification,
      AcademicCertificate: newMemberData?.academicCertificate,
      AcademicCertificateName: newMemberData?.academicQualificationCertificationFileName || null,
      // IsCurrentlyEmployed: currentEmpData?.isUnemployed ? true : false,
      // CurrentCompanyName: currentEmpData?.CurrentCompanyName || null,
      // CurrentDesignation: currentEmpData?.CurrentDesignation || null,
      // CurrentPeriodFrom: currentEmpData?.CurrentPeriodFrom || null,
      // CurrentPeriodTo: currentEmpData?.CurrentPeriodTo || null,
      // IsCurrent: true || null,
      // GMAPProfessionTypeId: currentEmpData?.GMAPProfessionTypeId?.value || currentEmpData?.GMAPProfessionTypeId,
    }
    updateGMAPTaskList(request)
    updateGmpAccountInformation(accountInfo)
  };

  const getTabs = () => {
    const tab = [
      { id: 1, header: "Personal Particulars", component: <PersonalParticulars ref={personalParticularsRef} profilePhotoFile={memberDetailsResponse?.professionalProfilPhoto} />, locked: false },
      { id: 2, header: "Academic Qualification", component: <AcademicQualification ref={academicQualificationRef} />, locked: false },
      { id: 3, header: "Career History", component: <CareerHistory ref={currentEmpInfoRef} />, locked: false },
      { id: 4, header: "Certification Course/ Green Mark Refresher Course", component: <CertificateCourse />, locked: false },
      { id: 5, header: "Green Mark Project Information", component: <GreenMarkProject />, locked: false },
      // { id: 6, header: "GMAP Membership", component: <GmapMembership />, locked: false },
      { id: 7, header: "Continuing Professional Development", component: <ContinuingProfessionalDevelopment isViewButton={true} />, locked: false },
      { id: 8, header: "Financial Record", component: <FinancialRecord />, locked: false },
      { id: 10, header: "Gma Certificate Record", component: <CertificateRecord />, locked: false },
      { id: 9, header: "Generate PDF", component: <PendingApplications type={memberDetailsResponse?.gmapApplicationTypeId} date={memberDetailsResponse?.submissionDate} />, locked: false }
    ];
    return tab;
  }
  const tabs = memberDetailsResponse?.gmapApplicationTypeId === 1 ? getTabs()?.filter(obj => obj.id !== 5) : getTabs();

  const resetPassword = () => {
    // navigate("/resetPassword");
    setIsModalOpen(!isModelOpen)
  };
  const openCloseModal = () => {
    setIsModalOpen(!isModelOpen);
  };

  return (
    <>
      <div className="EditIndividualName-title">
        <p>Profile of : {memberDetailsResponse?.fullName} ({accountInformationResponse?.gmapNumber})  </p>
      </div>
      <CardSection cardTitle="Account Information">
        <div className="row align-items-end mb-2">
          <div className="col-10">
            {isSuccessAccInfo ? <AccountInformation ref={accountInformationRef} /> : <PageLoader />}
          </div>
          <div className="col-2 d-flex justify-content-end">
            <div className="reset-password-button">
              {isModelOpen && (
                <CenterModel
                  modelTitle={modelDetail.label}
                  show={"test"}
                  handleClose={openCloseModal}
                  onUpload={openCloseModal}
                >
                  {modelDetail.component}
                  <UpdatePasswordForm />
                </CenterModel>

              )}
              <Buttons
                buttonText="Reset Password"
                buttonTypeClassName="success-btn"
                onClick={() => resetPassword()}
              />

            </div>
          </div>


        </div>
      </CardSection>
      <div className="common-accordian-view-design">
        {(isSuccess && memberDetailsResponse?.gmapApplicationTypeId) ? <Accordion alwaysOpen>
          {tabs?.map((item, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
                {item.header}
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <EditApplicationDataContext.Provider value={contextValue}>
                    {item.component}
                  </EditApplicationDataContext.Provider>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion> : <PageLoader />}
        <div className="common-custom-pagination">
          <Buttons
            buttonText="Back"
            buttonTypeClassName="back-btn"
            onClick={() => handleBack()}
          />
          <Buttons
            buttonText="Save"
            buttonTypeClassName="outline-btn"
            isLoading={isFetchingAppUpdate}
            onClick={handleUpdate}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(EditIndividualMembersForm);
