import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import CardSection from '../../../../components/ui/card/CardSection';
import FormCreator from '../../../../components/Forms/FormCreator';
import { NotificationToAttendeesFormData } from './data/NotificationToAttendeesForm.Data';
import { DietaryRequirementFormData } from './data/DietaryRequirementForm.Data';
import TextBoxComponent from './component/TextBox/TextBoxComponent';
import DropdownComponent from './component/Dropdown/DropdownComponent';
import RadioButtonComponent from './component/RadioButton/RadioButtonComponent';
import AdditionalQuestions from './component/AdditionalQuestions/AdditionalQuestions';
import FeedbackQuestions from './component/FeedbackQuestions/FeedbackQuestions';

const Others = forwardRef((props, ref) => {
    const dietaryRequirementRef = useRef();
    const notificationToAttendeesRef = useRef();
    const additionalQuestionsRef = useRef();

    const [dietaryRequirementForm, setDietaryRequirementForm] = useState(DietaryRequirementFormData);
    const [notificationToAttendeeForm, setNotificationToAttendeeForm] = useState(NotificationToAttendeesFormData);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        dietaryRequirementRef: {
            getFormData: () => {
                const dietaryRequirementFormDetails = dietaryRequirementRef.current.getFormData();
                if (dietaryRequirementFormDetails) {
                    return dietaryRequirementFormDetails;
                }
                return null;
            },
        },
        additionalQuestionsRef: {
            getFormData: () => {
                const additionalQuestionsDetails = additionalQuestionsRef.current.getFormData();
                if (additionalQuestionsDetails) {
                    return additionalQuestionsDetails;
                }
                return null;
            },
        },
        notificationToAttendeesRef: {
            getFormData: () => {
                const notificationToAttendeeFormDetails = notificationToAttendeesRef.current.getFormData();
                if (notificationToAttendeeFormDetails) {
                    return notificationToAttendeeFormDetails;
                }
                return null;
            },
        },

    }));

    return (
        <React.Fragment>
            <CardSection cardTitle="Dietary Requirement">
                <div className='row'>
                    <FormCreator ref={dietaryRequirementRef} {...dietaryRequirementForm} />
                </div>
            </CardSection>
            <CardSection cardTitle="Additional Questions">
                <AdditionalQuestions ref={additionalQuestionsRef} />
            </CardSection>
            <CardSection cardTitle="Feedback Questions">
                <FeedbackQuestions />
            </CardSection>
            <CardSection cardTitle="Notification To Attendees (Email)">
                <div className='row'>
                    <FormCreator ref={notificationToAttendeesRef} {...notificationToAttendeeForm} />
                </div>
            </CardSection>
        </React.Fragment>
    )
})

export default Others