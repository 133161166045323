import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../components/Grid/MolGrid';
import { TransactionReportListTableConfig } from './data/TransactionReportTable';
import { useGetTransactionRocordListMutation } from '../../../app/services/Financials/TransactionReport';
const TransactionReportListTable = ({ paginationReq }) => {
  const molGridRef = useRef();
  const [dataSource, setDataSource] = useState();
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 20,
    endPage: 100,
    totalCount: 0,
    StartDate: null,
    EndDate: null
  });
  const [TransactionReportList, { isSuccess: isTransactionReportListSuccess, isLoading: isTransactionReportListFetching, data: transactionReportListResponse },] = useGetTransactionRocordListMutation();
  useEffect(() => {
    TransactionReportList(paginationReq)
  }, [])

  useEffect(() => {
    setDataSource([]);
    TransactionReportList(paginationReq)
    console.log(transactionReportListResponse, "aaaa")
  }, [paginationReq])

  useEffect(() => {
    if (!isTransactionReportListFetching && isTransactionReportListSuccess && transactionReportListResponse) {
      setPaginationRequest({ ...paginationRequest, totalCount: transactionReportListResponse.totalCount })
      setDataSource(transactionReportListResponse.itemList)
    }
  }, [isTransactionReportListFetching, isTransactionReportListSuccess, transactionReportListResponse]);
  const handlePageChange = ({ pageNumber, pageSize }) => {
    TransactionReportList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
  }


  return (
    <div>
      <div>
        <h4>Total Count :{paginationRequest.totalCount} </h4>
      </div>
      <MolGrid
        ref={molGridRef}
        configuration={TransactionReportListTableConfig}
        dataSource={dataSource}
        allowPagination={true}
        pagination={paginationRequest}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default TransactionReportListTable
