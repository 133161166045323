import { FormFieldTypes } from "../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";


export const certificateCourseConfig = {
    columns: [
        {
            name: "Certificate Type",
            fieldName: "certificateName",
            colType: GridColumnType.INPUT,
        },
        {
            name: "Certificate Date",
            fieldName: "certificateDate",
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Certificate Number",
            fieldName: "certificateNumber",
        },
        {
            name: "Certificate Attachment ",
            fieldName: "certificateAttachmentName", // our FileObject 
            colType: GridColumnType.LINK,
            colSettings: {
                isOpenNewTab: true,
                url: "$certificateLink", // need to add that property in our object  
            },
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
            },
            actionHandler: {
                onEditAction: () => {
                    alert("edit called"); //Edit button Action
                },
                onDeleteAction: () => {
                    alert("delete called"); //Delete button Action
                },
            },
        },
    ]
}
export const certificateCourseData = {
    name: 'CertificateCourse',
    initialState: {
        gmaPathwayTypeID: '',
        gmapCertificateTypeId: '',
        gmapCertificateTypeP1Id: '',
        gmapCertificateTypeP2Id: '',
        certificateDate: '',
        certificateNumber: '',
        certificateAttachment: '',
        IHLCertificateTypeId: '',
        gmaapCertificateTypeId: '',
    },

    formFields: [
        {
            id: "gmaPathwayTypeID",
            lable: "GMA Pathways",
            Field_Name: "GMA Pathways",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaPathwayTypeID",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-4 mb-5 d-none"
            }
        },
        {
            id: "certificateHistoryTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "certificateHistoryTable",
            fieldSetting: {
                header: certificateCourseConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left",
            },
        },
        {
            id: "gmapCertificateTypeP1Id",
            lable: "Certificate Type",
            Field_Name: "Certificate Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapCertificateTypeP1Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmapCertificateTypeP2Id",
            lable: "Certificate Type",
            Field_Name: "Certificate Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapCertificateTypeP2Id",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmaapCertificateTypeId",
            lable: "Certificate Type",
            Field_Name: "Certificate Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmaapCertificateTypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "gmapCertificateTypeId",
            lable: "Certificate Type",
            Field_Name: "Certificate Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapCertificateTypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "certificateNumber",
            lable: "Certificate Number (Indicate Nil if not applicable)",
            Field_Name: "Certificate Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "certificateNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "IHLCertificateTypeId",
            lable: "IHL Certificate Type",
            Field_Name: "IHL Certificate Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "IHLCertificateTypeId",
            fieldSetting: {
                placeholder: "",
                options: []

            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "certificateDate",
            lable: "Certificate Date",
            Field_Name: "Certificate Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "certificateDate",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },

        {
            id: "certificateAttachment",
            lable: 'Certificate Attachment (please upload in pdf format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'certificateAttachment',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}
