import React, { useRef, useState } from "react"
import Buttons from "../../components/ui/button/Buttons";
import EventFeedbackFilterForm from "./component/EventFeedbackQuestionList/EventFeedbackQuestionListFilterForm";
import EventFeedbackListTable from "./component/EventFeedbackQuestionList/EventFeedbackQuestionListTable";

const initState = {
    pageNo: 1,
    pageSize: 20,
    endPage: 100,
    SearchText: "",
    totalCount: 0,
}

const EventFeedbackQuestionList = (props) => {
    const eventFeedbackQuestionListRef = useRef(null)
    const [paginationRequest, setPaginationRequest] = useState(initState);
    const handleSearchApplicationTask = () => {
        const taskListFormData = eventFeedbackQuestionListRef.current.getFormData();
        if (!eventFeedbackQuestionListRef.current) {
            return;
        }
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 20,
            endPage: 100,
            SearchText: taskListFormData.eventName,
        };
        setPaginationRequest(updatedPaginationRequest);
    };


    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <EventFeedbackFilterForm ref={eventFeedbackQuestionListRef} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end align-items-center">
                    <div className="two-button-fixes">
                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearchApplicationTask}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <EventFeedbackListTable paginationReq={paginationRequest} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventFeedbackQuestionList;
