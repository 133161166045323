/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FormCreator from "../../../../components/Forms/FormCreator";
import { eventRegistrationApprovalTasklistFormData } from "./data/EventRegistrationApprovalListForm.data";

const EventListFilterForm = forwardRef((props, ref) => {
    const filterFormRef = useRef();
    const [filterForm, setFilterForm] = useState(eventRegistrationApprovalTasklistFormData);

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const filterFormData = filterFormRef.current.getFormData();
                return filterFormData;
            }
            return null;
        }
    }));

    return (
        <FormCreator ref={filterFormRef} {...filterForm} />
    )

})

export default EventListFilterForm