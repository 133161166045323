import { FormFieldTypes } from "../../../../../../data/formFieldType";

export const updatePasswordData = {
    name: "updatePasswordData",
    initialState: {
        password: "",
        confirmPassword: ""
    },
    formFields: [
        {
            id: "password",
            lable: "Password",
            Field_Name: "Password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "password",
            fieldSetting: {
                placeholder: "",
            },
            validation: [{ type: "require" }, { type: "password" }],
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
        {
            id: "confirmPassword",
            lable: "Confirm Password",
            Field_Name: "Confirm Password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "confirmPassword",
            fieldSetting: {
                placeholder: "",
            },
            validation: [{ type: "require" }, { type: "password" }],
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
    ],
};
