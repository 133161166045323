import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const membershipFinancialService = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'membershipFinancialService',
    endpoints: (builder) => ({


        GetGMAPMembersFinancialInformationById: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/GetGMAPMembersFinancialInformationById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetGMAPMembersCertificateInformationById: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/GetGMAPMembersCertificateInformationById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
})

export const {
    useGetGMAPMembersFinancialInformationByIdMutation,
    useGetGMAPMembersCertificateInformationByIdMutation

} = membershipFinancialService;

export default membershipFinancialService;
