export const Menu = [
  {
    name: "Dashboard",
    id: "dashboard",
    to: "/",
    iconClass: "bi bi-speedometer2",
    subMenu: false,
    children: [],
  },
  {
    id: "GMP",
    name: "GMP",
    iconClass: "bi bi-person",
    subMenu: true,
    children: [
      {
        id: "gmapApplicationTasklist",
        submenuName: "Application",
        to: "/gmapApplicationTasklist",
      },
      {
        id: "gmapDiscretionaryRenewalRequest",
        submenuName: "Dis. Renewal",
        to: "/gmapDiscretionaryRenewalRequest",
      },
      {
        id: "gmapApplicationTaskListDiscretionaryRenewal",
        submenuName: "Dis. Renewal List",
        to: "/gmapApplicationTaskListDiscretionaryRenewal",
      },
      {
        id: "gmaCourse",
        submenuName: "GMA Course ",
        to: "/gmaCourse",
      },
    ],
  },
  {
    name: "GMP Directory (Staff)",
    id: "gmpDirectoryStaff",
    to: "/viewIndividualMembers",
    iconClass: "bi bi-people",
    subMenu: false,
    children: [
    ],
  },
  {
    id: "CPD",
    name: "CPD",
    iconClass: "bi bi-person",
    subMenu: true,
    children: [
      {
        id: "verifyCpdSubmission",
        submenuName: "CPD Submissions",
        to: "/approveCpdSubmission",
      },
      {
        id: "cpdUpload",
        submenuName: "CPD Upload",
        to: "/cpdUpload",
      },
      {
        id: "cpdRenewalCycle",
        submenuName: "CPD Renewal Cycle",
        to: "/cpdRenewalCycle",
      },
    ],
  },
  // {
  //   id: "CourseProvider",
  //   name: "Course Provider",
  //   iconClass: "bi bi-book",
  //   subMenu: true,
  //   children: [
  //     {
  //       id: "courseProvider",
  //       submenuName: "Course Providers",
  //       to: "/courseProvider",
  //     },
  //     {
  //       id: "eventApplications",
  //       submenuName: "Events Applications",
  //       to: "/eventApplications",
  //     },
  //   ],
  // },
  // {
  //   name: "Resources",
  //   id: "viewResources",
  //   to: "/viewResources",
  //   iconClass: "bi bi-journal-text",
  //   subMenu: false,
  //   children: [],
  // },
  {
    id: "financial",
    name: "Financial",
    iconClass: "bi bi-currency-dollar",
    subMenu: true,
    children: [
      {
        id: "xeroBatchextraction",
        submenuName: "Xero Batch Extraction",
        to: "/xeroBatchExtraction",
      },
      {
        id: "transactionReport",
        submenuName: "Transaction Report",
        to: "/transactionReport",
      },
    ],
  },
  {
    id: "Admin",
    name: "Admin",
    iconClass: "bi bi-person",
    subMenu: true,
    children: [
      {
        id: "emailTemplateManagement",
        submenuName: "Email Templates",
        to: "/emailTemplateManagement",
      },
      {
        id: "dropdownManagement",
        submenuName: "Dropdown Fields",
        to: "/dropdownManagement",
      },
      {
        id: "gmapPricingTable",
        submenuName: "GMP Application Pricing",
        to: "/gmapPricingTable",
      },
    ],
  },
  // {
  //   id: "pageManagement",
  //   name: "Page Management",
  //   iconClass: "bi bi-card-checklist",
  //   subMenu: true,
  //   children: [
  //     {
  //       id: "createPages",
  //       submenuName: "Create Pages",
  //       to: "/createPages",
  //     },
  //     {
  //       id: "viewPages",
  //       submenuName: "View Pages",
  //       to: "/viewPages",
  //     },
  //   ],
  // },
  // {
  //   id: "EventManagement",
  //   name: "Event Management",
  //   iconClass: "bi bi-people",
  //   subMenu: true,
  //   children: [
  //     {
  //       id: "createEvent",
  //       submenuName: "Create Event",
  //       to: "/createEvent",
  //     },
  //     {
  //       id: "viewEditEvent",
  //       submenuName: "View/Edit Event",
  //       to: "/viewEditEvent",
  //     },
  //     {
  //       id: "eventRegistrationApproval",
  //       submenuName: "Event Registration Approval",
  //       to: "/eventRegistrationApproval",
  //     },
  //     {
  //       id: "eventFeedbackQuestion",
  //       submenuName: "Event Feedback Question",
  //       to: "/eventFeedbackQuestion",
  //     },
  //     {
  //       id: "eventQuestionAnswer",
  //       submenuName: "Event Question Answer",
  //       to: "/eventQuestionAnswer",
  //     },
  //   ],
  // },
  // {
  //   name: "Analytics",
  //   id: "Analytics",
  //   to: "/analytics",
  //   iconClass: "bi bi-speedometer2",
  //   subMenu: false,
  //   children: [],
  // },
  {
    id: "AccountManagement",
    name: "Account Management",
    iconClass: "bi bi-people",
    subMenu: true,
    children: [
      {
        id: "createAccount",
        submenuName: "Create Account",
        to: "/createAccount",
      },
      {
        id: "viewEditAccount",
        submenuName: "View/Edit Account",
        to: "/viewEditAccount",
      },
    ],
  },
];
